// @<COPYRIGHT>@
// ==================================================
// Copyright 2020.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

import app from "app";
import cdm from "soa/kernel/clientDataModel";
import localeSvc from "js/localeService";
import appCtxSvc from "js/appCtxService";

let exports = {};

export let processResponse = function (response) {
  let modObj;
  if (response != undefined) {
    let modObjUidsCount = response.plain.length;
    if (
      modObjUidsCount != undefined &&
      modObjUidsCount > 0 &&
      modObjUidsCount == 1
    ) {
      let modObjUid = response.plain[0];
      modObj = cdm.getObject(modObjUid);
    }
  }
  return modObj;
};

export let populateObjDesc = function (data) {
  let objectDesc = "";
  objectDesc = data.modObj.props.g4b_erstVerwenderEmail.uiValues[0];
  data.propValue.uiValue = objectDesc;
};

export let openEmailHyperlink = function (data) {
  let emailAddress = data.propValue.uiValue;

  let resource =
    app.getBaseUrlPath() + "/i18n/TaKonfigToolsInfoCommandsMessages";
  let localTextBundle = localeSvc.getLoadedText(resource);

  let emailSubject = localTextBundle.g4bErstVerwenderEmailSubject;

  window.location.href = "mailto:" + emailAddress + "?subject=" + emailSubject;
};

export let g4bOpenEmailHyperlinkAndRefresh = function () {
  let prototypeRevObj = cdm.getObject(
    appCtxSvc.getCtx("selected.props.awb0UnderlyingObject.dbValues[0]")
  );
  let emailAddress = prototypeRevObj.props.g4b_erstVerwenderEmail.uiValues[0];
  let resource =
    app.getBaseUrlPath() + "/i18n/TaKonfigToolsInfoCommandsMessages";
  let localTextBundle = localeSvc.getLoadedText(resource);

  let emailSubject = localTextBundle.g4bErstVerwenderEmailSubject;

  window.location.href = "mailto:" + emailAddress + "?subject=" + emailSubject;
};

exports = {
  g4bOpenEmailHyperlinkAndRefresh,
  processResponse,
  populateObjDesc,
  openEmailHyperlink,
};

export default exports;

// @<COPYRIGHT>@
// ==================================================
// Copyright 2020.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

let exports = {};

export let getUnderlyingPartUsage = function (sourceObjects) {
  let underlyingPartUsageObjects = [];

  for (let i = 0; i < sourceObjects.length; i++) {
    underlyingPartUsageObjects.push({
      uid: sourceObjects[i].props.awb0UnderlyingObject.dbValues[0],
      type: sourceObjects[i].props.awb0UnderlyingObjectType.dbValues[0],
    });
  }
  return underlyingPartUsageObjects;
};

export let refreshWindow = function () {
  location.reload();
};

exports = {
  refreshWindow,
  getUnderlyingPartUsage,
};

export default exports;

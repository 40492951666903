// @<COPYRIGHT>@
// ==================================================
// Copyright 2018.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/**
 * Service to expand selected partitions recursively till the end partusages;
 *
 * @module js/G4B_expandAndSelectAllChildrenService
 */

import angular from "angular";
import appCtxService from "js/appCtxService";
import dataManagementSvc from "soa/dataManagementService";
import contextStateMgmtService from "js/contextStateMgmtService";
import occmgmtUpdatePwaDisplayService from "js/occmgmtUpdatePwaDisplayService";
import eventBus from "js/eventBus";
import $ from "jquery";
import aceExpandBelowService from "js/aceExpandBelowService";
import AwPromiseService from "js/awPromiseService";

let exports = {};
let partUsagesfound = [];
let selectedRows = [];
let masterRowsArr = [];

export let g4bPerformExpandBelowMultiSelected = function (mselected) {
  let multiDeferred = AwPromiseService.instance.defer().resolve();
  mselected.forEach(function (selected) {
    multiDeferred = multiDeferred
      .then(function () {
        return doExpandBelowNode(selected);
      })
      .then(function () {
        //console.log("Done All!");
      });
  });
};

/**
 * This function is used to expand a treenode. It is being called sequentially to expand multiple selected nodes.
 * Refer to "performExpandBelow" function from aceExpandBelowService.js
 * @param {ModelObject} selected
 */

let doExpandBelowNode = function (selected) {
  let deferred = AwPromiseService.instance.defer();
  appCtxService.getCtx(
    "aceActiveContext.context.pwaSelectionModel.setSelection(selected.uid)"
  );
  setTimeout(function () {
    let vmoId = appCtxService.getCtx(
      "aceActiveContext.context.vmc.findViewModelObjectById( selected.uid )"
    );
    if (vmoId !== -1) {
      appCtxService.updatePartialCtx(
        "aceActiveContext.context.expansionCriteria.expandBelow",
        "true"
      );
      appCtxService.updatePartialCtx(
        "aceActiveContext.context.expansionCriteria.loadTreeHierarchyThreshold",
        "500"
      );
      appCtxService.updatePartialCtx(
        "aceActiveContext.context.nodeUnderExpandBelow",
        selected
      );

      let vmo = appCtxService.getCtx(
        "aceActiveContext.context.vmc.loadedVMObjects[ vmoId ]"
      );

      if (vmo.isExpanded) {
        contextStateMgmtService.updateContextState(
          appCtxService.getCtx("aceActiveContext.key"),
          {
            o_uid: selected.uid,
            c_uid: selected.uid,
          },
          true
        );
        occmgmtUpdatePwaDisplayService.resetPwaContents();
      } else {
        delete vmo.__expandState;
        eventBus.publish(
          appCtxService.getCtx("aceActiveContext.context.vmc.name") +
            ".expandTreeNode",
          {
            parentNode: {
              id: selected.uid,
            },
          }
        );
      }
    }
    deferred.resolve();
  }, 1000);

  return deferred.promise;
};
// collapse the already expanded tree of partition element
export let g4bCollapseAlreadyExpandedPartition = function (selected) {
  let vmoId = appCtxService
    .getCtx("aceActiveContext.context.vmc")
    .findViewModelObjectById(selected.uid);
  let vmo = appCtxService.getCtx(
    "aceActiveContext.context.vmc.loadedVMObjects[ vmoId ]"
  );
  if (vmo.isExpanded) {
    contextStateMgmtService.updateContextState(
      appCtxService.getCtx("aceActiveContext.key"),
      {
        o_uid: selected.uid,
        c_uid: selected.uid,
      },
      true
    );
    aceExpandBelowService.performCollapseBelow();
  }
};

export let g4bPerformExpandBelowForModuleOrg = function (mselected) {
  let multiDeferred = AwPromiseService.instance.defer().resolve();
  let lvlToExpand = "4";
  rearrangeMselectedObjects(mselected);
  mselected.forEach(function (selected) {
    multiDeferred = multiDeferred
      .then(function () {
        return doExpandBelowNodeForModuleOrg(selected, lvlToExpand);
      })
      .then(function () {
        //console.log("Done All!");
      });
  });
};

let rearrangeMselectedObjects = function (mselected) {
  let loadedVMObjects = appCtxService.getCtx(
    "occmgmtContext.vmc.loadedVMObjects"
  );
  let sortingArrUids = [];
  //let mselectedSorted
  for (let inx = 0; inx < loadedVMObjects.length; inx++) {
    sortingArrUids.push(loadedVMObjects[inx].uid);
  }

  mselected.sort(function (a, b) {
    return sortingArrUids.indexOf(a.uid) - sortingArrUids.indexOf(b.uid);
  });
};

/**
 * This function is used to expand a treenode. It is being called sequentially to expand multiple selected nodes.
 * Refer to "performExpandBelow" function from aceExpandBelowService.js
 * @param {ModelObject} selected
 */

let doExpandBelowNodeForModuleOrg = function (selected, levelsToExpand) {
  let deferred = AwPromiseService.instance.defer();
  let vmoId = appCtxService.getCtx(
    "aceActiveContext.context.vmc.findViewModelObjectById( selected.uid )"
  );
  if (vmoId !== -1) {
    appCtxService.updatePartialCtx(
      "aceActiveContext.context.expansionCriteria.expandBelow",
      "true"
    );
    appCtxService.updatePartialCtx(
      "aceActiveContext.context.expansionCriteria.loadTreeHierarchyThreshold",
      "500"
    );
    appCtxService.updatePartialCtx(
      "aceActiveContext.context.nodeUnderExpandBelow",
      selected
    );

    let vmo = appCtxService.getCtx(
      "aceActiveContext.context.vmc.loadedVMObjects[ vmoId ]"
    );
    if (levelsToExpand) {
      appCtxService.updatePartialCtx("aceActiveContext.context.expandNLevel", {
        levelsToExpand: levelsToExpand,
        levelsApplicableForExpansion:
          vmo.$$treeLevel + parseInt(levelsToExpand),
      });
    } else {
      delete appCtxService.getCtx("aceActiveContext.context.expandNLevel");
    }
    delete vmo.__expandState;
    eventBus.publish(
      appCtxService.getCtx("aceActiveContext.context.vmc.name") +
        ".expandTreeNode",
      {
        parentNode: {
          id: selected.uid,
        },
      }
    );
  }
  //Auto scroll untill all partitions are expanded
  let interval = setInterval(function () {
    if (!checkIfAllPartitionsExpanded()) {
      let gridElement = document
        .getElementsByTagName("aw-splm-table")[0]
        .getElementsByClassName("aw-splm-tableContainer")[0]
        .getElementsByClassName("aw-splm-tableViewport")[0];
      $(gridElement).animate(
        { scrollTop: $(gridElement)[0].scrollHeight },
        250
      );
    } else {
      clearInterval(interval);
      deferred.resolve();
    }
  }, 1000);

  return deferred.promise;
};
/**
 * Clear all selections and set mode to multi select
 */
let deselectAllUserSelected = function () {
  appCtxService.getCtx("occmgmtContext.pwaSelectionModel").selectNone();
  let awTable = document.getElementsByTagName("aw-table")[0];
  angular.element(awTable).scope().$evalAsync();
};

export let getSelectedPartitionRows = function () {
  partUsagesfound = [];
  let allRows = document
    .getElementsByTagName("aw-table")[0]
    .getElementsByClassName("ui-grid-render-container-left")[0]
    .getElementsByClassName("ui-grid-row");
  selectedRows = [];
  for (let inx = 0; inx < allRows.length; inx++) {
    let currentRow = angular.element(allRows[inx]).scope().row;
    if (currentRow.isSelected == true) {
      if (
        currentRow.entity.id.indexOf("Fgf0PartitionElement") >= 0 ||
        currentRow.entity.id.indexOf("Awb0DesignElement") >= 0
      ) {
        selectedRows.push(currentRow); //push to selected row array if a partition type
      }
    }
  }
  deselectAllUserSelected();

  for (let jnx = 0; jnx < selectedRows.length; jnx++) {
    recursivePartitionExpander(selectedRows[jnx]);
    //console.log('selected row: '+selectedRows[jnx].entity.id);
  }
};

let recursivePartitionExpander = function (selectedRow) {
  //TODO:  Change uiGridTreeBaseSvc as per AW 5.0
  //uiGridTreeBaseSvc.expandRow(selectedRow.grid,selectedRow);

  let expandCountdown = setInterval(function () {
    if (
      selectedRow.treeNode.children.length > 0 &&
      !selectedRow.entity.isLeaf
    ) {
      //console.log("children found");
      clearInterval(expandCountdown);
      let checkExpand = false;
      for (let inx = 0; inx < selectedRow.treeNode.children.length; inx++) {
        if (selectedRow.treeNode.children[inx].row.entity.isLeaf) {
          if (
            selectedRow.treeNode.children[inx].row.entity.id.indexOf(
              "Fgb0PartUsage"
            ) >= 0
          ) {
            // eslint-disable-next-line no-console
            console.log(
              "***FoundPU***: " +
                selectedRow.treeNode.children[inx].row.entity.id
            );
            partUsagesfound.push(
              selectedRow.treeNode.children[inx].row.entity.id
            );
            checkExpand = checkIfAllExpanded();
          }
        } else {
          recursivePartitionExpander(selectedRow.treeNode.children[inx].row);
        }
      }
      if (checkExpand == true) {
        let treeLoadFinishTimeout = setInterval(function () {
          //after expansion wait until treeLoadingInProgress becomes false
          if (
            appCtxService.getCtx("occmgmtContext.treeLoadingInProgress") ==
            false
          ) {
            // eslint-disable-next-line no-console
            console.log("tree expansion complete");
            clearInterval(treeLoadFinishTimeout);
            dataManagementSvc.loadObjects(partUsagesfound).then(function () {
              appCtxService
                .getCtx("occmgmtContext.pwaSelectionModel")
                .setMultiSelectionEnabled(true);
              appCtxService
                .getCtx("occmgmtContext.pwaSelectionModel")
                .setSelection(partUsagesfound);
            });
          }
        }, 500);
      }
    } else if (selectedRow.entity.isLeaf) {
      clearInterval(expandCountdown);
    } else if (
      selectedRow.entity.isExpanded &&
      selectedRow.entity.children != null
    ) {
      clearInterval(expandCountdown);
    }
    //console.log('expanding....');
  }, 1000); //  check every second if the current row`s expansion is complete
};

/*
 * function to check if all nodes are expanded
 */
let checkIfAllExpanded = function () {
  let allExpandedFlag = false;
  masterRowsArr = [];
  for (let inx = 0; inx < selectedRows.length; inx++) {
    getAllRows(selectedRows[inx]);
  }

  for (let jnx = 0; jnx < masterRowsArr.length; jnx++) {
    // eslint-disable-next-line no-extra-parens
    if (
      (masterRowsArr[jnx].entity.isExpanded &&
        masterRowsArr[jnx].treeNode.children.length > 0) ||
      masterRowsArr[jnx].entity.isLeaf
    ) {
      if (jnx == masterRowsArr.length - 1) {
        allExpandedFlag = true;
      }
      continue;
    } else {
      break;
    }
  }
  return allExpandedFlag;
};

let getAllRows = function (row) {
  masterRowsArr.push(row);
  if (row.treeNode.children.length > 0) {
    for (let inx = 0; inx < row.treeNode.children.length; inx++) {
      getAllRows(row.treeNode.children[inx].row);
    }
  }
};

let checkIfAllPartitionsExpanded = function () {
  let allExpandedFlag = true;
  let loadedVMObjects = appCtxService.getCtx(
    "occmgmtContext.vmc.loadedVMObjects"
  );
  for (let inx = 0; inx < loadedVMObjects.length; inx++) {
    if (
      loadedVMObjects[inx]._expandRequested === true ||
      (loadedVMObjects[inx].isInExpandBelowMode === true &&
        loadedVMObjects[inx].isExpanded != true)
    ) {
      allExpandedFlag = false;
      break;
    }
  }
  return allExpandedFlag;
};

exports = {
  getSelectedPartitionRows,
  g4bPerformExpandBelowMultiSelected,
  g4bPerformExpandBelowForModuleOrg,
  g4bCollapseAlreadyExpandedPartition,
};

export default exports;

// @<COPYRIGHT>@
// ==================================================
// Copyright 2017.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*global
 define
 */

/**
 * Simple Alert service for sample command Handlers
 *
 * @module js/CreateDxOrderService
 */

import AwStateService from "js/awStateService";

("use strict");
let exports = {};
// Main function execution start from Here
export let createRelateAndSubmitResponse = function (responseData) {
  var modelObjs = responseData.ServiceData.modelObjects;

  // Find uid in responseData modelObjects
  var uidFinal = "";
  for (var key in modelObjs) {
    if (modelObjs[key].type == "V4B_TC_Dx_OrderRevision") {
      uidFinal = modelObjs[key].uid;
    }
  }
  // calling showObjectCommandHandler to open the created object in edit mode
  /// PARAMS for .execute() function:
  /// First parameter is vmo json
  /// Second - DON'T KNOW/CARE
  /// THIRD - boolean - specifies whether to open object in Edit mode

  var showObject = "com_siemens_splm_clientfx_tcui_xrt_showObject";
  var toParams = {};
  var options = {};

  toParams.uid = uidFinal;
  toParams.cmdId = "Awp0StartEdit";
  toParams.edit = "true";
  options.inherit = false;

  AwStateService.instance.go(showObject, toParams, options);
};
exports = {
  createRelateAndSubmitResponse,
};

export default exports;

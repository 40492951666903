/*window
 */
/**
 * Common Custom Services for FTM Location
 *
 * @module js/CommonCustomServices
 * * ModifyBy              ModifyOn      Comment
 * Sunil Petkar         15/April/2020    Change the surrogate serch string logic based on UID
 * Niranjan Rekhate     26/May/2020      Changes related to user referenced properties
 */
import eventBus from "js/eventBus";
import _ from "lodash";
import $ from "jquery";
import appCtxSvc from "js/appCtxService";
import VIMSoaUtils from "js/VIMSoaUtils";
import AwStateService from "js/awStateService";
import cdmSvc from "soa/kernel/clientDataModel";
import AwPromiseService from "js/awPromiseService";
import searchFilterSvc from 'js/aw.searchFilter.service';
import localeService from 'js/localeService';
import app from "app";
import cdm from 'soa/kernel/clientDataModel';
import searchFilterService from 'js/aw.searchFilter.service';

("use strict");

let exports = {};

let _SORT_ALLOWED_PROP_TYPE = [
  "STRING",
  "BOOLEAN",
  "DATETIME",
  "INTEGER",
  "DOUBLE",
  "DATE",
  "OBJECT",
];

/**
 * Open Object in showObject Location in edit mode
 * @param {String} uid - uid of the object to open
 */
export let openShowObjectInEditMode = function (uid) {
  let showObject = "com_siemens_splm_clientfx_tcui_xrt_showObject";
  let toParams = {};
  let options = {};

  toParams.uid = uid;
  toParams.cmdId = "Awp0StartEdit";
  options.inherit = false;
  toParams.edit = true;
  AwStateService.instance.go(showObject, toParams, options);
};

/**
 *Function to get selected search sort criteria.
 * @function getSearchSortCriteria
 * @param {Object} data - The Data provider
 */
export let getSearchSortCriteria = function (data) {
  if (data.columnProviders.clientScopeUI.sortCriteria.length > 0) {
    let fieldName =
      data.columnProviders.clientScopeUI.sortCriteria[0].fieldName;
    let temp = fieldName.split(".");
    fieldName = temp.length == 2 ? temp[1] : temp[0];
    let vmo = data.dataProviders.gridDataProvider.getItemAtIndex(0);
    if (vmo && vmo != null) {
      let propType = vmo.props[fieldName].type;

      if (_.includes(_SORT_ALLOWED_PROP_TYPE, propType)) {
        let cols = data.dataProviders.gridDataProvider.cols;
        for (let ii = 1; ii < cols.length; ii++) {
          if (cols[ii].field === fieldName) {
            data.columnProviders.clientScopeUI.sortCriteria[0].fieldName =
              cols[ii].typeName + "." + cols[ii].field;
            break;
          }
        }
      } else {
        data.columnProviders.clientScopeUI.sortCriteria = [];
      }
    }
  }

  return data.columnProviders.clientScopeUI.sortCriteria;
};

/**
 *Function to get selected filter categories.
 * @function getSearchFilterCategories
 * @param {Object} response - The Response Data
 */
export let getSearchFilterCategories = function (response) {
  let ctxPrefObject = appCtxSvc.ctx.getCtx("preferences");

  let locationName =
    appCtxSvc.getCtx("locationContext")["ActiveWorkspace:Location"];
  if (locationName === "TIMLocations") {
    locationName =
      appCtxSvc.getCtx("locationContext")["ActiveWorkspace:SubLocation"];
  }

  let validFilterNames = [];
  let prefName = locationName + "_AW_FiltersList";
  if (ctxPrefObject[prefName]) {
    validFilterNames = ctxPrefObject[prefName];
  }

  let newFilterCategories = [];
  if (validFilterNames.length > 0) {
    for (let ii = 0; ii < validFilterNames.length; ii++) {
      for (let jj = 0; jj < response.searchFilterCategories.length; jj++) {
        if (
          validFilterNames[ii].indexOf(
            response.searchFilterCategories[jj].internalName
          ) >= 0
        ) {
          newFilterCategories.push(response.searchFilterCategories[jj]);
        }
      }
    }
  } else {
    newFilterCategories = response.searchFilterCategories;
  }

  /**  Update the Category in the bar chart. */
  /**  For  FTM under MyTask it should be chart by the Project in other SubLocations by the status. */
  let ctxSearchObject = appCtxSvc.getCtx("search");
  let SublocationName =
    appCtxSvc.getCtx("locationContext")["ActiveWorkspace:SubLocation"];

  if (locationName === "FTM") {
    if (SublocationName === "FTMMyTasks" || SublocationName === "FTMMyIssues") {
      ctxSearchObject.currentHighlightedCategory =
        newFilterCategories[1].internalName;
    }
  } 
  /**  Update the Category in the bar chart. */
  appCtxSvc.updatePartialCtx("search", ctxSearchObject);

  return newFilterCategories;
};

/**
 *Function to parse SOA service response and load column data.
 * @function loadData
 * @param {Object} response - The Response Data
 */
export let loadData = function (response) {
  return (
    (response.searchResults = response.searchResults
      ? response.searchResults.map(function (vmo) {
          return cdmSvc.getObject(vmo.modelObject.uid);
        })
      : []),
    response.searchResults
  );
};

/** 
 *Function to get Solr sort string.
 *@function getSolrString
 * @param {String} inStr
 */
 function getSolrString(inStr) {
  if (inStr.indexOf(' ') >= 0) {
      inStr = '"' + inStr + '"';
  }
  return inStr;
}

/**
 *Function to verify and arrange columns after click on sort columns data.
 *@function verifyAndArrangeColumns
 * @param {Object} declViewModel - The View Model Object
 * @param {Object} eventData - The Event Data
 */
export let verifyAndArrangeColumns = function (declViewModel, eventData) {
  if (eventData.columns && eventData.columns.length > 0) {
    for (let ii = 0; ii < eventData.columns.length; ii++) {
      console.log(eventData.columns[ii].pixelWidth);
      eventData.columns[ii].pixelWidth = eventData.columns[ii].pixelWidth | 0; // this statement here will cast double to INTEGER
    }
  }
};

/**
 * Returns only filters which are present in requiredFiltersList parameter.
 * @param {Object} filterMap - Filter map object.
 * @param {String[]} requiredFiltersList - List of filters to keep in the returned filter map.
 * NOTE: if requiredFiltersList is empty, filterMap is returned as it is.
 */
export let deleteExtraFilters = function (filterMap, requiredFiltersList) {
  if (0 === requiredFiltersList.length) return filterMap;

  for (let filterName in filterMap) {
    if (requiredFiltersList.indexOf(filterName) < 0) {
      delete filterMap[filterName];
    }
  }
};
/**
 *Function to verify and arrange columns after click on sort columns data.
 * @function getOnlyActiveFilters
 * @param {Object} responseData - The Response Data
 */
export let getOnlyActiveFilters = function (responseData) {
  let ctxSearchObject = appCtxSvc.getCtx("search");
  let ctxPrefObject = appCtxSvc.getCtx("preferences");
  let locationName =
    appCtxSvc.getCtx("locationContext")["ActiveWorkspace:Location"];

  let validFilterNames = [];
  let prefName = "V4B_" + locationName + "_AW_FiltersList";
  if (ctxPrefObject[prefName]) {
    validFilterNames = ctxPrefObject[prefName];
  }

  if (_.isEmpty(ctxSearchObject.activeFilterMap)) {
    return responseData.searchFilterMap3;
  }

  // let myActiveFilterMap = $.extend(true,{},responseData.searchFilterMap3);
  let myActiveFilterMap = JSON.parse(
    JSON.stringify(responseData.searchFilterMap3)
  );

  for (let key in myActiveFilterMap) {
    let i = 0;
    let anythingSelected = false;
    for (i = 0; i < myActiveFilterMap[key].length; i++) {
      if (myActiveFilterMap[key][i].selected) anythingSelected = true;
    }

    i = 0;
    if (anythingSelected) {
      while (i < myActiveFilterMap[key].length) {
        if (!myActiveFilterMap[key][i].selected) {
          myActiveFilterMap[key].splice(i, 1);
          i = 0;
        } else i++;
      }
    }
  }

  let finalFilterMap = {};
  for (let key in myActiveFilterMap) {
    if (myActiveFilterMap[key].length > 0)
      finalFilterMap[key] = myActiveFilterMap[key];
  }

  return finalFilterMap;
};

/**
 * This function will be call for SurrogateTasks sub-location(VGA,DALi etc.)
 * @function searchSurrogateTasks
 * @param {Object} data - Declarative view model data object
 * @author  : Anantha Bhople Date : August 19, 2019
 */
export let searchSurrogateTasks = function (data) {
  let deferred = AwPromiseService.instance.defer();
  let searchStr = "null"; // default search string
  let userCtx = appCtxSvc.getCtx("user");
  let userModelObject = cdmSvc.getObject(userCtx.uid);

  let arrayOfModelObjects = [];
  arrayOfModelObjects.push({
    uid: userCtx.uid,
    type: "User",
  });
  let attributesArrayToLoad = ["subscribed_inboxes"];

  /**
   * This load the surrogate  users(subscribed_inboxes) of currently loged-in user.
   * And on success search string will be biuld.
   * Search string will be used to perform "performSearchViewModel".
   */
  VIMSoaUtils.getProperties(arrayOfModelObjects, attributesArrayToLoad).then(
    function (response) {
      let sub_inboxes = userModelObject.props.subscribed_inboxes.uiValues;
      //Remove current login-user from array.
      let currentLoinUser = appCtxSvc
        .getCtx("userSession")
        .props.user.uiValue.split(")")[0];
      for (let ii = 0; ii < sub_inboxes.length; ii++) {
        let userName = sub_inboxes[ii].split(")")[0];
        if (userName === currentLoinUser) {
          //Compare User name and user id.
          sub_inboxes.splice(ii, 1);
        }
      }
      let requiredType = data.issue_type;
      let requiredStatus = "true";

      searchStr =
        "( " +
        data.objectType.dbValue +
        ":" +
        requiredType +
        " AND " +
        data.inProcess.dbValue +
        ":" +
        requiredStatus +
        " AND " +
        data.archiviert.dbValue +
        ":" +
        false +
        " ) " +
        "AND " +
        "( ";

      for (let user = 0; user < sub_inboxes.length; user++) {
        if (user != 0) {
          searchStr += " OR ";
        }
        if (
          "FTMSurrogateTasks" === data.searchStringType ||
          "DALiSurrogateTasks" === data.searchStringType
        ) {
          let userIdExtra = sub_inboxes[user].split("(")[1];
          let userId = userIdExtra.split(")")[0];
          if (userId != undefined)
            searchStr += data.responsibleParty.dbValue + ":" + userId;
        } else if ("VGASurrogateTasks" === data.searchStringType) {
          let userIdExtra = sub_inboxes[user].split("(")[1];
          let userId = userIdExtra.split(")")[0];
          if (userId != undefined)
            searchStr +=
              " (" +
              data.referenceLV.dbValue +
              ":" +
              userId +
              " OR " +
              data.counterLV.dbValue +
              ":" +
              userId +
              " )";
        }
      }
      searchStr += " )";

      /**
       * Check table view to set search sort criteria.
       */
      if (data.loadTableView != undefined && data.loadTableView === true) {
        data.searchSortCriteria = exports.getSearchSortCriteria(data);
      }

      /**
       * call perform search for surrogate Tasks.
       */
      VIMSoaUtils.performSearchViewModel(searchStr, data).then(function (
        soaResponse
      ) {
        return deferred.resolve(soaResponse);
      });
    }
  );
  return deferred.promise;
};
export let showNotificationBar3 = function () {
  let startTime = performance.now();
  let location = appCtxSvc.getCtx("locationContext")["ActiveWorkspace:Location"];
  if (["FTM", "CustomLocation"].includes(location)) {
    $("#vimGrid .aw-splm-tableRow").each(function (index, row) {
      // set the ID for later use.
      $(row).attr("uid", row.vmo.uid);
      try {
        let isVisited = row.vmo.props.v4b_gen_is_object_visited.dbValue;
        if (!isVisited) {
          $(row).addClass("vim-unread-issue");
          $(row)
            .find(".aw-widgets-gridCellColorIndicator")
            .addClass("vim-bmw-issue-enable-bar");
        }
      } catch (error) {
        console.log(error);
      }


      // need to change in future
      let isPriorityIssue = _.get(row.vmo.props, "v4b_priority.dbValue", null);
      if (isPriorityIssue !== null) {
        $(row).addClass("vim-ftm-priority-issue");
      }
    });

    //subscribe for update event
    if (appCtxSvc.getCtx("vimUpdateObjSubs") == null) {
      let eventSubsID = eventBus.subscribe("cdm.updated", function (eventData) {
        let location = appCtxSvc.ctx["location.titles.headerTitle"];
        if ([ "FTM"].includes(location)) {
          eventData.updatedObjects.forEach(function (modelObject) {
            if (
              modelObject.uid &&
              modelObject.type == "V4B_FTMTKB_IssueRevision"
            ) {
              // need to change in future
              let isPriorityIssue = _.get(
                modelObject.props,
                "v4b_priority.dbValues[0]",
                null
              );
              let idSelector = "[uid='" + modelObject.uid + "']";
              if (isPriorityIssue !== null) {
                $(idSelector).each(function (index, row) {
                  $(row).addClass("vim-ftm-priority-issue");
                });
              } else {
                $(idSelector).each(function (index, row) {
                  $(row).removeClass("vim-ftm-priority-issue");
                });
              }
            }
          });
        }
      });
      //update ctx
      appCtxSvc.registerCtx("vimUpdateObjSubs", eventSubsID);
    }
  }

  let endTime = performance.now();
  console.log("execution Time :" + (endTime - startTime) + "milliseconds");
};
export let resetNotifiedRows = function () {
  let acknowledgedRow = _.get(appCtxSvc, "ctx.xrtSummaryContextObject");
  $("#vimGrid .aw-splm-tableRow").each(function (index, row) {
    if (acknowledgedRow.uid === row.vmo.uid) {
      $(row).removeClass("vim-unread-issue");
      $(row)
        .find(".aw-widgets-gridCellColorIndicator")
        .removeClass("vim-bmw-issue-enable-bar");
      return;
    }
  });
};

export let doCleanup = function () {
  let vimUpdateObjSubs = appCtxSvc.getCtx("vimUpdateObjSubs");
  if (vimUpdateObjSubs != undefined) {
    eventBus.unsubscribe(vimUpdateObjSubs);
    appCtxSvc.unRegisterCtx("vimUpdateObjSubs");
  }
  let FilterOutGreenMaturity = appCtxSvc.getCtx("FilterOutGreenMaturity");
  if (FilterOutGreenMaturity != undefined) {
    appCtxSvc.unRegisterCtx("FilterOutGreenMaturity");
  }
};
/**
 * This function Will update maturity column colors for secondary chart view
 *
 * @param {object} subPanelContext
 */
export let updateChartColorForMaturity = function (subPanelContext) {
  if(subPanelContext && subPanelContext.searchState && subPanelContext.searchState.chartProvider) {
    let locationName = appCtxSvc.getCtx('locationContext')['ActiveWorkspace:Location'];
    let chartProvider = subPanelContext.searchState.chartProvider;
    if(locationName === "FTM" || locationName === "CustomLocation") {
      //check if Maturity category is selected
      if (chartProvider && chartProvider.category &&
        (chartProvider.category.internalName == "V4B_GenFTM_IssueRevision.v4b_gen_maturity" || 
        chartProvider.category.internalName == "V4B_VIMContainerRevision.v4b_Maturity_FTM")) {
            //Get All Category color from ctx chartprovider
            let columnColors = chartProvider.columns;
            columnColors.forEach( (element, index) => {
              $("rect.highcharts-point.highcharts-color-" + index).each( (i,ele) => {
                ele.style.fill = getColorCodeforMaturity(element.stringValue);
              });
            });
      } else if(chartProvider && chartProvider.columns) {
            // remove style filled for others
            let columnColors = chartProvider.columns;
            columnColors.forEach(function (element, index) {
            $("rect.highcharts-point.highcharts-color-" + index).each(function (i,ele) {
              ele.style.fill = "";
            });
        });
      }
    }
  }
};
/**
 * This function will return color code according to StringValue of Column .
 * used in function  "updateChartColorForMaturity"
 * @param {string} colorString maturrity value
 * @return {string} rgba color code value for the given maturity string
 */
let getColorCodeforMaturity = function (colorString) {
  let colorCode = "";
  switch (colorString) {
    case "green":
      colorCode = "rgba(0,176,80,255)";
      break;
    case "red":
      colorCode = "rgba(255,0,0,255)";
      break;
    case "yellow":
      colorCode = "rgba(255,192,0,255)";
      break;
    case "blue":
      colorCode = "rgba(76,113,136,255)";
      break;
    case "purple":
      colorCode = "rgba(112,48,160,255)";
       break;
     case "white":
       colorCode = "rgb(85,160,185)";
      break;
    case "$NONE":
      colorCode = "rgba(127,127,127,255)";
      break;
    default:
      break;
  }
  return colorCode;
};

export let loadSavedSearch = (eventdata, searchCriteria) => {
  AwStateService.instance.go(appCtxSvc.getCtx("locationContext")["ActiveWorkspace:SubLocation"], {
    filter: eventdata.filter,
    searchCriteria: searchCriteria.searchString
  });
}
/**
 * buildTitle
 * @function buildTitle
 * @param {Object}searchObject - search state object
 * @return {Promise} Promise containing the localized text
 */
export let buildTitle = function( searchObject ) {
    let localTextBundle = null;
    if( searchObject ) {
        localTextBundle = localeService.getLoadedText(
          app.getBaseUrlPath() + "/i18n/VIMSublocationMessages"
        );
        let totalFound;
        let searchCriteria = '';
        let label = '';
        //Get search Criteria, Total Found and Crumbs
        if( searchObject.thresholdExceeded === 'true' ) {
            return '';
        }
        if( searchObject.totalFound >= 0 ) {
            totalFound = searchObject.totalFound;
        }
        if( searchObject.criteria && searchObject.criteria.searchString ) {
          let sublocationTitleKey = appCtxSvc.getCtx("sublocation.label.key"); 
          searchCriteria = localTextBundle[sublocationTitleKey];
        }
        if( searchObject.label ) {
            label = searchObject.label;
        }
        if( searchObject.showNoCriteriaMessage && searchObject.noCriteriaMessage && searchObject.noCriteriaMessage.length > 0
            && ( !searchCriteria || searchCriteria && searchCriteria.length === 0 ) ) {
            return localeService.getLocalizedTextFromKey( searchObject.noCriteriaMessage ).then( ( localizedText ) => {
                if( localizedText && localizedText.length > 0 ) {
                    return localizedText;
                }
                return '';
            } );
        } else if( searchObject.showNoCriteriaMessage && !searchObject.noCriteriaMessage
            && ( !searchCriteria || searchCriteria && searchCriteria.length === 0 ) ) {
            return localeService.getLocalizedTextFromKey( 'SearchMessages.resultsNoCriteriaDefaultMessage' ).then( ( localizedText ) => {
                return localizedText;
            } );
        }
        return searchFilterSvc.loadBreadcrumbTitle( label, searchCriteria, totalFound ).then( ( localizedText ) => {
            return localizedText;
        } );
    }
    return Promise.resolve( {} );
};

export const getSearchContextforVIM = ( searchContext )=>{
  if( !searchContext.criteria || AwStateService.instance.params.uid ) {
      searchContext.criteria = {};
  }
  let criteria = '';
  if( AwStateService.instance.params.uid ) {
      let mo = cdm.getObject( AwStateService.instance.params.uid );
      if( mo ) {
          let projList = "Projects";
          let projId = mo.props.awp0CellProperties.uiValues[ 0 ];
          let index = projId.indexOf( ':' );
          projId =  index === -1 ? projId : projId.substring( index + 1, projId.length );
          criteria = '"' + projList + '":"' + projId + '"';
          AwStateService.instance.params.searchCriteria = criteria;
          let locationCtx = appCtxSvc.getCtx( 'location.titles' );
          if( locationCtx ) {
              locationCtx.headerTitle = projId;
              appCtxSvc.updateCtx( 'location.titles', locationCtx );
          }
      }
      searchContext.criteria.searchString = criteria;
  }
  return AwPromiseService.instance.when( searchContext );
};

export const getSubPanelContextDataforVIM = ( provider ) => {
  let searchContext = searchFilterService.buildSearchFilters( provider.context );
  return getSearchContextforVIM( searchContext ).then( function( updatedSearchContext ) {
      provider.context.search.criteria = updatedSearchContext.criteria;
      return provider;
  } );
};

exports = {
  updateChartColorForMaturity,
  doCleanup,
  resetNotifiedRows,
  showNotificationBar3,
  searchSurrogateTasks,
  deleteExtraFilters,
  getOnlyActiveFilters,
  loadData,
  verifyAndArrangeColumns,
  getSearchFilterCategories,
  openShowObjectInEditMode,
  buildTitle,
  loadSavedSearch,
  getSubPanelContextDataforVIM,
  getSearchContextforVIM
};

export default exports;

// @<COPYRIGHT>@
// ==================================================
// Copyright 2017.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/*global
 define
 */

/**
 * Simple Alert service for sample command Handlers
 *
 * @module js/V4BTriggerJobService
 */
import _ from "lodash";
import eventBus from "js/eventBus";
import $ from "jquery";
import appCtxService from "js/appCtxService";
import msgService from "js/messagingService";
import soaSvc from "soa/kernel/soaService";

("use strict");

let exports = {};

export let callTriggerJob = function () {
  // call the service
  let url = appCtxService.getCtx("preferences.V4B_Dx_Order_Web_URL");
  if (url == undefined) {
    msgService.showError("V4B_Dx_Order_Web_URL preference not set.");
    return;
  }
  url += "triggerjob";

  let selMO = appCtxService.getCtx("selected");

  let sendCpyID = selMO.props.v4b_dxorder_send_company_id.uiValues[0];
  let sendCpy = selMO.props.v4b_dxorder_send_company.uiValues[0];
  let sendLoc = selMO.props.v4b_dxorder_send_location.uiValues[0];
  let sendDept = selMO.props.v4b_dxorder_send_depart.uiValues[0];
  let sendPer = selMO.props.v4b_dxorder_send_person.uiValues[0];

  let recCpyID = selMO.props.v4b_dxorder_rec_company_id.uiValues[0];
  let recCpy = selMO.props.v4b_dxorder_rec_company.uiValues[0];
  let recLoc = selMO.props.v4b_dxorder_rec_location.uiValues[0];
  let recDept = selMO.props.v4b_dxorder_rec_depart.uiValues[0];
  let recPer = selMO.props.v4b_dxorder_rec_person.uiValues[0];

  // change to real file path attribute
  let file = selMO.props.v4b_dxorder_description.uiValues[0];

  // validate if all the attribtues are filled
  if (
    sendCpy == "" ||
    sendLoc == "" ||
    sendDept == "" ||
    sendPer == "" ||
    recCpy == "" ||
    recLoc == "" ||
    recDept == "" ||
    recPer == "" ||
    file == ""
  ) {
    msgService.showError("Incomplete data, can not start the Export Job.");
    return;
  }

  msgService.showInfo("Starting the export job");
  eventBus.publish("progress.start", {
    endPoint: url,
  });

  let input = {
    contactfrom: {
      companyid: sendCpyID,
      companylong: sendCpy,
      departmentlong: sendDept,
      locationlong: sendLoc,
      personname: sendPer,
    },
    contactto: {
      companyid: recCpyID,
      companylong: recCpy,
      departmentlong: recDept,
      locationlong: recLoc,
      personname: recPer,
    },
    datasetfrom: {
      csyshortname: "TCM-PAPS", // fixed value
      ffmshortname: "TCM-PAPS", // fixed value
    },
    datasetto: {
      csyshortname: "TCM-PAPS", // fixed value
      ffmshortname: "TCM-PAPS", // fixed value
    },
    dfmshortname: "OFTP mit Engdat",
    files: [file],
  };

  $.ajax({
    url: url,
    type: "post",
    dataType: "json",
    data: JSON.stringify(input),
    headers: {
      accept: "application/json",
      "Content-Type": "application/json",
    },
    success: function (data) {
      eventBus.publish("progress.end", {
        endPoint: url,
      });

      if (data.jobid && data.jobid != "") {
        msgService.showError("Success: OpenDXM Job ID: " + data.jobid);

        //set property and refresh the page
        let inputData = {
          objects: [selMO],
          attributes: {
            v4b_dxorder_delivery: {
              stringVec: ["active"],
            },
            v4b_dxorder_delivery_id: {
              stringVec: [data.jobid],
            },
          },
        };

        soaSvc
          .post("Core-2007-01-DataManagement", "setProperties", inputData)
          .then(function (response) {
            eventBus.publish("cdm.relatedModified", {
              relatedModified: response.data.updatedObjects,
            });
          });
      } else if (data.errorcode && data.errorcode != "") {
        msgService.showError(
          "Error: Call to OpenDXM failed with code: " + data.errorcode
        );
      }
    },
    error: function (jqXHR, exception) {
      eventBus.publish("progress.end", {
        endPoint: url,
      });
      let msg = "";
      if (jqXHR.status === 0) {
        msg = "Not connect.\n Verify Network.";
      } else if (jqXHR.status == 404) {
        msg = "Requested page not found. [404]";
      } else if (jqXHR.status == 500) {
        msg = "Internal Server Error [500].";
      } else if (exception === "parsererror") {
        msg = "Requested JSON parse failed.";
      } else if (exception === "timeout") {
        msg = "Time out error.";
      } else if (exception === "abort") {
        msg = "Ajax request aborted.";
      } else {
        msg = "Uncaught Error.\n" + jqXHR.responseText;
      }

      msgService.showError(
        "Error: Could not start the Export Job. Please contact administrator."
      );
      console.log("------- error in TriggerJob::" + msg);
    },
  });
};
exports = {
  callTriggerJob,
};

export default exports;



import app from 'app';
import _eventBus from 'js/eventBus';
import $ from 'jquery';
import _soaSvc from 'soa/kernel/soaService';
import _appCtxSvc from 'js/appCtxService';
import _cdm from 'soa/kernel/clientDataModel';
import _cmdPanelSvc from 'js/commandPanel.service';
import AwPromiseService from 'js/awPromiseService';
import soaSvc from 'soa/kernel/soaService';
import appCtxService from "js/appCtxService";
import eventBus from 'js/eventBus';


var exports = {};
var timeSliceIntentFormulaBeforeReset = '';

export let openTimeSliceCommandAction = function (commandId, location, object) {

    if (_appCtxSvc.ctx.g4b_PreviousTimeSliceOnCD) {
        _appCtxSvc.unRegisterCtx("g4b_PreviousTimeSliceOnCD");
    }

    _eventBus.publish("G4B_ProductLinesListViewModel.updateSelection", object);
    var promise = exports.findSavedQueriesForEffectivity();
    $.when(promise).done(function (response) {

        var promise1 = exports.fetchCurrentEffectivity(response, object);
        $.when(promise1).done(function (response) {

            if (response.arrayOfResults[0].objectUIDS) {
                var promise2 = exports.getBookmarkData(response);
                $.when(promise2).done(function (response) {
                    var uid = response.plain[0];
                    var currentEffectivity = response.modelObjects[uid].props.fgf0EffectivityFormula.dbValues[0];
                    if (currentEffectivity != null && currentEffectivity.indexOf('DER') > -1 && currentEffectivity.indexOf('BP') > -1 && currentEffectivity.indexOf('SOP') > -1) {
                        var _curr = currentEffectivity.split("&");

                        var sop = _curr[1].split('= ');
                        sop = sop[1].split(" ");
                        var der = _curr[2].split('= ');
                        der = der[1].split(" ");
                        var bp = _curr[3].split('= ');
                        bp = bp[1].split(" ");

                        var tsOption = der[0] + '_' + bp[0] + '_' + sop[0];
                        var selectedTs = {
                            g4b_der: der[0],
                            g4b_bp: bp[0],
                            g4b_sop: sop[0],
                            g4b_quickSelect: tsOption
                        }

                        if (_appCtxSvc.ctx.g4b_PreviousTimeSliceOnCD) {
                            _appCtxSvc.updateCtx("g4b_PreviousTimeSliceOnCD", selectedTs);
                        }
                        else {
                            _appCtxSvc.registerCtx("g4b_PreviousTimeSliceOnCD", selectedTs);
                        }
                    }
                    exports.g4b_activateCommandPanel(commandId, location, object);

                });
            }
            else {
                exports.g4b_activateCommandPanel(commandId, location, object);
            }


        });

    });



}


export let findSavedQueriesForEffectivity = function () {
    var deferred = $.Deferred();
    var inputData = {
        inputCriteria: [{
            queryNames: ["__G4B_Get_Fgf0BookmarkData_For_User_And_ModelID"],
            queryDescs: ["This query returns the Fgf0BookmarkData object for a given User ID and Model ID (Collaborative Design). The Fgf0BookmarkData object holds the currently active Effectivity Formula for the user."],
            queryType: 0
        }]
    }

    _soaSvc.post("Query-2010-04-SavedQuery", "findSavedQueries", inputData).then(
        function (response) {
            if (response) {
                deferred.resolve(

                    response
                )

            }
        });
    return deferred.promise();
}

export let fetchCurrentEffectivity = function (response, object) {
    var deferred = $.Deferred();
    //var mdlId =_appCtxSvc.ctx.selected.props.mdl0model_id.dbValues[0];
    var userId = _appCtxSvc.ctx.userSession.props.user_id.dbValue;
    var inputData = {
        input: [
            {
                query: {
                    type: response.savedQueries[0].type,
                    uid: response.savedQueries[0].uid
                },
                entries: ["Model ID", "Id"],
                values: [object.cellHeader2, userId],
                clientId: "AWC"
            }
        ]
    }

    if (_appCtxSvc.ctx.selected.type == "Fgd0DesignSubsetElement") {
        var collDesignObjName = _appCtxSvc.ctx.selected.props.fgd0ModelObject.uiValues[0];
        var cdObjectName = collDesignObjName.substring(0, collDesignObjName.indexOf(';'));
        inputData.input[0].values = [cdObjectName, userId];
    }
    if (_appCtxSvc.ctx.userSession.props.fnd0locale.dbValues[0] == 'de_DE') {
        inputData.input[0].entries = ["Modell-ID", "ID"]
    }

    _soaSvc.post("Query-2008-06-SavedQuery", "executeSavedQueries", inputData).then(
        function (response) {
            if (response) {
                deferred.resolve(

                    response
                )

            }
        });
    return deferred.promise();
};

export let getBookmarkData = function (response) {
    /* var bookmarkDataObj = _cdm.getObject(response.arrayOfResults[0].objectUIDS[0]);
     console.log("bookmark obj", bookmarkDataObj);*/
    var deferred = $.Deferred();
    var inputData = {
        objects: [{
            uid: response.arrayOfResults[0].objectUIDS[0],
            type: 'Fgf0BookmarkData'
        }],
        attributes: ["fgf0EffectivityFormula"]
    }

    _soaSvc.post("Internal-Core-2007-12-Session", "getProperties", inputData).then(
        function (response) {
            if (response) {
                deferred.resolve(

                    response
                )

            }
        });
    return deferred.promise();
};

export let resetTimeSlicePanel = function() {
    var i, key;
    var tsInfoRadioButtonData = _appCtxSvc.ctx.g4b_TimeSliceContext.tsInfoRadioButtonData;
        for(key in tsInfoRadioButtonData){
            for(i=0; i<tsInfoRadioButtonData[key].length; i++){
                    tsInfoRadioButtonData[key][i].isHidden = false;
                    tsInfoRadioButtonData[key][i].radioSelected = false;
            }
        }
    var tsInfoQuickSelectData = _appCtxSvc.ctx.g4b_TimeSliceContext.tsInfoQuickSelectRadioButtonData;
    for(i=0; i<tsInfoQuickSelectData.length; i++){
        tsInfoQuickSelectData[i].isHidden = false;
        tsInfoQuickSelectData[i].radioSelected = false;
    }
    //reset user selections
    var currentUserSelections = _appCtxSvc.ctx.g4b_TimeSliceContext.timeSliceAttrSelected;
    for(key in currentUserSelections){
        currentUserSelections[key] = '';
    }
    
};

export let getTimeSliceInfoFromCD = function( data, selected ) {

    //console.log("Hallo Kollegen");

    if(_appCtxSvc.ctx.g4b_TimeSliceContext){
        delete _appCtxSvc.ctx.g4b_TimeSliceContext;
    }
    
    var tsInfo_ModelObjects = [];

    var collDesObj = '';
    if(selected.type =='G4B_CollaborativeDesign'){
        collDesObj = _cdm.getObject(selected.uid);
    }
    //in case of newly added subset to workset the subset is already in selected ctx after creation
    if(selected.type == 'Fgd0DesignSubsetElement'){
        collDesObj = _cdm.getObject(selected.props.fgd0ModelObject.dbValues[0]);
        //get bookmarkdata when the panel is opened in case of a child/sibling subset is created inside a workset
        getBookmarkDataForSubset(collDesObj);
    }
    //in case when user opens the CD via other means
    if(selected.type == 'Fgf0ApplicationModel'){
        collDesObj = _cdm.getObject(_appCtxSvc.ctx.occmgmtContext.openedElement.props.awb0UnderlyingObject.dbValues[0]);
    }
    //in case of subset definition
    if(selected.type == 'Fgf0SubsetElement'){
        collDesObj = _cdm.getObject(selected.props.fgf0ModelObject.dbValues[0]);
    }

    var inputData = {};
    inputData.objects = [collDesObj];
    inputData.attributes = ["g4b_TSInfos2"];

    var propPolicySvc = app
    .getInjector()
    .get("soa_kernel_propertyPolicyService");
    var policy = {
        "types": [ {
            "name": "G4B_TSInfo2",
            "properties": [ {
                "name": "g4b_BP"
            },
            {
                "name": "g4b_DER"
            },
            {
                "name": "g4b_SOP"
            } ]
        } ]
    };

    propPolicySvc.register( policy );

    _soaSvc.post("Core-2006-03-DataManagement", "getProperties", inputData).then(
        function (response) {
            if(response){
                //console.log("TSInfo: " + collDesObj.props.g4b_TSInfos2.dbValues[0]);

                //get TSInfo modelobjects from servicedata
                for(var inx=0; inx< collDesObj.props.g4b_TSInfos2.dbValues.length; inx++){
                    if(response.modelObjects[collDesObj.props.g4b_TSInfos2.dbValues[inx]] != undefined){
                        tsInfo_ModelObjects.push(response.modelObjects[collDesObj.props.g4b_TSInfos2.dbValues[inx]]);
                    }
                }
                propPolicySvc.unregister(policy);    
            }
        }
    ).then(
        function(){

            //if( _appCtxSvc.ctx.userSession.props.role_name.dbValue !== 'Gepard IT Admin')
              //  {
                    var user = _cdm.getObject(_appCtxSvc.ctx.userSession.props.user.dbValue);
                    
                    //var role = _cdm.getObject(_appCtxSvc.ctx.userSession.props.role.dbValue);
                    
                        var  inputs3 = {
                        userProjectsInfoInputs: [{
                        user: user,
                        activeProjectsOnly: true,
                        privilegedProjectsOnly: false,
                        programsOnly: false,
                        clientId: "1"
                        }]
                    }
                    _soaSvc.post("Core-2009-10-ProjectLevelSecurity", "getUserProjects", inputs3).then(
                        function ( response2 ) {
                            if( response2 ){
                              
                              if( _appCtxSvc.ctx.userSession.props.role_name.dbValue !== 'Gepard IT Admin')
                            {
                                var userProjects = response2.userProjectInfos[0].projectsInfo;//[1].project.props.object_string.dbValues[0];
                                
                                var curated_tsInfo_ModelObjects = [];
                                 //get TSInfo modelobjects from servicedata
                            for(var inx=0; inx < tsInfo_ModelObjects.length; inx++){
                                
                                    if( userProjects != undefined )
                                    {
                                        for( var inx2=0; inx2 < userProjects.length; inx2++ )
                                        {
                                            if( userProjects[inx2].project.props.object_string.dbValues[0] ===  tsInfo_ModelObjects[inx].props.g4b_DER.dbValues[0] )
                                            {

                                                curated_tsInfo_ModelObjects.push(tsInfo_ModelObjects[inx]);
                                            }
                                        }
                                    }
                                    
                                          
                                   
                                
                            }
                            tsInfo_ModelObjects = curated_tsInfo_ModelObjects;
                        }
                                
                            }

                            var tsInfoMasterJSON = [];
                            var tsInfoRadioButtonDataJSON = {
                                "g4b_DER": [],
                                "g4b_BP": [],
                                "g4b_SOP": []
                            }
                            var tsInfoQuickSelectRadioButtonDataArr = [];
            
                            for(var jnx=0; jnx<tsInfo_ModelObjects.length; jnx++){
                                //create master Data JSON for all available time slices
                                //var prevSelection = _appCtxSvc.getCtx("g4b_PreviousTimeSliceOnCD");
                                var timeSliceString = tsInfo_ModelObjects[jnx].props.g4b_DER.dbValues[0]+ '_'+
                                tsInfo_ModelObjects[jnx].props.g4b_BP.dbValues[0]+ '_'+
                                tsInfo_ModelObjects[jnx].props.g4b_SOP.dbValues[0];
            
                                var timeSliceParameters = {
                                    "g4b_DER": tsInfo_ModelObjects[jnx].props.g4b_DER.dbValues[0],
                                    "g4b_BP": tsInfo_ModelObjects[jnx].props.g4b_BP.dbValues[0],
                                    "g4b_SOP": tsInfo_ModelObjects[jnx].props.g4b_SOP.dbValues[0],
                                    "timeSliceString": timeSliceString
                                }
                                tsInfoMasterJSON.push(timeSliceParameters);
            
                                tsInfoQuickSelectRadioButtonDataArr.push({
                                        "propDisplayValue": timeSliceString,
                                        "radioSelected": false,
                                        "isHidden":false
                                    });
            
                                //create initial JSON data for displaying the radio buttons
                                //only push unique content into the array
                                if(isalreadyPresent(tsInfo_ModelObjects[jnx].props.g4b_DER.dbValues[0],tsInfoRadioButtonDataJSON.g4b_DER) == false ){
                                    tsInfoRadioButtonDataJSON.g4b_DER.push({
                                        "propDisplayValue": tsInfo_ModelObjects[jnx].props.g4b_DER.dbValues[0],
                                        "radioSelected": false,
                                        "isHidden":false
                                    });
                                }
                                if(isalreadyPresent(tsInfo_ModelObjects[jnx].props.g4b_BP.dbValues[0],tsInfoRadioButtonDataJSON.g4b_BP) == false ){
                                    tsInfoRadioButtonDataJSON.g4b_BP.push({
                                        "propDisplayValue": tsInfo_ModelObjects[jnx].props.g4b_BP.dbValues[0],
                                        "radioSelected": false,
                                        "isHidden":false
                                    });
                                }
                                if(isalreadyPresent(tsInfo_ModelObjects[jnx].props.g4b_SOP.dbValues[0],tsInfoRadioButtonDataJSON.g4b_SOP) == false ){
                                    tsInfoRadioButtonDataJSON.g4b_SOP.push({
                                        "propDisplayValue": tsInfo_ModelObjects[jnx].props.g4b_SOP.dbValues[0],
                                        "radioSelected": false,
                                        "isHidden":false
                                    });
                                }
                                
                            }
            
                            //Sort arrays ASC alphanumerically
                            sortByTsKey(tsInfoQuickSelectRadioButtonDataArr,"propDisplayValue");
                            sortByTsKey(tsInfoRadioButtonDataJSON.g4b_DER,"propDisplayValue");
                            sortByTsKey(tsInfoRadioButtonDataJSON.g4b_BP,"propDisplayValue");
                            sortByTsKey(tsInfoRadioButtonDataJSON.g4b_SOP,"propDisplayValue");
                            
                            var timeSliceAttrSelected = {
                                'g4b_DER': '',
                                'g4b_BP' : '',
                                'g4b_SOP': ''
                            };
                            var tsCtx = {
                                'collaborativeDesign': collDesObj,
                                'tsInfoMaster':tsInfoMasterJSON,
                                'tsInfoRadioButtonData': tsInfoRadioButtonDataJSON,
                                'tsInfoQuickSelectRadioButtonData':tsInfoQuickSelectRadioButtonDataArr,
                                'timeSliceAttrSelected': timeSliceAttrSelected
                            }
                            data.g4b_TimeSliceContext = tsCtx;
                            //console.log("tsInfos found:  "+ tsInfoMasterJSON);
                            if(_appCtxSvc.getCtx('g4b_TimeSliceContext') != undefined){
                                _appCtxSvc.ctx.g4b_TimeSliceContext.collaborativeDesign = selected;
                                _appCtxSvc.ctx.g4b_TimeSliceContext.tsInfoMaster = tsInfoMasterJSON;
                                _appCtxSvc.ctx.g4b_TimeSliceContext.tsInfoRadioButtonData = tsInfoRadioButtonDataJSON;
                                _appCtxSvc.ctx.g4b_TimeSliceContext.tsInfoQuickSelectRadioButtonData = tsInfoQuickSelectRadioButtonDataArr;
                                _appCtxSvc.ctx.g4b_TimeSliceContext.timeSliceAttrSelected = timeSliceAttrSelected;
                            }
                            else{
                                _appCtxSvc.registerCtx('g4b_TimeSliceContext',tsCtx);
                            }
                        });
                 //   }




            //Commented. Only show label for existing TS. No need to preselect radio-buttons
            /*angular.element(document.getElementsByTagName('g4b-timeslice-radio')[0]).isolateScope().$evalAsync()

            //pre-select existing TS found:
            var delayInMilliseconds = 100; //Race condition: 0.1 sec delay added to let DOM load the TS radio buttons and then select the existing TS on panel
            setTimeout(function() {
                var existingTimeSliceOnCD = _appCtxSvc.getCtx('g4b_PreviousTimeSliceOnCD.g4b_quickSelect');
                angular.element(document.getElementsByTagName('g4b-timeslice-radio')[0]).isolateScope().select(existingTimeSliceOnCD,'timeslice_string')
                angular.element(document.getElementsByTagName('g4b-timeslice-radio')[0]).isolateScope().$evalAsync()
            }, delayInMilliseconds);
            */

    });

};

export let updateRadioButtonsData = function() {
    //console.log("update");
};

//Start point:
export let createInputForTransientRevRule = function () {
    var der = getSelectedRadio('g4b_DER');
    var bp = getSelectedRadio('g4b_BP');
    var sop = getSelectedRadio('g4b_SOP');


    var TSgroupSelections = [];
    var family = ['BP', 'DER', 'SOP'];
    var tsCtx = [bp, der, sop];


    for (var index = 0; index < family.length; index++) {
        var familyAndOptionValue = {
            familyName: family[index],
            optionValue: tsCtx[index]
        };
        TSgroupSelections.push(familyAndOptionValue);
    }

    //var revisionRule = _appCtxSvc.getCtx("userSession").props.awp0RevRule.dbValue;
    var collaborativeDesignUid = '';
    if(_appCtxSvc.ctx.selected.type == 'G4B_CollaborativeDesign'){
        collaborativeDesignUid = _appCtxSvc.ctx.selected.uid;
    }
    if(_appCtxSvc.ctx.selected.type == 'Fgd0DesignSubsetElement'){
        collaborativeDesignUid = _appCtxSvc.ctx.selected.props.fgd0ModelObject.dbValues[0];
    }
    if(_appCtxSvc.ctx.selected.type == 'Fgf0SubsetElement'){
        collaborativeDesignUid = _appCtxSvc.ctx.selected.props.fgf0ModelObject.dbValues[0];
    }
    if(_appCtxSvc.ctx.selected.type == 'Fgf0ApplicationModel'){
        collaborativeDesignUid = _appCtxSvc.ctx.occmgmtContext.openedElement.props.awb0UnderlyingObject.dbValues[0];
    }

    var inputData = {
        objects: [{
            uid: collaborativeDesignUid,
            type: 'G4B_CollaborativeDesign'
        }],
        attributes: ["mdl0config_product_name", "mdl0config_prod_namespace", "g4b_EffProductNameSpace"]
    }

    _soaSvc.post("Internal-Core-2007-12-Session", "getProperties", inputData).then(
        function (response) {
            if (response) {

                var context = TSgroupSelections;
                if (context != undefined) {

                    var objectId = response.modelObjects[collaborativeDesignUid].props.g4b_EffProductNameSpace.dbValues[0];
                    var formula = "";
                    var concateAmp = "&"

                    for (var i = 0; i < context.length; i++) {
                        var _formula = '[' + objectId + ']' + context[i].familyName + '=' + context[i].optionValue;
                        if(i != context.length-1){
                            _formula = _formula + concateAmp
                        }
                        formula = formula + _formula;
                    }
                    if(_appCtxSvc.ctx.selected.type == 'G4B_CollaborativeDesign'){
                        exports.setRevRuleIntentFormula(formula, _appCtxSvc.ctx.selected.uid);
                    }
                    else{
                        var rootElementUid = _appCtxSvc.ctx.occmgmtContext.rootElement.props.awb0UnderlyingObject.dbValues[0];
                        exports.setRevRuleIntentFormula(formula, rootElementUid);
                    }

                    /*var rootElementUid = _appCtxSvc.ctx.occmgmtContext.rootElement.props.awb0UnderlyingObject.dbValues[0];
                    var openedElementType = _appCtxSvc.ctx.occmgmtContext.openedElement.props.awb0UnderlyingObjectType.dbValues[0];

                    //case: when user opens a collaborative design
                    if(openedElementType === 'G4B_CollaborativeDesign'){
                        exports.setRevRuleIntentFormula(formula, rootElementUid);
                    }
                    //case: for a subset inside a workset
                    if(openedElementType === 'G4B_AnlCtxWrkSetRevision' || openedElementType === 'G4B_StatCPWrkSetRevision' || openedElementType === 'G4B_ProtTypeSpecRevision'){
                        exports.setRevRuleIntentFormula(formula, rootElementUid);
                    }
                    //case: for subset definition
                    if(openedElementType === 'G4B_DynamicConfigProduct' || openedElementType === 'G4B_EvalPhaseSubsetDefn'){
                        exports.setRevRuleIntentFormula(formula, rootElementUid)
                    }*/

                }
                //Calling postSaveRecipeProcess
                if (_appCtxSvc.ctx.selected.type == 'Fgd0DesignSubsetElement') {
                    var effectivityFormula = "Unit=1, BP = " + bp + " & DER = " + der + " & SOP = " + sop + "";
                    var inputData = {
                        parentObject: {
                            uid: collaborativeDesignUid,
                            type: 'G4B_CollaborativeDesign'
                        },
                        createdObject: {
                            uid: _appCtxSvc.ctx.selected.uid,
                            type: _appCtxSvc.ctx.selected.type
                        },
                        effectivityString: effectivityFormula
                    }
                    _soaSvc.post('AWS2-2018-05-KoVA', 'postSaveRecipeProcess', inputData).then(function (response) {});
                }
            }
        });
};

var getBookmarkDataForSubset = function(collDesObj){
    if(_appCtxSvc.ctx.g4b_PreviousTimeSliceOnCD){
        _appCtxSvc.unRegisterCtx("g4b_PreviousTimeSliceOnCD");
    }

    var promise = exports.findSavedQueriesForEffectivity();
    $.when(promise).done(function (response) {

        var promise1 = exports.fetchCurrentEffectivity(response,collDesObj);
        $.when(promise1).done(function (response) {
            
            if(response.arrayOfResults[0].objectUIDS){
                var promise2 = exports.getBookmarkData(response);
            $.when(promise2).done(function (response) {
                 var uid = response.plain[0];
                var currentEffectivity = response.modelObjects[uid].props.fgf0EffectivityFormula.dbValues[0];
                if(currentEffectivity != null && currentEffectivity.indexOf('DER') > -1 && currentEffectivity.indexOf('BP') > -1 && currentEffectivity.indexOf('SOP') > -1){
                    var _curr = currentEffectivity.split("&");

                    var sop =_curr[1].split('= ');
                    sop = sop[1].split(" ");
                    var der =_curr[2].split('= ');
                    der = der[1].split(" ");
                    var bp =_curr[3].split('= ');
                    bp= bp[1].split(" ");

                    var tsOption = der[0]+'_'+bp[0]+'_'+sop[0];
                    var selectedTs = {
                        g4b_der:der[0],
                        g4b_bp:bp[0],
                        g4b_sop:sop[0],
                        g4b_quickSelect:tsOption
                    }

                    if(_appCtxSvc.ctx.g4b_PreviousTimeSliceOnCD){
                        _appCtxSvc.updateCtx("g4b_PreviousTimeSliceOnCD",selectedTs);
                    }
                    else{
                        _appCtxSvc.registerCtx("g4b_PreviousTimeSliceOnCD",selectedTs);
                    }
                }                            
            });
            }
        });
    });
};

var isalreadyPresent = function(value,jsonDataArr){
    var result = false;
    for(var inx=0; inx<jsonDataArr.length; inx++){
        if(jsonDataArr[inx].propDisplayValue == value){
            result = true;
        }
    }
    return result;
};

//sort JSON array based on an input key
var sortByTsKey = function(array, key) {
    return array.sort(function(a, b) {
        var x = a[key];
        var y = b[key];
        // eslint-disable-next-line no-extra-parens
        return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
};

var getSelectedRadio = function (propName) {
    var radioSelected = '';
    if (_appCtxSvc.ctx.g4b_TimeSliceContext.timeSliceAttrSelected[propName].length > 0) {
        radioSelected = _appCtxSvc.ctx.g4b_TimeSliceContext.timeSliceAttrSelected[propName];
    } else {
        var tsInfoRadioButtonData = _appCtxSvc.ctx.g4b_TimeSliceContext.tsInfoRadioButtonData;
        for (var inx = 0; inx < tsInfoRadioButtonData[propName].length; inx++) {
            if (tsInfoRadioButtonData[propName][inx].radioSelected == true) {
                radioSelected = tsInfoRadioButtonData[propName][inx].propDisplayValue
            }
        }
    }
    if (radioSelected =='') {
        radioSelected = _appCtxSvc.ctx.g4b_PreviousTimeSliceOnCD[propName.toLowerCase()];
    }
    return radioSelected;
};

export let subscribeToStructureReset = function(){
    timeSliceIntentFormulaBeforeReset = appCtxService.getCtx("aceActiveContext.context.productContextInfo.props.fgf0IntentFormulaList.dbValues[0]");
    let SVRRules = appCtxService.getCtx("aceActiveContext.context.productContextInfo.props.awb0letiantRules.dbValues");
    let eventSubscription = eventBus.subscribe('occDataProvider.treeNodesLoaded', function () {
        let collaborativeDesignUID = appCtxService.getCtx("occmgmtContext.rootElement.props.awb0UnderlyingObject.dbValues[0]");
        exports.setRevRuleIntentFormula(timeSliceIntentFormulaBeforeReset,collaborativeDesignUID,SVRRules);
        eventBus.unsubscribe(eventSubscription);
    });
};

export let setRevRuleIntentFormula = function (formula, openedElementUid,SVRRules) {

    let getPartitionSchemeUidPromise = getPartitionSchemeUid("MMG-FB", openedElementUid);
    $.when(getPartitionSchemeUidPromise).done(
        function (partitionSchemeObj) {
            let productContextInfo = appCtxService.getCtx('aceActiveContext').context.productContextInfo;
            if (SVRRules) {
                if (SVRRules.length == 0) {
                    SVRRules = [''];
                }
            }

            if (productContextInfo != undefined) {
                let path = "aceActiveContext.context.configContext";
                let value = {
                    "r_uid": productContextInfo.props.awb0CurrentRevRule.dbValues[0],
                    "let_uids": SVRRules,
                    "fromUnit": 1,
                    "toUnit": 1,
                    "startDate": productContextInfo.props.awb0StartEffDates.dbValues[0],
                    "endDate": productContextInfo.props.awb0EndEffDates.dbValues[0],
                    "intentFormula": formula,
                    "startFreshNavigation": true,
                    "org_uid": partitionSchemeObj.uid
                };

                appCtxService.updatePartialCtx(path, value);
            }
            eventBus.publish('closeTimeslicePanel');
            eventBus.publish("G4bCreateSubsetPanel.closePopup");
        });
};

/**
     * Fetch the partition scheme uid for a give partion scheme name and target object
     * @param {string} partitionSchemeName Name of the partition scheme to look for.
     * @param {string} targetObjectUid Uid of target configured object for which relevant partition schemes are to be fetched. e.g. uid of collaborative design or Cpd0DesignSubsetElement 
     * e.g. getPartitionSchemeUid("MMG-FB", "7pc5G6T35AChlD")
     */
let getPartitionSchemeUid = function(partitionSchemeName, targetObjectUid){
    let deferred = AwPromiseService.instance.defer();
    let inputData = {
        "searchInput": {
            "maxToLoad": 1,
            "maxToReturn": 1,
            "providerName": "Fgf0PartitionSchemeProvider",
            "searchCriteria": {
                "uid": targetObjectUid,
                "searchString": partitionSchemeName
            }
        }
    }

    soaSvc.post("Query-2014-11-Finder", "performSearch", inputData).then(
        function(response){
            let resultPartitionScheme = null;
            if(response.searchResults && response.searchResults.length > 0){
                resultPartitionScheme ={
                    uid: response.searchResults[0].uid,
                    type: response.searchResults[0].type
                }
            }
            deferred.resolve(resultPartitionScheme);
        });

    return deferred.promise;
}

export default exports = {
    getBookmarkData,
    fetchCurrentEffectivity,
    findSavedQueriesForEffectivity,
    openTimeSliceCommandAction,
    resetTimeSlicePanel,
    getTimeSliceInfoFromCD,
    updateRadioButtonsData,
    createInputForTransientRevRule,
    subscribeToStructureReset,
    setRevRuleIntentFormula
};

app.factory('g4bOpenTimeSliceService', () => exports);



/* eslint-disable no-console */
import _ from 'lodash';
import appCtxSvc from 'js/appCtxService';
 
let exports = {};
 
export let g4b_getAllHiddenElementSubset = function (actionType, commandContext) {
  let HiddenElementContext = {};
  //define and update new ctx for all Hidden Element Subset context
  if (appCtxSvc.getCtx("HiddenElementContext") != undefined) {
    appCtxSvc.updateCtx("HiddenElementContext", HiddenElementContext);
  }
  else {
    appCtxSvc.registerCtx("HiddenElementContext", HiddenElementContext);
  }
  let sourceSubsetName = appCtxSvc.getCtx('selected.props.awb0UnderlyingObject.uiValues[0]');
  let sourceSubsetUid = appCtxSvc.getCtx('selected.props.awb0UnderlyingObject.dbValues[0]');
  let ProductContextInfo = appCtxSvc.getCtx('aceActiveContext.context.productContextInfo.uid');
  appCtxSvc.updateCtx('HiddenElementContext.actionType', actionType);
  appCtxSvc.updateCtx('HiddenElementContext.sourceSubsetName', sourceSubsetName);
  appCtxSvc.updateCtx('HiddenElementContext.sourceSubsetUid', sourceSubsetUid);
  appCtxSvc.updateCtx('HiddenElementContext.ProductContextInfo', ProductContextInfo);
  appCtxSvc.updateCtx('HiddenElementContext.RecipeType', "sourceRecipe");
  let firstPart = appCtxSvc.getCtx('HiddenElementContext.sourceSubsetName');
  let finalElementName = firstPart.concat("-AE");
  appCtxSvc.updateCtx('HiddenElementContext.targetSubsetName', finalElementName);
  appCtxSvc.updateCtx('HiddenElementContext.targetSubsetUid', "AAAAAAAAAAAAAA");
  let allSubsetList = commandContext.occContext.vmc.getLoadedViewModelObjects();
  let hiddenElementList = [];
  for (let i = 0; i < allSubsetList.length; i++) {
    if (allSubsetList[i].props["REF(awb0UnderlyingObject,Cpd0DesignSubsetElement).g4b_TaKoSubsetType"].dbValues[0] == "3") {
      let hiddenElementSubset = {
        type: "Cpd0DesignSubsetElement",
        uid: allSubsetList[i].stableId
      }
        hiddenElementList.push(hiddenElementSubset);
    }
  }
  appCtxSvc.updateCtx('HiddenElementContext.hiddenElementSubsets', hiddenElementList);
  appCtxSvc.updateCtx('HiddenElementContext.isHiddenElementsExist', false);
  if (hiddenElementList.length > 0) {
    appCtxSvc.updateCtx('HiddenElementContext.isHiddenElementsAvailable', true);
  }
  else {
    appCtxSvc.updateCtx('HiddenElementContext.isHiddenElementsAvailable', false);
  }
};
 
export let g4b_checkHiddenElementForSelected = function (relationResp,context) {
  let isHiddenElementsExist = false;
  let allFoundObjects = relationResp.output;
  for (let i = 0; i < allFoundObjects.length; i++) {
    let targetSubset = allFoundObjects[i].inputObject.uid;
    let parentSubsetUid = allFoundObjects[i].otherSideObjData[0].otherSideObjects[0].uid;
    if (parentSubsetUid == appCtxSvc.getCtx('HiddenElementContext.sourceSubsetUid')) {
      isHiddenElementsExist = true;
      appCtxSvc.updateCtx('HiddenElementContext.targetSubsetUid', targetSubset);
    }
  }
  if (isHiddenElementsExist == true) {
    let allSubsetList =context.vmc.getLoadedViewModelObjects();
    for (let j = 0; j < allSubsetList.length; j++) {
      if (allSubsetList[j].props.awb0UnderlyingObject.dbValue == appCtxSvc.getCtx('HiddenElementContext.targetSubsetUid')) {
        appCtxSvc.updateCtx('HiddenElementContext.targetSubsetName', allSubsetList[j].props.object_string.dbValue);
        break;
      }
    }
  }
  if (isHiddenElementsExist == false) {
    let firstPart = appCtxSvc.getCtx('HiddenElementContext.sourceSubsetName');
    let finalElementName = firstPart.concat("-AE");
    appCtxSvc.updateCtx('HiddenElementContext.targetSubsetName', finalElementName);
    appCtxSvc.updateCtx('HiddenElementContext.targetSubsetUid', "AAAAAAAAAAAAAA");
    let stylesheetInput = "{\"productContextUid\":" + appCtxSvc.getCtx('aceActiveContext.context.productContextInfo.uid') + "\"}";
    //prepare data for declarative stylesheet inputData
    appCtxSvc.updateCtx('HiddenElementContext.declarativeStylesheetInput', stylesheetInput);
  }
  appCtxSvc.updateCtx('HiddenElementContext.isHiddenElementsExist', isHiddenElementsExist);
 
};
 
/**
 *  function to check is filter available on source subset.
 *  Nitin Tilekar         17/Jan/2023   Initial Draft
 */
export let g4b_checkFilterOnSourceSubset = function () {
  let isFilterAvailableOnSource = false;
  let sourceRec = appCtxSvc.getCtx('HiddenElementContext.sourceRecipe');
  if (sourceRec && sourceRec.length > 0) {
      if (sourceRec[0].criteriaValues[0] == "G4B_GEP_TaKoPpgQuery" && sourceRec.length > 1) {
        isFilterAvailableOnSource = true;
      }
  }
  appCtxSvc.updateCtx('HiddenElementContext.isFilterAvailableOnSource', isFilterAvailableOnSource);
};
 
/**
 *  Set data variables based on recipeType.
 *  This function call internally in output of g4b_getsubsetInfo3 action.
 *  Nitin Tilekar         17/Jan/2023   Initial Draft
 */
export let g4b_setRecipeToDestination = function (recipeResp) {
 
  if (appCtxSvc.getCtx('HiddenElementContext.RecipeType') == "sourceRecipe") {
    let sourceRecipe = recipeResp.filterOut[0].recipe;
    appCtxSvc.updateCtx('HiddenElementContext.sourceRecipe', sourceRecipe);
  } else if (appCtxSvc.getCtx('HiddenElementContext.RecipeType') == "targetRecipe") {
    let targetRecipe = recipeResp.filterOut[0].recipe;
    appCtxSvc.updateCtx('HiddenElementContext.targetRecipe', targetRecipe);
  }
  return recipeResp;
};
 
/**
 *  function to modify filter for hidden elements (Inversion of existing filter type).
 *  Nitin Tilekar         18/Jan/2023   Initial Draft
 */
export let g4b_getDeepCopyDatas = function (deepcopyResp) {
  let deepCopyDataVal = JSON.parse(deepcopyResp.declarativeUIDefs[0].viewModel).data.deepCopyDatas;
  let deepCopyDataArr = deepCopyDataVal.dbValue;
  processDeepCopyData(deepCopyDataArr);
  appCtxSvc.updateCtx('HiddenElementContext.deepCopyDatas', deepCopyDataArr);
  return deepcopyResp;
};
 
let processDeepCopyData = function (deepCopyDataArr) {
  _.forEach(deepCopyDataArr, function (deepCopyData) {
 
    if (deepCopyData.attachedObject) {
      if (deepCopyData.saveAsInput != undefined) {
        deepCopyData.saveAsInput = {};
      }
      deepCopyData.saveAsInput.boName = deepCopyData.attachedObject.type;
    }
 
    if (_.isArray(deepCopyData.childDeepCopyData)) {
      processDeepCopyData(deepCopyData.childDeepCopyData);
    }
  });
};
/**
 *  Set data variables based on newly created subset id and available list of subsets under workset.
 *  This function call internally in output of g4b_getoccurance3 action.
 *  Nitin Tilekar         18/Jan/2023   Initial Draft
 */
export let g4b_getTargetSubsetInfo = function(occuranceResp)
{
  //Creating the Source Recipe for calling getChildren SOA
   let PartitionSourceRecipe = appCtxSvc.getCtx("HiddenElementContext.sourceRecipe");
   let sourceSubsetAndPartition = [];
   let FinalFilterForHECreation = [];
   for(let inx = 0 ; inx < PartitionSourceRecipe.length ; inx++ )
   {
    if(PartitionSourceRecipe[inx].criteriaValues[0] != "G4B_GEP_TaKoPpgQuery" )
        {
           
            if(PartitionSourceRecipe[inx].criteriaType == "Partition")
            {
               /*If the criteriaType is Partition then push the partition obj to getProperties SOA */
                if(PartitionSourceRecipe[inx].criteriaValues.length > 0)
                {
                    let partLen = PartitionSourceRecipe[inx].criteriaValues.length-1;
                    for (let j = 0 ; j < partLen ; j++)
                    {
                        sourceSubsetAndPartition.push({
                   
                            "type": "G4B_PartitionMMGFB",
                            "uid" : PartitionSourceRecipe[inx].criteriaValues[j]
                        });
                    }
                }              
            }
            else if(PartitionSourceRecipe[inx].criteriaType != "Partition")
            {
                 /*If the criteriaType is not Partition then push the  obj to FinalFilterForHECreation It is use for ModifyRecipe */
                if(PartitionSourceRecipe[inx].criteriaOperatorType=="Exclude")
                {
                    PartitionSourceRecipe[inx].criteriaOperatorType = "Include";
                }              
                FinalFilterForHECreation.push(PartitionSourceRecipe[inx])
            }          
        }
   }
   /*push the  Cpd0DesignSubsetElement in the  sourceSubsetAndPartition Context Object for getProperties SOA */
   if(appCtxSvc.getCtx("selected.props.awb0UnderlyingObjectType.dbValues[0]")=="Cpd0DesignSubsetElement")
   {
    sourceSubsetAndPartition.push({
        "type": appCtxSvc.getCtx("selected.props.awb0UnderlyingObjectType.dbValues[0]"),
        "uid" : appCtxSvc.getCtx("selected.props.awb0UnderlyingObject.dbValues[0]") });
   }/* Assign the  */
   appCtxSvc.updateCtx("HiddenElementContext.sourceSubsetAndPartition",sourceSubsetAndPartition);
   appCtxSvc.updateCtx("HiddenElementContext.FinalFilterForHECreation",FinalFilterForHECreation);
   //targetSubsetInfo
    let listOfSubsets = occuranceResp.parentChildrenInfos[0].childrenInfo;
    let listOfelementToPCI = occuranceResp.elementToPCIMap[1];
    if (appCtxSvc.getCtx("HiddenElementContext.targetSubsetUid") != undefined){
        let requiredProductCtx ="";
        for (let i= 0; i < listOfSubsets.length; i++) {
            if ( listOfSubsets[i].stableId == appCtxSvc.getCtx("HiddenElementContext.targetSubsetUid")) {
                appCtxSvc.updateCtx("HiddenElementContext.RecipeType","targetRecipe");
                appCtxSvc.updateCtx("HiddenElementContext.targetOccurrenceId",listOfSubsets[i].occurrenceId);
                requiredProductCtx = "P:".concat(appCtxSvc.getCtx("HiddenElementContext.targetSubsetUid"));
                break;
            }
        }
        for ( let inx= 0; inx < listOfelementToPCI.length; inx++){
            if (listOfelementToPCI[inx].uid.includes(requiredProductCtx)){
                appCtxSvc.updateCtx("HiddenElementContext.ProductContextInfo",listOfelementToPCI[inx].uid);
                break;
            }
        }
    }
    return occuranceResp;
};
 
/**
 * This function called on the g4b_getoccurance3 SOA output data In this
 * function store the all the source PPG in the context object sourcePPGList
 * Also compare the source PPG with the Filter Prtition If it match then push in the FinalFilterForHECreation.
 *  Mangesh Pable         14/Sep/2023   Initial Draft
 */
 
export let g4b_getSourcePPGList = function(responce)
{
    if (responce.modelObjects != undefined)
    {
        let subsetUid = appCtxSvc.getCtx("HiddenElementContext.sourceSubsetUid");  
        // Get the sourcePPG List from source Object and store in the Context Object  
        let ppgList = responce.modelObjects[subsetUid].props.g4b_TaKoPPGs.dbValues 
        appCtxSvc.updateCtx("HiddenElementContext.sourcePPGList", ppgList);
        let sourcepartition = appCtxSvc.getCtx("HiddenElementContext.sourceSubsetAndPartition");
        let FinalFilterForHECreation = appCtxSvc.getCtx("HiddenElementContext.FinalFilterForHECreation");
        let sortedPartitionForGetChildren=[];
        let criteriaDisplayValue ="MMG-FB_$CAT_";
        let partUids=[];
        let count = 0;
        let SourcPPGsList =appCtxSvc.getCtx("HiddenElementContext.sourcePPGList");
        for(let inx = 0 ; inx < sourcepartition.length ; inx++ )
        {
            if(sourcepartition[inx].type == "G4B_PartitionMMGFB")
            {
                let sourcepartitionUid = sourcepartition[inx].uid;          
                if(responce.modelObjects[sourcepartitionUid].props.g4b_PPG.dbValues[0] == null)
                {
                    sortedPartitionForGetChildren.push(sourcepartition[inx]);
                                       
                }
                else if(responce.modelObjects[sourcepartitionUid].props.g4b_PPG.dbValues[0] != null)
                {                                
                    let isPPGFound= SourcPPGsList.includes(responce.modelObjects[sourcepartitionUid].props.g4b_PPG.dbValues[0]);
                    if(isPPGFound == true)
                    {
                        if(count > 0)
                       {
                        criteriaDisplayValue = criteriaDisplayValue +"^";
                       }
                     criteriaDisplayValue = criteriaDisplayValue + responce.modelObjects[sourcepartitionUid].props.object_name.dbValues[0];
                     partUids.push(sourcepartition[inx].uid);
                     count = count + 1;  
                    }
                                   
                }
            }  
        }
        //push the object to the FinalFilterForHECreation Array            
        if(partUids.length > 0 && count > 0)
        {
            partUids.push("1");
            FinalFilterForHECreation.push(
                {
                            "criteriaType": "Partition",
                            "criteriaDisplayValue": criteriaDisplayValue,
                            "criteriaOperatorType": "Include",
                            "criteriaValues":partUids,
                            "subCriteria": []                                
                }
                );  
        }            
        appCtxSvc.updateCtx("HiddenElementContext.sortedPartitionForGetChildren",sortedPartitionForGetChildren);  
    }
    return responce;
};
 
/**
 *  function function is store all the Children of the partition in letiable
 *  which is required for the getProperties SOA on the Partition Object.
 *  Mangesh Pable         14/Sep/2023   Initial Draft
 */
export let g4b_getPartitionObjects = function(responce)
{
    let partionSendToGetProps=[];
    if (responce.output != undefined)
    {
       let childrenPartitionList=responce.output;
        for(let index = 0 ; index < childrenPartitionList.length ; index++)
        {
            let parentChildMap=childrenPartitionList[index].parentChildMap[0];
            for( let inx = 0 ; inx < parentChildMap.length ; inx++)
            {                            
                partionSendToGetProps.push({
                    "type": parentChildMap[inx].type,
                    "uid" : parentChildMap[inx].uid});
                           
            }
        }    
         appCtxSvc.updateCtx("HiddenElementContext.partionSendToGetProperties",partionSendToGetProps);  
    }
    return responce;
};
 
/**
 *  function to sourcePPG is match with the PartitionPPG If match then
 *  push the partition in the FinalFilterForHECreation Context Object.
 *  Mangesh Pable         14/Sep/2023   Initial Draft
 */
export let g4b_getSortPartitionList = function(responce)
{
    if (responce.modelObjects != undefined)
    {
        let FinalFilterForHECreation = appCtxSvc.getCtx("HiddenElementContext.FinalFilterForHECreation");
        let partitionOfGetChildren= appCtxSvc.getCtx("HiddenElementContext.partionSendToGetProperties");
        let partiUids=[];
        let criteriaDisplayValue = "MMG-FB_$CAT_";
        let count = 0;
 
        for(let index = 0 ; index < partitionOfGetChildren.length ;index++)
        {
           let partitionUid= partitionOfGetChildren[index].uid;
 
            if(responce.modelObjects[partitionUid].props.g4b_PPG.dbValues[0] != null)
            {                                  
                   let SourcPPGsList =appCtxSvc.getCtx("HiddenElementContext.sourcePPGList");
                   let isPPGFound= SourcPPGsList.includes(responce.modelObjects[partitionUid].props.g4b_PPG.dbValues[0]);
                   if(isPPGFound == true)
                   {
                    if(count > 0)
                    {
                        criteriaDisplayValue = criteriaDisplayValue +"^";
                    }
                    criteriaDisplayValue = criteriaDisplayValue + responce.modelObjects[partitionUid].props.object_name.dbValues[0];
                    partiUids.push(partitionOfGetChildren[index].uid);
                    count = count + 1;
                   
                   }  
                                               
            }            
        }
        if(partiUids.length > 0 && count > 0 )
        {
            partiUids.push("1");
            FinalFilterForHECreation.push(
                {
                            "criteriaType": "Partition",
                            "criteriaDisplayValue": criteriaDisplayValue,
                            "criteriaOperatorType": "Include",
                            "criteriaValues": partiUids,
                            "subCriteria": []
                   
                }
                );  
 
        }
       
    }
    return responce;
};
 
/**
 *  function to create the ModifyRecipe for the AE-creation.
 *  Mangesh Pable         14/Sep/2023   Initial Draft
 */
export let g4b_modifyHiddenElement = function(data)
{
    let updatedRecipe = [];
    let FinalRecipe = appCtxSvc.getCtx("HiddenElementContext.FinalFilterForHECreation");
    let targetRecipe = appCtxSvc.getCtx("HiddenElementContext.targetRecipe[0]");
    updatedRecipe.push(targetRecipe);
    for(let index = 0 ; index < FinalRecipe.length ; index++)
    {
        updatedRecipe.push(FinalRecipe[index]);
    }
    appCtxSvc.updateCtx("HiddenElementContext.modifiedRecipe",updatedRecipe);
    return data;
};
 exports = {
    g4b_getAllHiddenElementSubset,
    g4b_checkHiddenElementForSelected,
    g4b_checkFilterOnSourceSubset,
    g4b_setRecipeToDestination,
    g4b_getDeepCopyDatas,
    g4b_getTargetSubsetInfo,
    g4b_modifyHiddenElement,
    g4b_getSourcePPGList,
    g4b_getPartitionObjects,
    g4b_getSortPartitionList
};
export default exports;
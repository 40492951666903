// @<COPYRIGHT>@
// ==================================================
// Copyright 2020.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

import $ from "jquery";
import appCtxService from "js/appCtxService";
import cdm from "soa/kernel/clientDataModel";
import soaSvc from "soa/kernel/soaService";
import AwPromiseService from "js/awPromiseService";
import messagingService from "js/messagingService";
import eventBus from "js/eventBus";
import _ from "lodash";

let exports = {};

export let getPvOrVsaRelation = function () {
  let relationType = "";
  if (appCtxService.getCtx("selected.type") == "G4B_TaKoAddedPV") {
    relationType = "G4B_TaKoSubsetAddedPVRel";
  }
  if (appCtxService.getCtx("selected.type") == "G4B_TaKoAddedVSA") {
    relationType = "G4B_TaKoSubsetAddedVSARel";
  }
  if (appCtxService.getCtx("selected.type") == "G4B_TaKoAddedHPV") {
    relationType = "G4B_TaKoSubsetAddedHPVRel";
  }
  if (appCtxService.getCtx("selected.type") == "G4B_TaKoAddedQPV") {
    relationType = "G4B_TaKoSubsetAddedQPVRel";
  }
  return relationType;
};

let getExistingRelatedHangingPVs = function (commandContext) {
  let deferred = AwPromiseService.instance.defer();
  let currentSubset =
    commandContext.occContext.rootElement.props.awb0UnderlyingObject
      .dbValues[0];
  let subsetObj = cdm.getObject(currentSubset);

  let inputData = {
    primaryObjects: [subsetObj],
    pref: {
      info: [
        {
          relationTypeName: "G4B_TaKoSubsetAddedHPVRel",
          otherSideObjectTypes: ["G4B_TaKoAddedHPV"],
        },
      ],
    },
  };

  soaSvc
    .post(
      "Core-2007-09-DataManagement",
      "expandGRMRelationsForPrimary",
      inputData
    )
    .then(function (response) {
      let relatedObjectsGUIDarr = [];
      if (response.output.length > 0) {
        for (
          let jnx = 0;
          jnx < response.output[0].relationshipData.length;
          jnx++
        ) {
          for (
            let inx = 0;
            inx <
            response.output[0].relationshipData[jnx].relationshipObjects.length;
            inx++
          ) {
            let relObj = cdm.getObject(
              response.output[0].relationshipData[jnx].relationshipObjects[inx]
                .otherSideObject.uid
            );

            ////TODO: change g4b_Name to g4b_PVGUID to compare GUIDs
            relatedObjectsGUIDarr.push(relObj.props.g4b_PVGUID.dbValues[0]);
          }
        }
      }
      deferred.resolve(relatedObjectsGUIDarr);
    });
  return deferred.promise;
};

export let G4B_GetHangingPartUsageProps = function (commandContext, i18n) {
  let deferred = AwPromiseService.instance.defer();
  let mselected = appCtxService.getCtx("mselected");
  let currentSubset =
    commandContext.occContext.rootElement.props.awb0UnderlyingObject
      .dbValues[0];
  let subsetObj = cdm.getObject(currentSubset);

  let getExistingHPVPromise = getExistingRelatedHangingPVs(commandContext);
  $.when(getExistingHPVPromise).done(function (relatedObjectsGUIDarr) {
    let inputData = {
      inputs: [],
    };

    let inputPV = {
      clientId: "CreateObject",
      createData: {
        boName: "G4B_TaKoAddedHPV",
        propertyNameValues: {},
        compoundCreateInput: {},
      },
      dataToBeRelated: {},
      workflowData: {},
      pasteProp: "G4B_TaKoSubsetAddedHPVRel",
      targetObject: {
        uid: subsetObj.uid,
        type: subsetObj.type,
      },
    };

    for (let inx = 0; inx < mselected.length; inx++) {
      //If a Katalog PU already exists then DO NOT create a new object
      let underlyingPUObj = cdm.getObject(
        mselected[inx].props.awb0UnderlyingObject.dbValues[0]
      );
      if (
        relatedObjectsGUIDarr.indexOf(
          underlyingPUObj.props.g4b_PVGUID.dbValues[0]
        ) > -1
      ) {
        continue;
      }

      let inputPVLocal = JSON.parse(JSON.stringify(inputPV));
      inputData.inputs.push(inputPVLocal);

      //create input params for the G4B_TaKoAddedHPV object
      let inputPVPropertyNameValues = {
        g4b_Name: [
          underlyingPUObj.props.object_name.dbValues[0] != null
            ? underlyingPUObj.props.object_name.dbValues[0]
            : "",
        ],
        g4b_PVGUID: [
          underlyingPUObj.props.g4b_PVGUID.dbValues[0] != null
            ? underlyingPUObj.props.g4b_PVGUID.dbValues[0]
            : "",
        ],
        g4b_PV: [
          underlyingPUObj.props.g4b_PNAME.dbValues[0] != null
            ? underlyingPUObj.props.g4b_PNAME.dbValues[0]
            : "",
        ],
        g4b_Department: [
          underlyingPUObj.props.g4b_KO_BEREICH.dbValues[0] != null
            ? underlyingPUObj.props.g4b_KO_BEREICH.dbValues[0]
            : "",
        ],
        g4b_SubmodulOrg: [
          underlyingPUObj.props.g4b_MODUL4.dbValues[0] != null
            ? underlyingPUObj.props.g4b_MODUL4.dbValues[0]
            : "",
        ],
        g4b_KoGrPPG: [
          underlyingPUObj.props.g4b_PPG.dbValues[0] != null
            ? underlyingPUObj.props.g4b_PPG.dbValues[0]
            : "",
        ],
        g4b_KoGr: [
          underlyingPUObj.props.g4b_KOGR.dbValues[0] != null
            ? underlyingPUObj.props.g4b_KOGR.dbValues[0]
            : "",
        ],
        g4b_Quantity: [
          underlyingPUObj.props.g4b_QUANT.dbValues[0] != null
            ? underlyingPUObj.props.g4b_QUANT.dbValues[0]
            : "",
        ],
        g4b_UnitOfMeasure: [
          underlyingPUObj.props.g4b_QUNIT.dbValues[0] != null
            ? underlyingPUObj.props.g4b_QUNIT.dbValues[0]
            : "",
        ],
      };
      inputData.inputs[
        inputData.inputs.length - 1
      ].createData.propertyNameValues = inputPVPropertyNameValues;
    }

    soaSvc
      .post(
        "Internal-Core-2012-10-DataManagement",
        "createRelateAndSubmitObjects",
        inputData
      )
      .then(function () {
        deferred.resolve("done");
        eventBus.publish("cdm.relatedModified", {
          relatedModified: [subsetObj],
          refreshLocationFlag: true,
        });
        messagingService.showInfo(i18n.G4B_addHangingPVSuccessMsg);
      });
  });

  return deferred.promise;
};

export let getSelectedKatalogPVpropsAndCreate = function (data) {
  let deferred = AwPromiseService.instance.defer();

  let currentSubset = appCtxService.getCtx(
    "occmgmtContext.rootElement.props.awb0UnderlyingObject.dbValues[0]"
  );
  let subsetObj = cdm.getObject(currentSubset);
  let selectedKatalogPVs =
    data.dataProviders.TaKoKatalogPVDataProvider.selectedObjects;

  let getExistingKatalogPVsromise = getExistingKatalogPVs();
  $.when(getExistingKatalogPVsromise).done(function (relatedObjectsArr) {
    let inputData = {
      inputs: [],
    };

    let inputPV = {
      clientId: "CreateObject",
      createData: {
        boName: "G4B_TaKoAddedPV",
        propertyNameValues: {},
        compoundCreateInput: {},
      },
      dataToBeRelated: {},
      workflowData: {},
      pasteProp: "G4B_TaKoSubsetAddedKPVRel",
      targetObject: {
        uid: subsetObj.uid,
        type: subsetObj.type,
      },
    };

    for (let inx = 0; inx < selectedKatalogPVs.length; inx++) {
      //If a Hanging PU already exists then DO NOT create a new G4B_TaKoAddedHPV object
      ////TODO : change object_name prop to g4b_PVGUID to compare GUIDs
      let materialNr = "";
      let materialTxt = "";
      let underlyingPUObj = cdm.getObject(selectedKatalogPVs[inx].uid);
      if (
        relatedObjectsArr.indexOf(underlyingPUObj.props.g4b_Name.dbValues[0]) >
        -1
      ) {
        continue;
      }
      if (
        underlyingPUObj.props.g4b_MaterialNumber.dbValues[0] == null ||
        underlyingPUObj.props.g4b_MaterialNumber.dbValues[0] == ""
      ) {
        materialNr = "x";
      }
      if (
        underlyingPUObj.props.g4b_MaterialText.dbValues[0] == null ||
        underlyingPUObj.props.g4b_MaterialText.dbValues[0] == ""
      ) {
        materialTxt = underlyingPUObj.props.g4b_Name.dbValues[0];
      }

      if (underlyingPUObj.props.g4b_MaterialNumber.dbValues[0] != null) {
        materialNr = underlyingPUObj.props.g4b_MaterialNumber.dbValues[0];
      }
      if (underlyingPUObj.props.g4b_MaterialText.dbValues[0] != null) {
        materialTxt = underlyingPUObj.props.g4b_MaterialText.dbValues[0];
      }
      let inputPVLocal = JSON.parse(JSON.stringify(inputPV));
      inputData.inputs.push(inputPVLocal);

      //create input params for the G4B_TaKoAddedPV object
      let inputPVPropertyNameValues = {
        g4b_Name: [
          underlyingPUObj.props.g4b_Name.dbValues[0] != null
            ? underlyingPUObj.props.g4b_Name.dbValues[0]
            : "",
        ],
        g4b_PV: [
          underlyingPUObj.props.g4b_PV.dbValues[0] != null
            ? underlyingPUObj.props.g4b_PV.dbValues[0]
            : "",
        ],
        g4b_Department: [
          underlyingPUObj.props.g4b_Department.dbValues[0] != null
            ? underlyingPUObj.props.g4b_Department.dbValues[0]
            : "",
        ],
        g4b_SubmodulOrg: [
          underlyingPUObj.props.g4b_SubmodulOrg.dbValues[0] != null
            ? underlyingPUObj.props.g4b_SubmodulOrg.dbValues[0]
            : "",
        ],
        g4b_KoGrPPG: [
          underlyingPUObj.props.g4b_KoGrPPG.dbValues[0] != null
            ? underlyingPUObj.props.g4b_KoGrPPG.dbValues[0]
            : "",
        ],
        g4b_KoGr: [
          underlyingPUObj.props.g4b_KoGr.dbValues[0] != null
            ? underlyingPUObj.props.g4b_KoGr.dbValues[0]
            : "",
        ],
        g4b_Quantity: [
          underlyingPUObj.props.g4b_Quantity.dbValues[0] != null
            ? underlyingPUObj.props.g4b_Quantity.dbValues[0]
            : "",
        ],
        g4b_UnitOfMeasure: [
          underlyingPUObj.props.g4b_UnitOfMeasure.dbValues[0] != null
            ? underlyingPUObj.props.g4b_UnitOfMeasure.dbValues[0]
            : "",
        ],
        g4b_MaterialNumber: [materialNr],
        g4b_MaterialText: [materialTxt],
      };
      inputData.inputs[
        inputData.inputs.length - 1
      ].createData.propertyNameValues = inputPVPropertyNameValues;
    }

    soaSvc
      .post(
        "Internal-Core-2012-10-DataManagement",
        "createRelateAndSubmitObjects",
        inputData
      )
      .then(function () {
        deferred.resolve();

        eventBus.publish("g4bCreateCatalogPV.close");
        eventBus.publish("cdm.relatedModified", {
          relatedModified: [subsetObj],
          refreshLocationFlag: true,
        });
      });
  });
  return deferred.promise;
};

export let fetchKatalogPVs = function (data) {
  let deferred = AwPromiseService.instance.defer();

  let inputData = {
    searchInput: {
      attributesToInflate: [
        "g4b_Name",
        "g4b_Archived",
        "g4b_PV",
        "g4b_Department",
        "g4b_SubmodulOrg",
        "g4b_KoGrPPG",
        "g4b_KoGr",
        "g4b_Quantity",
        "g4b_UnitOfMeasure",
        "g4b_MaterialNumber",
        "g4b_MaterialText",
      ],
      maxToLoad: 5000,
      maxToReturn: 5000,
      providerName: "Awp0SavedQuerySearchProvider",
      searchCriteria: {
        queryName: "General...",
        Type: "TaKo Catalog PV",
        Name: "*",
        typeOfSearch: "ADVANCED_SEARCH",
        utcOffset: "",
        searchID: "",
        lastEndIndex: "",
        totalObjectsFoundReportedToClient: "",
      },
      searchFilterFieldSortType: "Alphabetical",
    },
  };

  soaSvc
    .post("Internal-AWS2-2016-03-Finder", "performSearch", inputData)
    .then(function (response) {
      let filterString = data.propertyFilterText.dbValue;
      let foundObjectsArr = response.searchResults;
      let filteredObjectsArr = [];
      for (let inx = 0; inx < foundObjectsArr.length; inx++) {
        let cellProperties = "";
        for (
          let jnx = 0;
          jnx < foundObjectsArr[inx].props.awp0CellProperties.dbValues.length;
          jnx++
        ) {
          cellProperties +=
            foundObjectsArr[inx].props.awp0CellProperties.dbValues[jnx] + " ";
        }
        foundObjectsArr[inx].props.toolTip = _.cloneDeep(
          foundObjectsArr[inx].props.g4b_Name
        );
        let toolTipText =
          foundObjectsArr[inx].props.g4b_Name.propertyDescriptor.displayName +
          ": " +
          foundObjectsArr[inx].props.g4b_Name.uiValues[0] +
          "\n" +
          foundObjectsArr[inx].props.g4b_Archived.propertyDescriptor
            .displayName +
          ": " +
          foundObjectsArr[inx].props.g4b_Archived.uiValues[0] +
          "\n" +
          foundObjectsArr[inx].props.g4b_PV.propertyDescriptor.displayName +
          ": " +
          foundObjectsArr[inx].props.g4b_PV.uiValues[0] +
          "\n" +
          foundObjectsArr[inx].props.g4b_Department.propertyDescriptor
            .displayName +
          ": " +
          foundObjectsArr[inx].props.g4b_Department.uiValues[0] +
          "\n" +
          foundObjectsArr[inx].props.g4b_SubmodulOrg.propertyDescriptor
            .displayName +
          ": " +
          foundObjectsArr[inx].props.g4b_SubmodulOrg.uiValues[0] +
          "\n" +
          foundObjectsArr[inx].props.g4b_KoGrPPG.propertyDescriptor
            .displayName +
          ": " +
          foundObjectsArr[inx].props.g4b_KoGrPPG.uiValues[0] +
          "\n" +
          foundObjectsArr[inx].props.g4b_KoGr.propertyDescriptor.displayName +
          ": " +
          foundObjectsArr[inx].props.g4b_KoGr.uiValues[0] +
          "\n" +
          foundObjectsArr[inx].props.g4b_Quantity.propertyDescriptor
            .displayName +
          ": " +
          foundObjectsArr[inx].props.g4b_Quantity.uiValues[0] +
          "\n" +
          foundObjectsArr[inx].props.g4b_UnitOfMeasure.propertyDescriptor
            .displayName +
          ": " +
          foundObjectsArr[inx].props.g4b_UnitOfMeasure.uiValues[0] +
          "\n" +
          foundObjectsArr[inx].props.g4b_MaterialNumber.propertyDescriptor
            .displayName +
          ": " +
          foundObjectsArr[inx].props.g4b_MaterialNumber.uiValues[0] +
          "\n" +
          foundObjectsArr[inx].props.g4b_MaterialText.propertyDescriptor
            .displayName +
          ": " +
          foundObjectsArr[inx].props.g4b_MaterialText.uiValues[0];

        foundObjectsArr[inx].props.toolTip.dbValues[0] = toolTipText;
        foundObjectsArr[inx].props.toolTip.uiValues[0] = toolTipText;

        if (
          cellProperties.toLowerCase().indexOf(filterString.toLowerCase()) > -1
        ) {
          filteredObjectsArr.push(foundObjectsArr[inx]);
        }
      }

      if (data.propertyFilterArchivedKPV.dbValue == true) {
        response.searchResults = filteredObjectsArr;
        response.totalLoaded = filteredObjectsArr.length;
        response.totalFound = filteredObjectsArr.length;
        return deferred.resolve(response);
      } else {
        let allKatalogObjsArr = filteredObjectsArr;
        let finalKatalogObjectsArr = [];
        for (let inx = 0; inx < allKatalogObjsArr.length; inx++) {
          if (allKatalogObjsArr[inx].props.g4b_Archived.dbValues[0] == "0") {
            // getting all archived==false objects only
            finalKatalogObjectsArr.push(allKatalogObjsArr[inx]);
          }
        }
        response.searchResults = finalKatalogObjectsArr;
        response.totalLoaded = finalKatalogObjectsArr.length;
        response.totalFound = finalKatalogObjectsArr.length;

        return deferred.resolve(response);
      }
    });
  return deferred.promise;
};

let getExistingKatalogPVs = function () {
  let deferred = AwPromiseService.instance.defer();
  let currentSubset = appCtxService.getCtx(
    "occmgmtContext.rootElement.props.awb0UnderlyingObject.dbValues[0]"
  );
  let subsetObj = cdm.getObject(currentSubset);

  let inputData = {
    primaryObjects: [subsetObj],
    pref: {
      info: [
        {
          relationTypeName: "G4B_TaKoSubsetAddedKPVRel",
          otherSideObjectTypes: ["G4B_TaKoAddedPV"],
        },
      ],
    },
  };

  soaSvc
    .post(
      "Core-2007-09-DataManagement",
      "expandGRMRelationsForPrimary",
      inputData
    )
    .then(function (response) {
      let relatedObjectsGUIDarr = [];
      if (response.output.length > 0) {
        for (
          let jnx = 0;
          jnx < response.output[0].relationshipData.length;
          jnx++
        ) {
          for (
            let inx = 0;
            inx <
            response.output[0].relationshipData[jnx].relationshipObjects.length;
            inx++
          ) {
            let relObj = cdm.getObject(
              response.output[0].relationshipData[jnx].relationshipObjects[inx]
                .otherSideObject.uid
            );
            relatedObjectsGUIDarr.push(relObj.props.g4b_Name.dbValues[0]);
          }
        }
      }
      deferred.resolve(relatedObjectsGUIDarr);
    });
  return deferred.promise;
};

exports = {
  getPvOrVsaRelation,
  G4B_GetHangingPartUsageProps,
  getSelectedKatalogPVpropsAndCreate,
  fetchKatalogPVs,
};

export default exports;

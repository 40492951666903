/**
 *
 * @author Ganesh Pachbhai
 * File description : This file contains "SyncToInterfaceActiveService"
 *
 *
 */
import appCtxSvc from "js/appCtxService";
import soaSvc from "soa/kernel/soaService";
import cdmSvc from "soa/kernel/clientDataModel";
import AwPromiseService from "js/awPromiseService";
import _ from "lodash";
 
let exports = {};
 
export let updateDarpSyncTracker = function () {
  let deferred = AwPromiseService.instance.defer();
  let DARP_Sync_ManuallyTrigerred = appCtxSvc.getCtx(
    "DARP_Sync_ManuallyTrigerred"
  );
 
  let inputData = {
    "input": {
      "className": "G4B_TAKO_DARP_Sync_Tracker",
      "clientId": "",
      "outputAttributeNames": [],
      "attrAndValues": [
        {
          attrName: "object_name",
          values: ["*"]
        }
 
      ]
    }
  }
  let promise = soaSvc.post("Internal-Query-2008-06-Finder", "findObjectsByClassAndAttributes", inputData)
 
  if (promise) {
    promise.then(function (response) {
      if (response.ServiceData.plain.length > 0) {
        let syncTrackerObj = cdmSvc.getObject(response.ServiceData.plain[0]);
        let isManuallyTrigerred = syncTrackerObj.props.g4b_IsManuallyTrigerred.dbValues[0];
 
        if (DARP_Sync_ManuallyTrigerred == undefined || DARP_Sync_ManuallyTrigerred == null) {
          appCtxSvc.registerCtx("DARP_Sync_ManuallyTrigerred", "Yes");
        }
        else {
          appCtxSvc.updateCtx("DARP_Sync_ManuallyTrigerred", "Yes");
        }
 
        let inputData1 = {
          'objects': [syncTrackerObj],
          'attributes': {
            'g4b_IsManuallyTrigerred': {
              'stringVec': ['Yes']
            }
          }
        };
        soaSvc.post("Core-2007-01-DataManagement", "setProperties", inputData1).then(
          function (resp) {
            deferred.resolve(resp);
          });
 
      }
      deferred.resolve();
    });
 
  }
  return deferred.promise;
};
 
export let updateLesSyncTracker = function()
{
   let deferred = AwPromiseService.instance.defer();
   let LES_Sync_ManuallyTrigerred = appCtxSvc.getCtx("LES_Sync_ManuallyTrigerred");
 
   let inputData = {
       "input":{
               "className":"G4B_TAKO_LES_Sync_Tracker",
               "clientId":"",
               "outputAttributeNames":[],
               "attrAndValues":[
                   {
                       attrName:"object_name",
                       values:["*"]
                   }
 
               ]
           }
       }
       let promise = soaSvc.post("Internal-Query-2008-06-Finder", "findObjectsByClassAndAttributes", inputData)
       
       if( promise ) {
           promise.then( function( response ) {
               if (response.ServiceData.plain.length > 0)
               {
                   let syncTrackerObj = cdmSvc.getObject(response.ServiceData.plain[0]);
                   let isManuallyTrigerred = syncTrackerObj.props.g4b_IsManuallyTrigerred.dbValues[0];
 
                   if( LES_Sync_ManuallyTrigerred == undefined || LES_Sync_ManuallyTrigerred == null)
                   {
                       appCtxSvc.registerCtx("LES_Sync_ManuallyTrigerred", "Yes");
                   }
                   else
                   {
                       appCtxSvc.updateCtx("LES_Sync_ManuallyTrigerred", "Yes");
                   }
 
                   let inputData1={
                       'objects':[syncTrackerObj],
                       'attributes':{
                           'g4b_IsManuallyTrigerred':{
                               'stringVec': ['Yes']                          
                           }
                       }              
                   };
                   soaSvc.post("Core-2007-01-DataManagement", "setProperties", inputData1).then(
                       function (resp) {
                           deferred.resolve(resp);
                       });
 
               }
               deferred.resolve();
           });
 
       }
       return deferred.promise;
};
 
export let checkDarpSyncTrackerStatus = function (i18n) {
  let deferred = AwPromiseService.instance.defer();
  let flagvar = "InProgress"
  appCtxSvc.updateCtx("syncToDarpStatus", flagvar);
  let inputData = {
    "input": {
      "className": "G4B_TAKO_DARP_Sync_Tracker",
      "clientId": "",
      "outputAttributeNames": [],
      "attrAndValues": [
        {
          attrName: "object_name",
          values: ["*"]
        }
 
      ]
    }
  }
  soaSvc.post("Internal-Query-2008-06-Finder", "findObjectsByClassAndAttributes", inputData).then(function (response) {
    if (response.ServiceData.plain.length > 0) {
      let syncTrackerObj = cdmSvc.getObject(response.ServiceData.plain[0]);
      let isManuallyTrigerred = syncTrackerObj.props.g4b_IsManuallyTrigerred.dbValues[0];
      let T4xStatus = syncTrackerObj.props.g4b_DarpSyncT4XStatus.dbValues[0];
      let syncedOn = syncTrackerObj.props.g4b_DarpDataSyncedOn.dbValues[0];
 
      if (isManuallyTrigerred == 'No' && T4xStatus == 'Done') {
        appCtxSvc.updateCtx("DARP_Sync_ManuallyTrigerred", isManuallyTrigerred);
        appCtxSvc.updateCtx("DARP_Sync_T4XStatus", T4xStatus);
        appCtxSvc.updateCtx("DARP_Sync_LastDataCollectedOn", syncedOn);
        flagvar = "Completed";
        appCtxSvc.updateCtx("syncToDarpStatus", flagvar);
 
      }
      else {
        appCtxSvc.updateCtx("DARP_Sync_ManuallyTrigerred", isManuallyTrigerred);
        appCtxSvc.updateCtx("DARP_Sync_T4XStatus", T4xStatus);
        appCtxSvc.updateCtx("DARP_Sync_LastDataCollectedOn", syncedOn);
      }
 
    }
    deferred.resolve(flagvar);
  });
 
  return deferred.promise;
};
 
export let checkLesSyncTrackerStatus = function (i18n) {
  let deferred = AwPromiseService.instance.defer();
  let flagvar = "InProgress"
  appCtxSvc.updateCtx("syncToLesStatus", flagvar);
  let inputData = {
    "input": {
      "className": "G4B_TAKO_LES_Sync_Tracker",
      "clientId": "",
      "outputAttributeNames": [],
      "attrAndValues": [
        {
          attrName: "object_name",
          values: ["*"]
        }
 
      ]
    }
  }
  soaSvc.post("Internal-Query-2008-06-Finder", "findObjectsByClassAndAttributes", inputData).then(function (response) {
    if (response.ServiceData.plain.length > 0) {
      let syncTrackerObj = cdmSvc.getObject(response.ServiceData.plain[0]);
      let isManuallyTrigerred = syncTrackerObj.props.g4b_IsManuallyTrigerred.dbValues[0];
      let T4xStatus = syncTrackerObj.props.g4b_LesSyncT4XStatus.dbValues[0];
      let syncedOn = syncTrackerObj.props.g4b_LesDataSyncedOn.dbValues[0];
 
      if (isManuallyTrigerred == 'No' && T4xStatus == 'Done') {
        appCtxSvc.updateCtx("LES_Sync_ManuallyTrigerred", isManuallyTrigerred);
        appCtxSvc.updateCtx("LES_Sync_T4XStatus", T4xStatus);
        appCtxSvc.updateCtx("LES_Sync_LastDataCollectedOn", syncedOn);
        flagvar = "Completed";
        appCtxSvc.updateCtx("syncToLesStatus", flagvar);
 
      }
      else {
        appCtxSvc.updateCtx("LES_Sync_ManuallyTrigerred", isManuallyTrigerred);
        appCtxSvc.updateCtx("LES_Sync_T4XStatus", T4xStatus);
        appCtxSvc.updateCtx("LES_Sync_LastDataCollectedOn", syncedOn);
      }
 
    }
    deferred.resolve(flagvar);
  });
 
  return deferred.promise;
};
 
 
exports = {
  updateDarpSyncTracker,
  checkDarpSyncTrackerStatus,
  updateLesSyncTracker,
  checkLesSyncTrackerStatus
};
 
export default exports;
 
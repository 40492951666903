// @<COPYRIGHT>@
// ==================================================
// Copyright 2018.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

import soaSvc from "soa/kernel/soaService";
import $ from "jquery";
import selectionSvc from 'js/selection.service';
import appCtxSvc from 'js/appCtxService';
import cdm from "soa/kernel/clientDataModel";
import eventBus from "js/eventBus";
import AwPromiseService from "js/awPromiseService";
import hostInteropSvc from 'js/hosting/hostInteropService';
import createLaunchFileRequestSvc from 'js/createLaunchFileRequest';
import hostOpenService from 'js/hosting/hostOpenService';
import preferenceSvc from 'soa/preferenceService';
import browserUtils from 'js/browserUtils';
import logger from 'js/logger';
import messagingSvc from 'js/messagingService';
import fmsUtils from 'js/fmsUtils';
import hostConfigValues from 'js/hosting/hostConst_ConfigValues';
import hostServices from 'js/hosting/hostConst_Services';
import _ from 'lodash';
import sessionCtxSvc from 'js/sessionContext.service';
import preferenceService from 'soa/preferenceService';

let exports = {};
let _soaPath;
let WEB_XML_VIS_PROXY_CONTEXT = 'VisProxyServlet' + '/';
let WEB_XML_SOA_PROXY_CONTEXT = 'tc';
let CLIENT_SOA_PATH = WEB_XML_SOA_PROXY_CONTEXT + '/';
let session_discriminator_notset ="session_discriminator_notset";
/**
 * {Boolean} TRUE if various processing steps should be logged.
 */
 var _debug_logLaunchActivity = browserUtils.getWindowLocationAttributes().logLaunchActivity !== undefined;

export let processAllSubsetsFromCurrentWorkset = function (data) {
  let deferred = AwPromiseService.instance.defer();
  let promise1 = getAllSubsetsFromWorkset(data);
  $.when(promise1).done(function (allSubsets) {
    if (allSubsets != null) {
      let promise3 = getAllProjectsFromSubsets(allSubsets);
      $.when(promise3).done(function (allProjects) {
        let currentSelectedSubsetProject;
        let selected = appCtxSvc.getCtx("selected");
        if (
          selected &&
          selected.props.awb0UnderlyingObjectType.dbValues ==
            "Cpd0DesignSubsetElement"
        ) {
          let selectedSubset = {
            uid: selected.props.awb0UnderlyingObject.dbValues[0],
            type: selected.props.awb0UnderlyingObjectType.dbValues[0],
          };
          let promise4 = getProjectsFromSubset(selectedSubset);
          $.when(promise4).done(function (currentSelectedSubsetProject) {
            if (currentSelectedSubsetProject.length == 0) {
              deferred.resolve(null);
            }
            let projectOnWorksetCount = 0;
            allProjects.forEach((project) => {
              if (
                project.type == currentSelectedSubsetProject[0].type &&
                project.uid == currentSelectedSubsetProject[0].uid
              ) {
                projectOnWorksetCount = projectOnWorksetCount + 1;
              }
            });
            if (projectOnWorksetCount == 1) {
              deferred.resolve(currentSelectedSubsetProject);
            }
            if (projectOnWorksetCount > 1) {
              deferred.resolve(allProjects);
            }
          });
        }
      });
    } else {
      deferred.resolve(null);
    }
  });
  return deferred.promise;
};

export let getAllSubsetsFromWorkset = function (data) {
  let deferred = AwPromiseService.instance.defer();
  if (
    data != undefined &&
    data.props.awb0UnderlyingObjectType.dbValues[0] ==
      "G4B_AnlCtxWrkSetRevision"
  ) {
    let inputData = {
      inputData: {
        requestPref: {
          includePath: ["true"],
          loadTreeHierarchyThreshold: ["50"],
          displayMode: ["Tree"],
          savedSessionMode: ["restore"],
          calculateFilters: ["false"],
          viewType: [""],
          startFreshNavigation: ["true"],
        },
        product: {
          uid: data.props.awb0UnderlyingObject.dbValues[0],
          type: data.props.awb0UnderlyingObjectType.dbValues[0],
        },
      },
    };
    let allSubsets = {};
    soaSvc
    .post(
      "Internal-ActiveWorkspaceBom-2022-06-OccurrenceManagement",
      "getOccurrences4",
      inputData
    )
      .then(function (response) {
        deferred.resolve(
          (allSubsets = response.parentChildrenInfos[0].childrenInfo)
        );
      });
  } else {
    deferred.resolve(null);
  }
  return deferred.promise;
};

export let getAllProjectsFromSubsets = function (allSubsets) {
  let deferred2 = AwPromiseService.instance.defer();
  let allProject = [];
  let projects;
  allSubsets.forEach((obj) => {
    projects = {};
    let subset = {
      uid: obj.stableId,
      type: obj.underlyingObjectType,
    };
    let promise2 = getProjectsFromSubset(subset);
    $.when(promise2).done(function (projects) {
      projects.forEach((project) => {
        if (project != null) {
          allProject.push(project);
        }
      });
      deferred2.resolve(allProject);
    });
  });
  return deferred2.promise;
};

export let getProjectsFromSubset = function (subset) {
  let deferred = AwPromiseService.instance.defer();
  let inputData = {};
  inputData.objects = [subset];
  inputData.attributes = ["project_list"];
  let projects = [];
  soaSvc
    .post("Core-2006-03-DataManagement", "getProperties", inputData)
    .then(function (response) {
      let modelObj = response.modelObjects;
      for (let key in modelObj) {
        if (modelObj[key].type == "TC_Project") {
          let obj = {
            type: modelObj[key].type,
            uid: modelObj[key].uid,
          };
          projects.push(obj);
        }
      }
      deferred.resolve(projects);
    });
  return deferred.promise;
};

export let createInputProjectsForRemoval = function (data) {
  let listOfProjects = [];
  if (
    data.currentSelectedSubsetProject != null &&
    data.currentSelectedSubsetProject.length > 0
  ) {
    let modelObj = data.currentSelectedSubsetProject;
    if (modelObj.length == 1) {
      for (let key in modelObj) {
        if (modelObj[key].type == "TC_Project") {
          let obj = {
            type: modelObj[key].type,
            uid: modelObj[key].uid,
          };
          listOfProjects.push(obj);
        }
      }
    }
  }
  return listOfProjects;
};

export let createInputProjectsForAssignment = function () {
  let listOfProjects = [];
  let subsetModelObj = cdm.getObject(
    appCtxSvc.getCtx("selected.props.awb0UnderlyingObject.dbValues[0]")
  );

  let projObj = cdm.getObject(subsetModelObj.props.project_list.dbValues[0]);
  listOfProjects.push(projObj);
  return listOfProjects;
};

export let checkAssignProjectToSaveAsSubset = function (newSubsetUid) {
  let subsetModelObj = cdm.getObject(
    appCtxSvc.getCtx("selected.props.awb0UnderlyingObject.dbValues[0]")
  );

  let _onOccDataLoadedEvt = eventBus.subscribe(
    "occDataLoadedEvent",
    function () {
      if (
        appCtxSvc.getCtx(
          "occmgmtContext.productContextInfo.props.fgf0PartitionScheme.dbValues[0]"
        )
      ) {
        let partitionSchemeObj = cdm.getObject(
          appCtxSvc.getCtx(
            "occmgmtContext.productContextInfo.props.fgf0PartitionScheme.dbValues[0]"
          )
        );
        let setPartitionSchemePromise = replaySubsetRecipe(
          newSubsetUid,
          partitionSchemeObj,
          false
        );
        $.when(setPartitionSchemePromise).done(function () {
          if (subsetModelObj.props.project_list.dbValues.length > 0) {
            eventBus.publish("BmwSaveAsNew.assignProject");
          } else {
            eventBus.publish("primaryWorkarea.reset");
            eventBus.publish("complete", {
              source: "toolAndInfoPanel",
            });
          }
        });
      } else {
        let getPartitionSchemePromise = getPartitionScheme(
          "MMG-FB",
          newSubsetUid
        );
        $.when(getPartitionSchemePromise).done(function (partitionSchemeObj) {
          let setPartitionSchemePromise = replaySubsetRecipe(
            newSubsetUid,
            partitionSchemeObj,
            false
          );
          $.when(setPartitionSchemePromise).done(function () {
            if (subsetModelObj.props.project_list.dbValues.length > 0) {
              eventBus.publish("BmwSaveAsNew.assignProject");
            } else {
              eventBus.publish("primaryWorkarea.reset");
              eventBus.publish("complete", {
                source: "toolAndInfoPanel",
              });
            }
          });
        });
      }
      eventBus.unsubscribe(_onOccDataLoadedEvt);
    }
  );
  eventBus.publish("primaryWorkarea.reset");
};

/**
 * Fetch the partition scheme uid for a give partion scheme name and target object
 * @param {string} partitionSchemeName Name of the partition scheme to look for.
 * @param {string} targetObjectUid Uid of target configured object for which relevant partition schemes are to be fetched. e.g. uid of collaborative design or Cpd0DesignSubsetElement
 * e.g. getPartitionScheme("MMG-FB", "7pc5G6T35AChlD")
 */
let getPartitionScheme = function (partitionSchemeName, targetObjectUid) {
  let deferred = AwPromiseService.instance.defer();
  let inputData = {
    searchInput: {
      maxToLoad: 1,
      maxToReturn: 1,
      providerName: "Fgf0PartitionSchemeProvider",
      searchCriteria: {
        uid: targetObjectUid,
        searchString: partitionSchemeName,
      },
    },
  };

  soaSvc
    .post("Query-2014-11-Finder", "performSearch", inputData)
    .then(function (response) {
      let resultPartitionScheme = null;
      if (response.searchResults.length > 0) {
        resultPartitionScheme = {
          uid: response.searchResults[0].uid,
          type: response.searchResults[0].type,
        };
      }
      deferred.resolve(resultPartitionScheme);
    });

  return deferred.promise;
};

/**
 * This function can be used to replay the recipe and/or to set a partition scheme on subset
 * @param {string} subsetUid  Uid of the target subset
 * @param {string} partitionScheme Partition scheme uid
 * @param {boolean} boolReplay Flag to set replay recipe on subset
 */
let replaySubsetRecipe = function (subsetUid, partitionScheme, boolReplay) {
  let deferred = AwPromiseService.instance.defer();
  let subsetPCI = "";
  let subsetOverlayUid = "";
  for (let key in appCtxSvc.getCtx("occmgmtContext.elementToPCIMap")) {
    if (key.indexOf(subsetUid) != -1) {
      subsetOverlayUid = key;
      subsetPCI = appCtxSvc.getCtx("occmgmtContext.elementToPCIMap[key]");
    }
  }
  let inputData = {
    inputData: {
      product: {
        uid: appCtxSvc.getCtx(
          "occmgmtContext.topElement.props.awb0UnderlyingObject.dbValues[0]"
        ),
        type: appCtxSvc.getCtx(
          "occmgmtContext.topElement.props.awb0UnderlyingObjectType.dbValues[0]"
        ),
      },
      config: {
        productContext: {
          uid: subsetPCI,
          type: "Awb0ProductContextInfo",
        },
      },
      focusOccurrenceInput: {
        element: {
          uid: subsetOverlayUid,
          type: "Fgd0DesignSubsetElement",
        },
      },
      parentElement: appCtxSvc.getCtx("occmgmtContext.topElement.uid"),
      requestPref: {
        includePath: ["true"],
        loadTreeHierarchyThreshold: ["50"],
        displayMode: ["Tree"],
        calculateFilters: ["false"],
        useGlobalRevRule: ["false"],
        startFreshNavigation: ["false"],
      },
    },
  };

  if (partitionScheme != null) {
    inputData.inputData.config.occurrenceScheme = partitionScheme;
  }

  if (boolReplay) {
    inputData.inputData.requestPref.replayRecipe = ["true"];
  }

  soaSvc
    .post(
      "Internal-ActiveWorkspaceBom-2022-06-OccurrenceManagement",
      "getOccurrences4",
      inputData
    )
    .then(function () {
      // eslint-disable-next-line no-console
      console.log("Recipe replayed");
      deferred.resolve("recipe replayed");
    });

  return deferred.promise;
};

export let saveAsPostProcess = function (data) {
  let _onOccDataLoadedEvt = eventBus.subscribe(
    "occDataLoadedEvent",
    function (data) {
      //After occurrences are loaded, we want to trigger getVisibleCommands SOA call.
      console.log(data.newObject.uid);
      eventBus.publish("updateCommandContext");

      eventBus.unsubscribe(_onOccDataLoadedEvt);
    }
  );
};
export let createAWSHostUrl = function(response){
  let awsurlNew = browserUtils.getBaseURL();
  let awsHostPath = "";
  if(response != undefined){
      let  pCId = response.parentProductContext.uid;
      awsHostPath = browserUtils.getBaseURL() + CLIENT_SOA_PATH;
      pCId = pCId.replaceAll('+','%2B');
      pCId = pCId.replaceAll(':','%3A');
      pCId = pCId.replaceAll(',','%2C');
      pCId = pCId.replaceAll('$','%24');
      let COT_uid = response.parentOccurrence.occurrenceId;
      COT_uid = COT_uid.replaceAll(':','%3A');
      COT_uid = COT_uid.replaceAll(',','%2C');
      COT_uid = COT_uid.replaceAll('$','%24');
      awsurlNew +="?ah=true#/com.siemens.splm.clientfx.tcui.xrt.showObject?pageId=tc_xrt_Content&uid=" + appCtxSvc.getCtx("selected.uid") + "&c_uid=" + COT_uid + "&o_uid=" + COT_uid + "&pci_uid=" + pCId + "&spageId=tc_xrt_Overview&t_uid=" + COT_uid ;
  }
  let AWSHostedURL = {
    'awsurl' : awsurlNew,
    'awsHostPath' : awsHostPath
  };
  
  return AWSHostedURL;
};
/**
 * Trigger point for launching 'Open in Vis' feature. Acts upon selected object in context.
 */
 export let executeOpenInVisCommand = function() {
  // if( isToLaunchProduct() ) {
  //     launchProduct( false );
  // } else {
      launchSelections( false );
  //}
};

/**
 * Gets value for prefernce AWV0HostAWInVisUponLaunch
 * @return {Promise} Resolved with true if we should be opening in host.
 */
 function determineHostInVis() {
  var deferred = AwPromiseService.instance.defer();
  preferenceSvc.getLogicalValue( 'AWV0HostAWInVisUponLaunch' ).then( function( result ) {
      if( result !== null && result.length > 0 && result.toUpperCase() === 'TRUE' ) {
          deferred.resolve( true );
      } else {
          deferred.resolve( false );
      }
  }, function( error ) {
      logger.error( error );
  } );

  return deferred.promise;
};

/**
 * @return {Promise} Resolved with TRUE if we should be opening in host.
 */
 function determineOpenInHost() {
  let openInHost = false;
  if( appCtxSvc.ctx.aw_hosting_enabled && appCtxSvc.ctx.aw_host_type === hostConfigValues.HOST_TYPE_VIS ) {
      openInHost = hostInteropSvc.getCanTalkWithHost() &&
          hostInteropSvc.isHostServiceAvailable( hostServices.HS_HOST_OPEN, hostServices.VERSION_2014_02 );
  }
  return openInHost;
}
/**
 * Fetches VVI for selections.
 *
 */
 var launchSelections = function( isReloadOperation, occContext ) {
  return determineHostInVis().then( function( isHostInVis ) {
      let selectedObjects = selectionSvc.getSelection().selected;
      let isOpenInHost = determineOpenInHost();
      return exports.launchObject( isOpenInHost, isHostInVis, selectedObjects, isReloadOperation );
  } );
};
/**
 * Attaches static create options with IdInfo
 *
 * @param {object} idInfo - Idinfo object holding product context info.
 * @param {Boolean} openInHost - Open in host.
 * @param {Boolean} isTohostInVis - true if AW URL is to be hosted in Vis
 */
 function _createIdInfoWithOptionsAndAdditionalInfo( idInfo, openInHost, isTohostInVis, selection ) {
  idInfo.createOptions = {
      CreateVisSC: {
          includeInLaunchFile: false,
          optionValue: 'True'
      },
      Operation: {
          includeInLaunchFile: true,
          optionValue: 'Interop'
      }
  };

  if( selection && selection.type !== 'VisStructureContext' ) {
      idInfo.createOptions.TransientDoc = {
          includeInLaunchFile: true,
          optionValue: 'True'
      };
  }

  if( openInHost ) {
      var clientOption = {
          optionValue: 'AW',
          includeInLaunchFile: true
      };

      idInfo.createOptions.CLIENT = clientOption;
  }

  if( isTohostInVis ) {
      var awHostUrl = browserUtils.getBaseURL();

      awHostUrl += '?ah=true';

      /**
       * Pass along common, helpful, host activity logging options.
       */
      var urlAttrs = browserUtils.getWindowLocationAttributes();

      _.forEach( urlAttrs, function( attrValue, attrName ) {
          if( attrName !== 'ah' ) {
              awHostUrl += '&';
              awHostUrl += attrName;

              if( attrValue ) {
                  awHostUrl += '=';
                  awHostUrl += attrValue;
              }
          }
      } );

      awHostUrl += window.location.hash;

      idInfo.idAdditionalInfo = {
          AWSHostedURL: awHostUrl
      };

      let occmgmtActiveContext = appCtxSvc.getCtx( 'aceActiveContext' );
      let occmgmtContextKey = occmgmtActiveContext && occmgmtActiveContext.key ? occmgmtActiveContext.key : 'occmgmtContext';
      let occmgmtCtx = appCtxSvc.getCtx( occmgmtContextKey );

      if( occmgmtCtx && occmgmtCtx.topElement && occmgmtCtx.topElement.uid && occmgmtCtx.topElement.uid.length > 0 ) {
          // idInfo.idAdditionalInfo.AWTopElementUID = occmgmtCtx.topElement.uid;
          idInfo.idAdditionalInfo.Title = occmgmtCtx.topElement.props.awb0Archetype.uiValues[0];
      }

      if( _debug_logLaunchActivity ) {
          logger.info( 'createLaunchInfoRequest: ' + '_createIdInfoWithOptionsAndAdditionalInfo: ' +
              'AWSHostedURL=' + awHostUrl );
      }
  }
};
/**
 * Get server information from session
 *
 * @function _getServerInfoObject
 *
 * @return {Object} Server info object (or NULL if no tcSessionData  on app ctx).
 */
 var _getServerInfoObject = function() {
  if( appCtxSvc.ctx.tcSessionData && appCtxSvc.ctx.tcSessionData.server ) {
      var soaPath = appCtxSvc.ctx.tcSessionData.server;
      var protocol = soaPath.substring( 0, soaPath.indexOf( '://', 0 ) );
  var replacementSoaPath = soaPath;

  //Production OR Integration: Replace the trailing 'tc/' with 'bmwtc/'
  if(soaPath.indexOf("tc4jb.bmwgroup") !== -1 || soaPath.indexOf("tc4jbi.bmwgroup") !== -1 ) { 
    replacementSoaPath = soaPath.replace("tc/", "bmwtc/");
  }
  else if (soaPath.indexOf("ltawsteg310.gi-dev") !== -1) { //Special case: New SSO enabled test environment aws_dev_310
    replacementSoaPath = "https://tc4git.bmwgroup.net/aws_dev_310/"
  }
  else if (soaPath.indexOf("ltawsteg314.gi-dev") !== -1) { //Special case: New SSO enabled test environment aws_dev_314
    replacementSoaPath = "https://tc4git.bmwgroup.net/aws_dev_314/"
  }

  else {
    replacementSoaPath = soaPath;
  }

  console.log(replacementSoaPath);
      return {
          protocol: protocol,
          hostpath: replacementSoaPath,
          servermode: 4
      };
  }

  return null;
};

/**
 * Downloads VVI file based on input idInfos.
 *
 * @param {IdInforArray} idInfos - ID Info to use.
 * @param {Boolean} openInHost - TRUE if we should call 'host' API to open.
 * @param {Boolean} isReloadOperation - boolean to check if this is for reload operation
 * @returns {Promise} Resolved when processing is complete.
 */
 function _createLaunchInfo( idInfos, openInHost, isReloadOperation ) {
  if( _debug_logLaunchActivity ) {
      logger.info( 'createLaunchInfoRequest: ' + 'createLaunchInfo: ' + 'idInfos=' + idInfos.length );
  }

  var serverInfo = _getServerInfoObject();

  return createLaunchInfoShowObjTcVis( idInfos, serverInfo, isReloadOperation ).then( function( response ) {
      if( response && response.ticket ) {
          if( _debug_logLaunchActivity ) {
              logger.info( 'createLaunchInfoRequest: ' + 'createLaunchInfo: ' + 'openInHost=' + openInHost );
          }
          if( isReloadOperation ) {
              return AwPromiseService.instance.resolve( response.ticket );
          }
          if( openInHost ) {
              if( hostOpenService ) {
                hostOpenService.openInHostViaObjectInfo( '', response.ticket, 'VVI' );
              }
          } else {
              var fileName = fmsUtils.getFilenameFromTicket( response.ticket );
              //if 3D is active or previously loaded then set skip as true to avoid viewer unload
              let skipBeforeUnload = appCtxSvc.getCtx( 'viewer.skipBeforeUnloadExecution' );
              if( skipBeforeUnload === false ) {
                  appCtxSvc.updatePartialCtx( 'viewer.skipBeforeUnloadExecution', true );
              }
              fmsUtils.openFile( response.ticket, fileName );
          }
          return AwPromiseService.instance.resolve( true );
      }
      return AwPromiseService.instance.reject();
  } );
};
let createLaunchInfoShowObjTcVis = function( idInfo, serverInfo ) {
  if( !serverInfo ) {
      return _getServerInfoTcVis().then( function( serverInfo ) {
          return _createLaunchInfoServerRequestTcVis( idInfo, serverInfo );
      } );
  }

  return _createLaunchInfoServerRequestTcVis( idInfo, serverInfo );
};

var _createLaunchInfoServerRequestTcVis = function( idInfo, serverInfo ) {
  return _getTCVisTcSharePrefrenceTcVis().then( function( value ) {
      let input = {
          idInfos: idInfo,
          serverInfo: serverInfo,
          userDataAgentInfo: _getUserAgentInfoTcVis(),
          sessionInfo: {
              sessionDescriminator: session_discriminator_notset,
              sessionOptions: {
                  UseTransientVolume: {
                    optionValue: 'True',
                    includeInLaunchFile: false
                  }
              },
              sessionAdditionalInfo: {}
          },
      };

      if( value ) {
          input.sessionInfo.sessionDescriminator = sessionCtxSvc.getSessionDiscriminator();
      }
      return soaSvc.post( 'ActiveWorkspaceVis-2015-03-DataManagement', 'createLaunchInfo', input );
  } ).catch( error => {
      logger.error( 'Error while loading TC Vis Share preference: ' + error );
      var errMessage = messagingSvc.getSOAErrorMessage( error );
      return AwPromiseService.instance.reject( errMessage );
  } );
};

function _getTCVisTcSharePrefrenceTcVis() {
  let deferred = AwPromiseService.instance.defer();
  preferenceService.getStringValue( 'AWV0VisReuseTCServer' ).then( function( result ) {
      if( result !== null && result.length > 0 && result.toUpperCase() === 'TRUE' ) {
          deferred.resolve( true );
      } else {
          deferred.resolve( false );
      }
  } ).catch( function() {
      deferred.resolve( false );
  } );

  return deferred.promise;
}

function _getUserAgentInfoTcVis() {
  var userAgentInfo = {};

  userAgentInfo.userApplication = sessionCtxSvc.getClientID() ? sessionCtxSvc.getClientID() : 'ActiveWorkspaceClient';
  userAgentInfo.userAppVersion = sessionCtxSvc.getClientVersion();

  return userAgentInfo;
}

function _getServerInfoTcVis() {
  return _getSoaPath().then( function( soaPath ) {
      if( _debug_logLaunchActivity ) {
          logger.info( 'frameAdapterService:' + '_getServerInfo: ' + 'soaPath=' + soaPath );
      }

      var protocol = soaPath.substring( 0, soaPath.indexOf( '://', 0 ) );

      var returnObject = {};

      returnObject.protocol = protocol;
      returnObject.hostpath = soaPath;
      returnObject.servermode = 4;

      return returnObject;
  } );
}

function _getSoaPath() {
  if( !_.isEmpty( _soaPath ) ) {
      return AwPromiseService.instance.resolve( _soaPath );
  }

  var connectionUrl = browserUtils.getBaseURL() + WEB_XML_VIS_PROXY_CONTEXT;

  if( _debug_logLaunchActivity ) {
      logger.info( 'frameAdapterService:' + '_getSoaPath: ' + 'connectionUrl=' + connectionUrl );
  }

  return window.JSCom.Health.HealthUtils.getSOAFullPath( connectionUrl ).then(
      function( soaPathFromVisServer ) {
          if( !_.isEmpty( soaPathFromVisServer ) ) {
              _soaPath = soaPathFromVisServer;
          } else {
              _soaPath = _getDefaultSoaPath();
          }

          if( _debug_logLaunchActivity ) {
              logger.info( 'frameAdapterService:' + '_getSoaPath: ' + '_soaPath=' + soaPathFromVisServer );
          }

          return _soaPath;
      },
      function( err ) {
          logger.error( 'Failed to get soa path : ' + err );

          _soaPath = _getDefaultSoaPath();

          return _soaPath;
      } );
}


function _getDefaultSoaPath() {
  return browserUtils.getBaseURL() + CLIENT_SOA_PATH;
}



/**
 * Launch the selected model objects (can be an occurrence or item)
 *
 * @param {Boolean} openInHost - Open in host.
 * @param {Boolean} isTohostInVis - true if AW URL is to be hosted in Vis
 * @param {Array} selectedObjects - Array of selected objects.
 * @param {Boolean} isReloadOperation - true if the call is made for sending VVI ticket to TcVis
 * @returns {Object} fileTicket to send to TcVis after reload operation
 */
export let launchObject = function( openInHost, isTohostInVis, selectedObjects, isReloadOperation ) {
    var idInfos = [];

    if( selectedObjects && selectedObjects.length > 0 ) {
        selectedObjects.forEach( function( selection ) {
            var idInfo = {};

            idInfo.launchedObject = appCtxSvc.getCtx('occmgmtContext.productContextInfo');
            idInfo.occurrenceList = [selection];

            _createIdInfoWithOptionsAndAdditionalInfo( idInfo, openInHost, isTohostInVis, selection );

            idInfos.push( idInfo );
        } );
    }

    if( !_.isEmpty( idInfos ) ) {
        if( isReloadOperation ) {
            return _createLaunchInfo( idInfos, openInHost, isReloadOperation );
        }
        return _createLaunchInfo( idInfos, openInHost, isReloadOperation ).then( function( result ) {
            if( _debug_logLaunchActivity ) {
                logger.info( 'createLaunchInfoRequest: ' + 'launchObject: ' + 'Success of createLaunchInfo: ' + result );
            }
        }, function( err ) {
            if( _debug_logLaunchActivity ) {
                logger.info( 'createLaunchInfoRequest: ' + 'launchObject: ' + 'Failure of createLaunchInfo: ' + err );
            }

            /**
             * Note: This is legacy logic being kept (just-in-case), but if the earlier promise fails it
             * will likely not return 'falsy'.
             */
            if( !err ) {
                createLaunchFileRequestSvc.launchObject( selectedObjects );
            } else {
                messagingSvc.showWarning( err );
            }
        } );
    }
    logger.error( 'Operation failed since object upon which the action is to be performed does not exist.' );
    return AwPromiseService.instance.reject( 'Operation failed since object upon which the action is to be performed does not exist.' );
};

exports = {
  processAllSubsetsFromCurrentWorkset,
  getAllSubsetsFromWorkset,
  getAllProjectsFromSubsets,
  createInputProjectsForRemoval,
  createInputProjectsForAssignment,
  checkAssignProjectToSaveAsSubset,
  createAWSHostUrl,
  executeOpenInVisCommand,
  launchObject
};

export default exports;

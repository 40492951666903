/* eslint-disable no-extra-parens */
// @<COPYRIGHT>@
// ===========================================================================
// Copyright 2017.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ===========================================================================
// @<COPYRIGHT>@

/* global
 define
 */

/**
 * @module js/K4BCreateNewExchangeService
 */

import msgSvc from "js/messagingService";
import appCtxSvc from "js/appCtxService";
import commandPanelService from "js/commandPanel.service";
import cdm from "soa/kernel/clientDataModel";
import dmSvc from "soa/dataManagementService";
import eventBus from "js/eventBus";
import AwStateService from "js/awStateService";

let exports = {};

let parentData = {};

export let validateCmdInputs = function (ctx, data) {
  let message = "";
  let count = 0;
  let validInputObjects = [];
  for (let vmo in ctx.mselected) {
    if (
      !(
        ((ctx.mselected[vmo].modelType.typeHierarchyArray.indexOf("Item") >
          -1 ||
          ctx.mselected[vmo].modelType.typeHierarchyArray.indexOf(
            "MECollaborationContext"
          ) > -1) &&
          ctx.mselected[vmo].modelType.typeHierarchyArray.indexOf(
            "Sc0SRMXChange"
          ) === -1 &&
          ctx.mselected[vmo].modelType.typeHierarchyArray.indexOf("Vendor") ===
            -1) ||
        (ctx.mselected[vmo].modelType.typeHierarchyArray.indexOf(
          "ItemRevision"
        ) > -1 &&
          ctx.mselected[vmo].modelType.typeHierarchyArray.indexOf(
            "Sc0SRMXChangeRevision"
          ) === -1 &&
          ctx.mselected[vmo].modelType.typeHierarchyArray.indexOf(
            "VendorRevision"
          ) === -1) ||
        ctx.mselected[vmo].modelType.typeHierarchyArray.indexOf(
          "Awb0ConditionalElement"
        ) > -1
      )
    ) {
      count++;
      message =
        message +
        "<br>" +
        data.i18n.proceedWithSelectionWarning.replace(
          "{0}",
          ctx.mselected[vmo].props.object_string.dbValues[0]
        );
    } else {
      validInputObjects.push(ctx.mselected[vmo]);
    }
  }
  appCtxSvc.registerCtx("validInputObjects", validInputObjects);
  if (
    count > 0 &&
    !(
      ctx.activeToolsAndInfoCommand &&
      ctx.activeToolsAndInfoCommand.commandId === "K4BCreateExchange"
    )
  ) {
    message =
      data.i18n.sc1InvalidObjectsFoundWarning
        .replace("{0}", ctx.mselected.length - count)
        .replace("{1}", ctx.mselected.length) + message;
    let buttons = [
      {
        addClass: "btn btn-notlfy",
        text: data.i18n.cancel,
        onClick: function ($noty) {
          $noty.close();
          appCtxSvc.unRegisterCtx("validInputObjects");
        },
      },
      {
        addClass: "btn btn-notify",
        text: data.i18n.proceed,
        onClick: function ($noty) {
          $noty.close();
          commandPanelService.activateCommandPanel(
            "K4BCreateExchange",
            "aw_toolsAndInfo"
          );
        },
      },
    ];
    msgSvc.showWarning(message, buttons);
  } else {
    commandPanelService.activateCommandPanel(
      "K4BCreateExchange",
      "aw_toolsAndInfo"
    );
  }
};

/**
 * Initialize variables and methods when create Exchange panel is loaded.
 *
 * @param {Object} data The view model data object
 */
export let initializeCreateExchangePanel = function (data) {
  // handler to listen on click on type on create form. On click it
  // will again show type selection panel.
  appCtxSvc.registerCtx("panelName", data._internal.panelId);
  data.clearSelectedTypeHandler = function () {
    data.selectedType.dbValue = "";
    data.selectedTypeDisplayName.dbValue = "";
  };

  // reset items variables.
  if (!data.items) {
    data.items = [];
  } else {
    data.items.splice(0, data.items.length);
  }

  if (!data.itemsUids) {
    data.itemsUids = [];
  } else {
    data.itemsUids.splice(0, data.itemsUids.length);
  }

  // If this is create Exchange in context show selected objects
  // in Items panel.
  if (appCtxSvc.getCtx() && appCtxSvc.getCtx("validInputObjects")) {
    let selectedObjects = appCtxSvc.getCtx("validInputObjects");
    if (selectedObjects && selectedObjects.length > 0) {
      for (let i = 0; i < selectedObjects.length; i++) {
        data.items.push(selectedObjects[i]);
        if (
          selectedObjects[i].modelType.typeHierarchyArray.indexOf(
            "Awb0ConditionalElement"
          ) > -1
        ) {
          data.itemsUids.push(
            selectedObjects[i].props.awb0UnderlyingObject.dbValues[0]
          );
        } else {
          data.itemsUids.push(selectedObjects[i].uid);
        }
      }
    }
  }

  // store create change panel data to a variable.
  parentData = data;
};

/**
 * Add new item to items list.
 *
 * @param {String}
 *            data - The view model data
 * @param {String}
 *            eventData - event data
 */
export let addItems = function (data, eventData) {
  let message = "";
  let count = 0;
  if (eventData && data) {
    if (!parentData.items) {
      parentData.items = [];
    }

    if (!parentData.itemsUids) {
      parentData.itemsUids = [];
    }

    if (eventData.length > 0) {
      for (let i = 0; i < eventData.length; i++) {
        if (
          !(
            eventData[i].modelType.typeHierarchyArray.indexOf(
              "Sc0SRMXChangeRevision"
            ) > -1 ||
            eventData[i].modelType.typeHierarchyArray.indexOf(
              "VendorRevision"
            ) > -1 ||
            eventData[i].modelType.typeHierarchyArray.indexOf("Vendor") > -1
          )
        ) {
          parentData.items.push(eventData[i]);
          parentData.itemsUids.push(eventData[i].uid);
        } else {
          count++;
          message =
            message +
            "<br>" +
            data.i18n.proceedWithSelectionWarning.replace(
              "{0}",
              eventData[i].props.object_string.dbValues[0]
            );
        }
      }
    }

    if (count === 1 && eventData.length === 1) {
      message = data.i18n.addItemsFailed.replace(
        "{0}",
        eventData[0].props.object_string.dbValues[0]
      );
      msgSvc.showError(message);
    } else if (count > 0) {
      message =
        data.i18n.sc1InvalidObjectsFoundWarning
          .replace("{0}", eventData.length - count)
          .replace("{1}", eventData.length) + message;
      msgSvc.showError(message);
    }

    if (
      parentData.dataProviders &&
      parentData.dataProviders.getSelectedItemList
    ) {
      // update data provider
      parentData.dataProviders.getSelectedItemList.update(
        parentData.items,
        parentData.items.length
      );

      // clear selection
      parentData.dataProviders.getSelectedItemList.changeObjectsSelection(
        0,
        parentData.dataProviders.getSelectedItemList.getLength() - 1,
        false
      );
    }

    data = parentData;
  }
};

/**
 * When user select type from type selection panel of change we need to navigate to create form. This method
 * will set few variable to hide type selector panel and to show create form.
 *
 * @param {Object} data - The panel's view model object
 */
export let handleTypeSelectionJs = function (data) {
  // Initialize some data

  if (data.items.length > 0) {
    for (let i = 0; i < data.items.length; i++) {
      data.items[i].selected = false;
    }
  }
  data.dataProviders.getSelectedItemList.update(data.items, data.items.length);
  data.dataProviders.getSelectedItemList.changeObjectsSelection(
    0,
    data.dataProviders.getSelectedItemList.getLength() - 1,
    false
  );
  let selectedType = data.dataProviders.awTypeSelector.selectedObjects;
  if (selectedType && selectedType.length > 0) {
    data.selectedType.dbValue = selectedType[0].props.type_name.dbValue;
  } else {
    data.selectedType.dbValue = "";
  }
};
/**
 *
 * Opens the created exchange revision object in location
 * @param {Object} exchangeRevisionObject Exchange Revision
 *
 */
export let openExchangeObject = function (exchangeRevisionObject) {
  if (exchangeRevisionObject && exchangeRevisionObject.uid) {
    let showObject = "com_siemens_splm_clientfx_tcui_xrt_showObject";
    let toParams = {};
    let options = {};

    toParams.uid = exchangeRevisionObject.uid;

    options.inherit = false;

    AwStateService.instance.go(showObject, toParams, options);
  }
};

/**
 * createObjects SOA input data. Create Input for exchangeline
 *
 * @param {object} data the view model data object
 * @param {object} subPanelContext  Current context information
 * @return {object} input ExchangeLine Create Input
 */
export let getExchangeLineCreateInput = function (data, subPanelContext) {
  let input = [];
  let createInput;
  let underlyingObject = null;
  let parentObj = null;
  if (data.items.length > 0) {
    let selectedItemRev = data.items;
    if (
      appCtxSvc.getCtx("selected.modelType.typeHierarchyArray").indexOf(
        "Awb0ConditionalElement"
      ) > -1
    ) {
      let siblingExchangeLines =
        exports.getSiblingExchangeLines(selectedItemRev);
      if (
        subPanelContext.pageContext.primaryActiveTabId === "tc_xrt_Overview"
      ) {
        parentObj = {
          uid: appCtxSvc.getCtx("pselected.uid"),
          type: appCtxSvc.getCtx("pselected.type"),
        };
      } else {
        underlyingObject = cdm.getObject(
          appCtxSvc.getCtx("selected.props.awb0UnderlyingObject.dbValues[0]")
        );
        parentObj = {
          uid: underlyingObject.uid,
          type: underlyingObject.type,
        };
      }
      createInput = {
        boName: "Vm0ExchangeLine",
        tagProps: {
          sc0SelObj: parentObj,
        },
        tagArrayProps: {
          sc0SiblingExchangeLines: siblingExchangeLines,
        },
        stringArrayProps: {
          sc0OccThreadChain: [
            appCtxSvc.getCtx("aceActiveContext.context.productContextInfo.uid"),
          ],
        },
      };
      input.push(createInput);
    } else {
      for (let secondObj in selectedItemRev) {
        createInput = {
          boName: "Vm0ExchangeLine",
          tagProps: {
            sc0SelObj: selectedItemRev[secondObj],
          },
        };
        input.push(createInput);
      }
    }
  }
  return input;
};

/**
 * Helper method for getting siblings
 *
 * @param {selectedItemRev} selectedItemRev Selected Item revison
 * @return {siblings} Item selected in ACE View
 */
export let getSiblingExchangeLines = function (selectedItemRev) {
  let siblings = [];

  for (let Obj in selectedItemRev) {
    let value = {
      uid: selectedItemRev[Obj].uid,
      type: selectedItemRev[Obj].type,
    };
    siblings.push(value);
  }
  return siblings;
};

/**
 * Get Revision name of Business object
 * @param {String} boName Business object name
 * @returns {String} Business object revision name
 */
export let getRevisionName = function (boName) {
  let boRevName = boName + "Revision";
  return boRevName;
};
/**
 * Get exchange revision created
 *
 * @param {object} data the view model data object
 * @return {object} input Exchange Revision
 */
export let getExRevisionFromSOAOutput = function (data) {
  let input;
  if (data.output.length > 0) {
    let createdObjs = data.output[0].objects;
    for (let obj in createdObjs) {
      if (
        createdObjs[obj].modelType.typeHierarchyArray.indexOf(
          "Sc0DesignXChangeRevision"
        ) > -1
      ) {
        input = createdObjs[obj];
        break;
      }
    }
  }
  return input;
};

/**
 * Get the new stuff folder of logged in user Object from application context service and
 * return the Object
 *
 * @return {Object} user - Newstuff folder object
 */
export let getNewStuffObject = function () {
  let currentNewStuffFolder = null;
  if (appCtxSvc.getCtx("user.props.newstuff_folder")) {
    currentNewStuffFolder = cdm.getObject(
      appCtxSvc.getCtx("user.props.newstuff_folder.dbValues[ 0 ]")
    );
  } else {
    dmSvc
      .getProperties([appCtxSvc.ctx.user.uid], ["newstuff_folder"])
      .then(function () {
        currentNewStuffFolder = cdm.getObject(
          appCtxSvc.getCtx("user.props.newstuff_folder.dbValues[ 0 ]")
        );
      });
  }

  return currentNewStuffFolder;
};

/**
 * Clear selected type when user click on type link on create form
 *
 * @param {Object} data - The create change panel's view model object
 *
 */
export let clearSelectedType = function (data) {
  data.selectedType.dbValue = "";
};

/**
 * Remove given Items from Items list.
 *
 * @param {String} selectedItem - The selected item to be removed
 */
export let removeItemsJs = function (selectedItem) {
  if (selectedItem && selectedItem.length > 0) {
    if (parentData.items && parentData.itemsUids) {
      for (let i = 0; i < selectedItem.length; i++) {
        let index = parentData.itemsUids.indexOf(selectedItem[i].uid);
        if (index > -1) {
          parentData.items.splice(index, 1);
          parentData.itemsUids.splice(index, 1);
        }
      }
    }

    if (
      parentData.dataProviders &&
      parentData.dataProviders.getSelectedItemList
    ) {
      parentData.dataProviders.getSelectedItemList.update(
        parentData.items,
        parentData.items.length
      );
    }
  }
};

/**
 * Add Items Page
 *
 * @param {String} titleLabel - Title for page
 */
export let addItemsPage = function (titleLabel) {
  let destPanelId = "K4BAddItemsSub";
  let context = {
    destPanelId: destPanelId,
    supportGoBack: true,
    title: titleLabel,
    recreatePanel: true,
    isolateMode: true,
  };
  eventBus.publish("awPanel.navigate", context);
};

/**
 * return Data
 * @return {object} parentData
 */
export let getData = function () {
  return parentData;
};

/**
 * return Data
 * createInputProjectsForAssignment
 */
export let createInputProjectsForAssignment = function (subPanelContext) {
  let listOfProjects = [];
  let projObj = null;
  let underlyingObject = null;
  let projects = null;
  if (
    subPanelContext.pageContext.primaryActiveTabId === "tc_xrt_Overview"
  ) {
    projects = appCtxSvc.getCtx("selected.props.project_list.dbValues");
    for (let y = 0; y < projects.length; y++) {
      projObj = cdm.getObject(
        appCtxSvc.getCtx("selected.props.project_list.dbValues[y]")
      );
      listOfProjects.push(projObj);
    }
  } else {
    underlyingObject = cdm.getObject(
      appCtxSvc.getCtx("selected.props.awb0UnderlyingObject.dbValues[0]")
    );
    projects = underlyingObject.props.project_list.dbValues;
    for (let ii = 0; ii < projects.length; ii++) {
      projObj = cdm.getObject(underlyingObject.props.project_list.dbValues[ii]);
      listOfProjects.push(projObj);
    }
  }
  return listOfProjects;
};

/**
 * Get the OwningProject of selected part from application context service and
 * return the Object
 *
 * @return {Object} Owning Project
 */
export let getOwningProject = function () {
  let uid = null;
  let underlyingObject = null;
  if (
    appCtxSvc.getCtx("xrtPageContext") !== undefined &&
    appCtxSvc.getCtx("xrtPageContext").primaryXrtPageID === "tc_xrt_Overview"
  ) {
    uid = appCtxSvc.getCtx("selected.props.owning_project.dbValues[0]");
  } else {
    underlyingObject = cdm.getObject(
      appCtxSvc.getCtx("selected.props.awb0UnderlyingObject.dbValues[0]")
    );
    uid = underlyingObject.props.owning_project.dbValues[0];
  }
  return uid;
};

/**
 * K4BCreateNewExchangeService factory
 *
 * @param {Object} msgSvc: messaging service
 * @param {Object} appCtxSvc: app context service
 * @param {Object} commandPanelService: command Panel service
 * @returns {Object} exports: export object
 */

exports = {
  validateCmdInputs,
  initializeCreateExchangePanel,
  addItems,
  handleTypeSelectionJs,
  openExchangeObject,
  getExchangeLineCreateInput,
  getSiblingExchangeLines,
  getRevisionName,
  getExRevisionFromSOAOutput,
  getNewStuffObject,
  clearSelectedType,
  removeItemsJs,
  addItemsPage,
  getData,
  createInputProjectsForAssignment,
  getOwningProject,
};
export default exports;

/**
 * VaaS Integration service
 *
 * @module js/BMWVaaSIntegrationService
 */
import app from 'app';
import soaSvc from 'soa/kernel/soaService';
import bmwVaasLogic from 'js/BMWVaaSIntgrnBusinessLogic';
import AwPromiseService from 'js/awPromiseService';

'use strict';
var exports = {};

/**
 * Function Name : openDALiGeometryInVaaS
 * This function collects the doku id to launch Geometry in VaaS 
 * @param {*} process  - Possible values (V4B_DALi_IssueRevision,  V4B_VGA_IssueRevision, GEPARD)
 * @param {*} dokuID  - Doku ID of the Geometry
 */
export let openGeometryInVaaS = function (process, dokuIDs, schnittlage, revision) {

    var baseURL = app.getBaseUrlPath();

    var vaasPopupWindow = null;
    switch (process) {
        case "V4B_DALi_IssueRevision":
            // calling method from plane JS
            vaasPopupWindow = bmwVaasLogic.openVIMGeometry4mDokuID(process, baseURL, dokuIDs[0]);
            break;
        
        case "V4B_VGA_IssueRevision":
            vaasPopupWindow = bmwVaasLogic.openVIMGeometry4mDokuID(process, baseURL, dokuIDs[0]);
            break;
        case "Gepard":
            // Gepard implementation here
            break;
        case "FTM":
            // FTM VIMContainer implementation here
            console.log(" FTM VIMContainer  choosen in BMWVaaSIntegrationService.js. " + "DokuIDs == " + dokuIDs + "; process == " + process + "; baseURL == " + baseURL);
            vaasPopupWindow = bmwVaasLogic.openVIMGeometry4mDokuID(process, baseURL, dokuIDs);
            break;
        default:
            vaasPopupWindow = bmwVaasLogic.openVIMGeometry4mDokuID(process, baseURL, dokuIDs[0]);
            break;
    }

    return vaasPopupWindow;
};

/**
 * Function Name : openDALiGeometryInVaaS
 * This function collects the doku id to launch Geometry in VaaS 
 * @param {*} process  - Possible values (V4B_DALi_IssueRevision, V4B_VGA_IssueRevision, GEPARD)
 * @param {*} uid  - UID of the Geometry
 */
export let open3DSessionInVaaS = function (process, uid) {

    var baseURL = app.getBaseUrlPath();
    var vaasPopupWindow = null;

    switch (process) {
        case "V4B_DALi_IssueRevision":
            // calling method from plane JS
            exports.getURN(uid, "DALi").then(function (urn) {
                if (urn != null) {
                    //exports.testAction(urn);
                    vaasPopupWindow = bmwVaasLogic.openVIMGeometry4mUID(process, baseURL, urn);
                }
            });

            break;
        
        case "V4B_VGA_IssueRevision":
            // calling method from plane JS
            exports.getURN(uid, "VGA").then(function (urn) {
                if (urn != null) {
                    //exports.testAction(urn);
                    vaasPopupWindow = bmwVaasLogic.openVIMGeometry4mUID(process, baseURL, urn);
                }
            });

            break;
        case "Gepard":
            // Gepard implementation here
            break;
        default:

            break;
    }
};

/**
 * Function Name : getURN
 * This function collects the URN to launch Geometry in VaaS 
 * @param {*} uid  - uid of the Geometry
 * @param {*} process  - Possible values (DALi, VGA, GEPARD)         
 */
export let getURN = function (uid, process) {
    var deferred = AwPromiseService.instance.defer();
    var input = {
        "issueID": uid,
        "operationName": process
    };
    var request = {
        "input": input
    }
    soaSvc.postUnchecked("DwnldXfrmFile-2015-03-DwnldXfrmFile", "downloadPLMXML", request).then(function (result) {
        return deferred.resolve(result.urn);
    });
    return deferred.promise;
};

exports = {
    openGeometryInVaaS,
    open3DSessionInVaaS,
    getURN
}
export default exports;
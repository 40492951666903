/**
 * Service to handle archive status for time slices
 * @module js/G4B_TS_ArchiveService
 */

import _ from 'lodash';
import _soaSvc from 'soa/kernel/soaService';
import _appCtxSvc from 'js/appCtxService';
import eventBus from 'js/eventBus';
import _cdm from 'soa/kernel/clientDataModel';
import msgSvc from 'js/messagingService';
import localeSvc from 'js/localeService';

var exports = {};

export let setPropertiesForArchive = function (isArchive, commandContext) {
    var selectedObj = _appCtxSvc.getCtx('selected');
    var selected = {};
    selected.type = "G4B_GenSecondaryTSInfo";
    selected.uid = selectedObj.props.G4B_SecondaryTSInfoRel.dbValue[0];

    var inputData = {};

    if (isArchive == true) {
        inputData = {
            objects: [selected],
            attributes: {
                g4b_IsArchived: {
                    stringVec: ['true']
                }
            },
        };
    }
    if (isArchive == false) {
        inputData = {
            objects: [selected],
            attributes: {
                g4b_IsArchived: {
                    stringVec: ['false']
                }
            },
        };
    }
    _soaSvc.post("Core-2007-01-DataManagement", "setProperties", inputData).then(
        function (response) {

            //Update archive prop in masterJSONData for time-slice tree
            let selection =  commandContext.selection[0];

            if (_appCtxSvc.getCtx('g4bTimeSliceTreeContext') != undefined) {
                let timeSliceTreeData = _.clone(_appCtxSvc.getCtx('g4bTimeSliceTreeContext').masterJSONData.data);
                for (let key in timeSliceTreeData) {
                    for (let index = 0; index < timeSliceTreeData[key].length; index++) {
                        if(timeSliceTreeData[key][index].type == "G4B_TSInfo2" && timeSliceTreeData[key][index].displayName == selection.props.object_string.dbValues[0]){
                            let g4b_IsArchivedProp = _cdm.getObject(selection.props.G4B_SecondaryTSInfoRel.dbValues[0]).props.g4b_IsArchived;
                            timeSliceTreeData[key][index].props.g4b_IsArchived =_.clone(g4b_IsArchivedProp);
                            break;
                        }
                    }
                }

                _appCtxSvc.ctx.g4bTimeSliceTreeContext.masterJSONData.data = timeSliceTreeData;
            }

            let message = '';
            let resource = 'GepardOneStepCommandsMessages';
            let localTextBundle = localeSvc.getLoadedText( resource );

            if(isArchive){
                message = localTextBundle.G4B_ArchiveObjectSuccess.replace( '{0}', selection.props.object_string.dbValues[0] );
            }
            else{
                message = localTextBundle.G4B_UnArchiveObjectSuccess.replace( '{0}', selection.props.object_string.dbValues[0] );
            }
            msgSvc.showInfo( message );
            eventBus.publish( 'cdm.relatedModified', {
                refreshLocationFlag: true,
                relatedModified: [ _appCtxSvc.ctx.selected ]
            } );
            commandContext.selectionModel.selectNone();
            eventBus.publish("executeProductLineSmartSearch.event");
            //eventBus.publish( "timeSliceTreeGrid.plTable.reload");

        }
    );
}

export default exports = {
    setPropertiesForArchive
};

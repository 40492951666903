/**
 *
 * @author Anantha Bhople
 * File description : This file contains "generateVIMPPTReport" base function, which will be call to generate VIM PPT report.
 * @module js/GenerateVIMPPTReportService
 * ModifyBy      ModifyOn          Comment
 *Anantha Bhople 20-Feb-2020       Single page report generation for multiple issue selections.
 *Anantha Bhople 06-March-2020     Updated for DUPLICATED dispatcher request problem, create dispatcher request with NULL value.
 *Sunil Petkar   30-July-2020      Add new function "loadIssues" to  load the property of all selected DiC issue revision.
 *Anantha Bhople 31-May-2021       Updated variables and functions names to Implement PPT report functionality for FTM Issue.
 *Anantha Bhople 28-June-2021      Updated functions to enable generate ppt report feature for V4B_VIMContainerRevision.
 *Anantha Bhople 16-Aug-2023       GAUDI-11684 : Remove dispatcher dependency and implement soa service approach.
 *
 *
 */

import soaSvc from "soa/kernel/soaService";
import cdmSvc from "soa/kernel/clientDataModel";
import VIMSoaUtils from "js/VIMSoaUtils";
import messagingService from "js/messagingService";
import _ from "lodash";
import $ from "jquery";
import fmsUtils from "js/fmsUtils";
import AwPromiseService from "js/awPromiseService";
import appCtxSvc from "js/appCtxService";
import modelPropertyService from "js/modelPropertyService";

("use strict");

let exports = {};
let selectedModelObjects = null;
let savedQueryModelObject = null;
let savedDatasetQueryName = "Dataset...";
let vimppt_report_template_name = null;
let queryResult = null;
let vimppt_template_uid_trans_args = null;
let reportTemplatePrefernceValues = null;
let selectedMType = null;

/**
 * Fetches base url
 * @return {String} _cachedBaseURL
 */
let getBaseURL = function () {
  let _cachedBaseURL;
  if (!_cachedBaseURL) {
    let pathname = window.location.pathname;
    window.location.origin ||
      (window.location.origin =
        window.location.protocol +
        "//" +
        window.location.hostname +
        (window.location.port ? ":" + window.location.port : "")),
      (_cachedBaseURL =
        window.location.origin +
        pathname.substring(0, pathname.lastIndexOf("/") + 1));
  }
  return _cachedBaseURL;
};
/**
 * Open a file given the file ticket
 *
 * @param {String} fileTicket - The file ticket
 * @param {String} openFileName - open file with this name.
 */
export let openFile = function (fileTicket, openFileName) {
  let RETRY_COUNTER = 5;
  let deferred = AwPromiseService.instance.defer();

  let downloadUri =
    "fms/fmsdownload/" +
    openFileName +
    "?ticket=" +
    encodeURIComponent(fileTicket);
  let finalURI = getBaseURL() + downloadUri;

  //output =  window.open(finalURI, "_self", "enabled" );

  let xhr = new XMLHttpRequest();
  xhr.open("GET", finalURI, true); // GET request to download file

  xhr.responseType = "arraybuffer";
  xhr.onload = function () {
    if (xhr.status == 200) {
      let fileData = xhr.response;
      let blob = new Blob([fileData], {
        type: "octet/stream",
      });
      //for microsoft IE
      if (
        window.navigator.userAgent.indexOf("MSIE ") > -1 ||
        !!navigator.userAgent.match(/Trident.*rv\:11\./)
      ) {
        window.navigator.msSaveBlob(blob, openFileName);
      } else {
        console.log("Creating Blob object");
        let blobUrl = URL.createObjectURL(blob);
        let a = document.createElement("a");
        a.href = blobUrl;
        a.download = openFileName;
        a.style = "display: none";
        document.body.appendChild(a);
        console.log("About to click on href element.");
        a.click();
        window.URL.revokeObjectURL(blobUrl);
        document.body.removeChild(a);
        a.remove();
        console.log("Done!!! resolving the promise");
        deferred.resolve();
      }
    } else if (xhr.status == 404 && RETRY_COUNTER > 0) {
      console.log("File not found (404).. retrying after 3 seconds.");
      RETRY_COUNTER--;
      console.log("RETRY_COUNTER: " + RETRY_COUNTER);
      setTimeout(function () {
        xhr.open("GET", finalURI, true); // re-initaialize
        xhr.send();
      }, 3000);
    }
  };
  xhr.send();

  return deferred.promise;
};

/**
 * This function prepare input data to create power point report for selected VIM issues.
 *  - Find template dataset for selected object.
 *  - Call a function to prepare input string for selected objects with format "uid, item_id item_revision_id, uid, item_id item_revision_id, ...."
 *  - Call a function to create ppt report and download on user system.
 * @param {Model Object} data - json model object.
 */

export let generateVIMPPTReport = function (data) {
  //Check no of selected issues for vim ppt report. IF more than 100 are selected give warning message to user.
  if (appCtxSvc.getCtx("mselected.length") > 100) {
    let messageString = data.i18n.moreIssuesSelectedForVIMPPTReport;
    messagingService.showError(messageString);
    return null;
  }

  //get selected model object and object type.
  selectedModelObjects = appCtxSvc.getCtx("mselected");
  if (selectedModelObjects != null)
    selectedMType = selectedModelObjects[0].type;

  /**call saved query function to find VIM PPT report template dataset*/
  VIMSoaUtils.findSavedQueries(savedDatasetQueryName).then(function (
    query_response
  ) {
    savedQueryModelObject = null;
    if (query_response.savedQueries == undefined) {
      let messageString = data.i18n.queryNotFound;
      messagingService.showError(messageString);
      return null;
    }
    savedQueryModelObject = null;
    savedQueryModelObject = query_response.savedQueries[0];
    /** check saved query found and throw an error message */
    if (savedQueryModelObject === null) {
      let messageString = data.i18n.queryNotFound;
      messagingService.showError(messageString);
      return null;
    }

    /** check preference is undefined and throw and error message */
    vimppt_report_template_name = null;
    if (
      appCtxSvc.getCtx("preferences.V4B_VIM_PPT_report_template") === undefined
    ) {
      let messageString = data.i18n.vimPPTReportTemplatePrefNotDefined;
      messagingService.showError(messageString);
      return null;
    }

    /**read report template dataset name from preference*/
    reportTemplatePrefernceValues = appCtxSvc.getCtx(
      "preferences.V4B_VIM_PPT_report_template"
    );
    for (let ii = 0; ii < reportTemplatePrefernceValues.length; ii++) {
      let prefValue = null;
      if (reportTemplatePrefernceValues.indexOf("=")) {
        prefValue = reportTemplatePrefernceValues[ii].substring(
          0,
          reportTemplatePrefernceValues[ii].lastIndexOf("=")
        );
        if (selectedMType === prefValue) {
          vimppt_report_template_name = reportTemplatePrefernceValues[
            ii
          ].substring(
            reportTemplatePrefernceValues[ii].lastIndexOf("=") + 1,
            reportTemplatePrefernceValues[ii].length
          );
          break;
        }
      }
    }
    /**check report template variable empty and throw an error */
    if (vimppt_report_template_name === null) {
      let messageString = data.i18n.vimPPTReportTemplatePrefNotDefined;
      messagingService.showError(messageString);
      return null;
    }
    /**prepare input data to saved query and execute query*/
    let executeQueryInputData = {
      input: [
        {
          query: savedQueryModelObject,
          entries: [data.i18n.queryEntryType, data.i18n.queryDatasetType],
          //entries: ["Name", "Dataset Type"],
          values: [vimppt_report_template_name, "MS Power Point M"],
          maxNumToReturn: 25,
          clientId: "AWC",
        },
      ],
    };

    soaSvc
      .post(
        "Query-2008-06-SavedQuery",
        "executeSavedQueries",
        executeQueryInputData
      )
      .then(
        function (query_resultReponse) {
          queryResult = null;
          //get vim pp report template dataset uid
          if (query_resultReponse.arrayOfResults.length > 0) {
            queryResult = query_resultReponse.arrayOfResults[0];
            if (queryResult.objectUIDS.length > 0) {
              vimppt_template_uid_trans_args = null;
              vimppt_template_uid_trans_args = queryResult.objectUIDS[0];
              /**Call prepareGeneratePPTReportRequestInput function*/
              prepareGeneratePPTReportRequestInput(vimppt_template_uid_trans_args, selectedModelObjects, data);
            }
          } else {
            let messageString = data.i18n.vimPPTReportTemplateDatasetNotFound;
            messagingService.showError(messageString);
          }
        },
        function (errObj) {
          let msg = errObj;
          errObj && errObj.message && (msg = errObj.message),
            messagingService.showError(msg);
          let lovMap = new Map();
          return lovMap;
        }
      );
  });
};

/**
 * This function will be used to call soa service operation downloadPPTReport soa service and it will genrate report.
 * This function wait till the request get completed.
 *  - If soa service response 'reportGenerationStatus' = COMPLETED then donload report on user system.
 *  - If soa service response 'reportGenerationStatus' = FAILED then show error message to user.
 *  - Report dataset will be deleted from Teamcenter database.
 * @function generatePPTReportAndDownlaod
 * @param {Array} keyValueArgInfo - request key-value argument information
 * @param {data} data
 * 
 */
function generatePPTReportAndDownlaod(keyValueArgInfo, data) {
  let inputData = {
      pptReportInput: {
          keyValueArgInfo: keyValueArgInfo
      }
  };
  soaSvc.post("VIMC-2016-03-DownloadOrUpdateAttachments", "downloadPPTReport", inputData).then(function (response) {

            if (response.reportGenerationStatus != "COMPLETE") {
                let messageString = data.i18n.vimPPTReportGenerationFailed;
                messagingService.showInfo(messageString);
                return null;
            }

        exports.downloadReportFilesAndDeleteDataset(data, response);
      },
      function (errObj) {
        let msg = errObj;
        errObj && errObj.message && (msg = errObj.message),
          messagingService.showError(msg);
        let lovMap = new Map();
        return lovMap;
      }
    );

    let messageString = data.i18n.vimPPTReportRequestCreatedSuccessfully;
    messagingService.showInfo(messageString);
}

/**
 * This function will be used to prepare input payload required to downloadPPTReport soa service operation.
 * @param {UID} vimppt_template_uid_trans_args - VIM PPX report template uid..
 * @param {ModelObject} selectedModelObjects - Selected  model objects.
 * @param {ModelObject} _selectedMType - selected model object type.
 * @param {data} data
 * 
 */
function prepareGeneratePPTReportRequestInput(vimppt_template_uid_trans_args, selectedModelObjects, data) {
  let keyValueArgInfo = [];
  let selected_issues_uid = null;
  keyValueArgInfo.push({
    argKey: "template_dataset_uid",
    argValue: vimppt_template_uid_trans_args,
  });
  keyValueArgInfo.push({
    argKey: "selected_issue_type",
    argValue: selectedModelObjects[0].type,
  });
  if (selectedMType === "V4B_VIMContainerRevision") {
    selected_issues_uid = exports.getVCONUidStr(selectedModelObjects, data);

    keyValueArgInfo.push({
      argKey: "vim_issue_type_in_container",
      argValue: "V4B_FTMTKB_IssueRevision",
    });

    keyValueArgInfo.push({
      argKey: "vcon_preview_img",
      argValue: data.base64ImgStream,
    });
  } else {
    selected_issues_uid = exports.getVIMIssuesUidStr(
      selectedModelObjects,
      false
    );
  }
  keyValueArgInfo.push({
    argKey: "selected_issues_uid",
    argValue: selected_issues_uid,
  });
  /**give call to user defined function to generate report**/
  generatePPTReportAndDownlaod(keyValueArgInfo, data);
  exports.removeClipboarPasteEventForVCONReport();
}

/**
 * This function preapre input string with format
 * "uid awp0Item_item_id item_revision_id,uid awp0Item_item_id item_revision_id,....#%#uid awp0Item_item_id item_revision_id,uid awp0Item_item_id item_revision_id,...."
 * for selected VIM Container.
 * @param {ModelObject} selectedModelObjects - Selected  model objects.
 * @param {data} data
 * @returns - selectedVCONAndAttachedIssuesUidStr
 */
export let getVCONUidStr = function (selectedModelObjects, data) {
  let selectedVCONAndAttachedIssuesUidStr = null;
  let attachedIssuesUidStr = null;
  let firstVCONElement = true;
  selectedModelObjects.forEach(function (vcon) {
    if (firstVCONElement) {
      selectedVCONAndAttachedIssuesUidStr = vcon.uid;
      firstVCONElement = false;
    } else {
      selectedVCONAndAttachedIssuesUidStr =
        selectedVCONAndAttachedIssuesUidStr + "#%#" + vcon.uid;
    }

    //get attached issues
    let allIssues = _.get(vcon, "props.V4B_Container_VIM_FTM_Rel.dbValues", []);
    let filteredIssueList = exports.getFilterAttachedIssues(
      data.selectedMaturityList,
      allIssues
    );
    //append attached issues "uid " string*/
    attachedIssuesUidStr = exports.getVIMIssuesUidStr(filteredIssueList, true);
    if (attachedIssuesUidStr != null && attachedIssuesUidStr) {
      selectedVCONAndAttachedIssuesUidStr =
        selectedVCONAndAttachedIssuesUidStr + "#@#" + attachedIssuesUidStr;
    }
  });
  return selectedVCONAndAttachedIssuesUidStr;
};

/**
 * This function prepare input string with format "uid awp0Item_item_id item_revision_id,uid awp0Item_item_id item_revision_id,...."
 * for input vim issues(e.g. DiC Issue, FTM Issue)
 * @param {modelObjects} vimIssues - vim issues(e.g DiC Issue, FTM Issue)
 * @param {boolean} isVIMContainerIssues - true --> If generate report for VIM Container
 *                                         false --> If generate report for VIM Issue(e.g. DiC, FTM)
 * @returns - vimIssuesUidStr
 */
export let getVIMIssuesUidStr = function (vimIssues, isVIMContainerIssues) {
  let vimIssuesUidStr = null;
  let firstLine = true;
  vimIssues.forEach(function (issueRev) {
    let vimIssueUid = null;
    if (isVIMContainerIssues) {
      vimIssueUid = issueRev;
    } else {
      vimIssueUid = issueRev.uid;
    }
    if (vimIssueUid != null && vimIssueUid) {
      if (firstLine) {
        vimIssuesUidStr = vimIssueUid;
        firstLine = false;
      } else {
        vimIssuesUidStr = vimIssuesUidStr.concat(",");
        vimIssuesUidStr = vimIssuesUidStr.concat(vimIssueUid);
      }
    }
  });

  return vimIssuesUidStr;
};

/**
 * This function create PPT report for vim container
 *  @param {Model Object} data - json model object.
 */

export let generateVCONPPTReport = function (data) {
  let panelContext = appCtxSvc.getCtx("panelContext");
  //get maturtiy list
  let selectedMaturityList = exports.getSelectedMaturityList(
    data,
    panelContext.maturityLOVValues
  );
  panelContext.selectedMaturityList = selectedMaturityList;

  //get incoded 64basestream
  let imgData = document
    .getElementById("vimcontainerClipboardImg")
    .getAttribute("src");
  let base64ImgStream = imgData.split("base64,")[1];
  panelContext.base64ImgStream = base64ImgStream;

  appCtxSvc.updateCtx("panelContext", panelContext);
  /*call function to generate report*/
  exports.generateVIMPPTReport(appCtxSvc.getCtx("panelContext"));
};

/**
 * This function return attached issues based on selected maturity in generate report UI
 *  @param {Array} selectedMaturityList - selected maturity list in UI
 */

export let getFilterAttachedIssues = function (
  selectedMaturityList,
  allIssues
) {
  let filteredIssueList = [];
  /*Filter attached issues based on selected maturity*/
  if (selectedMaturityList.indexOf("selectAll") == -1) {
    allIssues.forEach(function (element) {
      if (element != "") {
        let issueModelObject = cdmSvc.getObject(element);
        let propVal = issueModelObject.props.v4b_gen_maturity.dbValues[0];
        if (propVal && selectedMaturityList.indexOf(propVal) != -1) {
          filteredIssueList.push(issueModelObject.uid);
        }
      }
    });
  } else {
    filteredIssueList = allIssues;
  }
  return filteredIssueList;
};
/**
 * This function return maturity selected in generate ppt report UI
 *  @param {Model Object} data - json model object.
 *  @param {Array} maturityLOVValues - Maturity LOV Values.
 *  @return selectedMaturityList
 */

export let getSelectedMaturityList = function (data, inputMaurityList) {
  let selectedMaturityList = [];

  if (
    data.selectAllMaturity.dbValue != "" &&
    data.selectAllMaturity.dbValue === true
  ) {
    selectedMaturityList.push(data.selectAllMaturity.prevDisplayValues[0]);
  } else {
    data.data.listOfValues.forEach(function (element) {
      if (element.dbValue != "" && element.dbValue === true) {
        selectedMaturityList.push(element.prevDisplayValues[0]);
      }
    });
  }
  return selectedMaturityList;
};

/**
 * This function update selection of maturity in generate ppt report UI.
 *  If 'Select All' checkbox selected in UI  then enable other maturity checkboxes
 *  @param {Model Object} data - json model object.
 */

export let updateSelectionOfAllMaturity = function (data) {
  let panelContext = appCtxSvc.getCtx("panelContext");
  let updatedMaturityLOVValues = [];

  data.listOfValues.forEach(function (element) {
    element.dbValue = data.selectAllMaturity.dbValue;
    updatedMaturityLOVValues.push(element);
  });
  panelContext.maturityLOVValues = updatedMaturityLOVValues;
  appCtxSvc.updateCtx("panelContext", panelContext);
  return updatedMaturityLOVValues
};

/**
 * This function download all report files and delete dataset which was created to hold report files temporarily
 * @param {*} soaResponse - soa service response object.
 */
export let downloadReportFilesAndDeleteDataset = function (data, soaResponse) {
  let reportDatasetUID = null;
  let arrayImanFileUID = [];
  let reportName = null;
  for (let ii = 0; ii < soaResponse.ServiceData.updated.length; ii++) {
    let objectType =
      soaResponse.ServiceData.modelObjects[soaResponse.ServiceData.updated[ii]]
        .type;
    if (objectType == "ImanFile") {
      let imanFile = {
        uid: soaResponse.ServiceData.updated[ii],
        type: "ImanFile",
      };
      arrayImanFileUID.push(imanFile);
      reportName =
        soaResponse.ServiceData.modelObjects[
          soaResponse.ServiceData.updated[ii]
        ].props.object_string.dbValues[0];
    } else if (objectType == "V4B_MSPowerPointM") {
      reportDatasetUID = soaResponse.ServiceData.updated[ii];
    }
  }
  if (arrayImanFileUID.length === 0) {
    let messageString = data.i18n.noNamedRefFoundToPPTReportDataset;
    messagingService.showInfo(messageString);
  } else {
    /** Call download file function **/
    VIMSoaUtils.getFileReadTickets(arrayImanFileUID).then(function (
      getFileTicketResponse
    ) {
      fmsUtils.openFile(getFileTicketResponse.tickets[1][0], reportName);

      /* exports.openFile(getFileTicketResponse.tickets[1],reportName).then(function() {
                 
                 if (reportDatasetUID != null) {
                     // Call delete dataset function
                     VIMSoaUtils.deleteObjects( reportDatasetUID ).then(function(deleteDatasetResponse) {
                      var messageString = data.i18n.vimPPTReportGeneratedSuccessfully;
                         // messagingService.showInfo("messageString");
                     });
                 }
               }); */
    });
  }
};

/**
 * This function  collect maturity LOV values and create view model for each to show in VIM Container UI
 * @param {data} - data object.
 */
export let createMaturityCheckBoxes = function (data) {
  let maturityLOVValues = [];
  for (let ii = 0; ii < data.lovValues.length; ii++) {
    let checkbox = {
      displayName: data.lovValues[ii].propDisplayValues.lov_values[0],
      dbValue: '',
      type: "BOOLEAN",
      isRequired: "false",
      isEditable: "true",
      dispValue: data.lovValues[ii].propInternalValues.lov_values[0],
      labelPosition: "PROPERTY_LABEL_AT_RIGHT",
      propName: data.lovValues[ii].propDisplayValues.lov_values[0]
    };
    maturityLOVValues.push(checkbox);
  }
  return maturityLOVValues;
};

/**
 * @function createClipboarPasteEvent - Loads the Clipboard helper Service.
 * Also subscribes to the document paste event
 * And, checks whether browser is IE
 */
export let createClipboarPasteEventForVCONReport = function () {
  $("#VCON-IE-pasteArea").css("width", "0px");
  $("#VCON-IE-pasteArea").css("height", "0px");
  $("#VCON-IE-pasteArea").hide();

  if (
    window.navigator.userAgent.indexOf("MSIE ") > -1 ||
    !!navigator.userAgent.match(/Trident.*rv\:11\./)
  ) {
    // Check if browser is IE
    // Internet Explorer does not enable PASTE option if an editable element is not focused
    // So we are capturing the Ctrl + V key combination and focusing a hidden editable DIV
    document.addEventListener("keydown", function (e) {
      e = e || window.event;
      let key = e.which || e.keyCode; // keyCode detection
      let ctrl = e.ctrlKey ? e.ctrlKey : key === 17 ? true : false; // ctrl detection

      if (86 === key && ctrl) {
        // Check if image was pasted or text
        let clipboardDataValue = window.clipboardData.getData("Text");
        if (clipboardDataValue != null) return; // This means image was not pasted
        $("#VCON-IE-pasteArea").css("display", "block");
        $("#VCON-IE-pasteArea").focus();
      }
    });
  }

  // Check if paste handler is already attached
  let registered = window.jQuery._data(document, "events");
  if (registered && registered.paste) {
    $(document).off("paste", exports.handlePasteEventForVCONReport);
  }
  $(document).on("paste", exports.handlePasteEventForVCONReport); // IE is only accepting Paste event registered by jQuery
  appCtxSvc.updateCtx("isPreviewPictureAdded", "No");
};

/**
 * @function removeClipboarPasteEventForVCONReport - This function de-register the paste event.
 */
export let removeClipboarPasteEventForVCONReport = function () {
  // Check if paste handler is already attached
  let registered = window.jQuery._data(document, "events");
  if (registered && registered.paste) {
    $(document).off("paste", exports.handlePasteEventForVCONReport);
  }
  $(document).off("paste", exports.handlePasteEventForVCONReport);
  appCtxSvc.updateCtx("isPreviewPictureAdded", "No");
};

/**
 * @function removeClipboarPasteEventForVCONReport - This function subscribes to the document paste event
 * And, checks whether browser is IE
 * This event will be used to capture preview image for VIM Container Power Point report.
 */
export let handlePasteEventForVCONReport = function (event) {
  let imgData;
  let items = null;
  let isIE = false;

  if (
    window.navigator.userAgent.indexOf("MSIE ") > -1 ||
    !!navigator.userAgent.match(/Trident.*rv\:11\./)
  ) {
    // Check if browser is IE
    items = window.clipboardData;
    isIE = !0; // Browser is Internet Explorer :(
  } else
    items = (event.clipboardData || event.originalEvent.clipboardData).items;

  $("#VCON-IE-pasteArea").css("display", "none");
  if (!items || 0 === items.length) {
    return;
  }

  let item = null;
  if (isIE) {
    if ("files" in items && items.files.length > 0) item = items.files[0];
  } else if (items.length > 0 && "file" === items[0].kind) item = items[0];

  if (!item) {
    return;
  }

  let reader = new FileReader();
  reader.onload = function (event) {
    imgData = event.target.result;
    document
      .getElementById("vimcontainerClipboardImg")
      .setAttribute("src", imgData);
    $("#vimcontainerClipboardImg").css("max-width", "350px");
    $("#vimcontainerClipboardImg").css("max-height", "350px");
    appCtxSvc.updateCtx("isPreviewPictureAdded", "Yes");
    document.body.click();
  };

  // Start the file reader
  reader.readAsDataURL(isIE ? item : item.getAsFile());
};
export let getListOfCheckBox =  function(panelContext ) {
  let listOfValuestoRe = [];
  _.forEach( panelContext.maturityLOVValues, function( propAttrHolder ) {
      let vmProp = modelPropertyService.createViewModelProperty( propAttrHolder );
      listOfValuestoRe.push( vmProp );
  } );
  return listOfValuestoRe;
};

/**
 * To registered the service
 */
exports = {
  openFile,
  generateVIMPPTReport,
  getVCONUidStr,
  getVIMIssuesUidStr,
  generateVCONPPTReport,
  getFilterAttachedIssues,
  getSelectedMaturityList,
  updateSelectionOfAllMaturity,
  downloadReportFilesAndDeleteDataset,
  createMaturityCheckBoxes,
  createClipboarPasteEventForVCONReport,
  removeClipboarPasteEventForVCONReport,
  handlePasteEventForVCONReport,
  getListOfCheckBox
};

export default exports;

// @<COPYRIGHT>@
// ==================================================
// Copyright 2021.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/**
 *
 * @module js/g4b_TaKoArchiveService
 */

import app from "app";
import soaSvc from "soa/kernel/soaService";
import appCtxSvc from "js/appCtxService";
import messagingService from "js/messagingService";
import localeSvc from "js/localeService";
import eventBus from "js/eventBus";

let exports = {};

export let setProperties = async function (data) {
  let selected =
    data.dataProviders.TaKoKatalogPVDataProvider.selectedObjects[0];

  let isArchieved = true;

  let inputData = {};
  if (selected.type == "G4B_TaKoCatalogPV") {
    if (selected.props.g4b_Archived.dbValues[0] == "0") {
      inputData = {
        objects: [selected],
        attributes: {
          g4b_Archived: {
            stringVec: ["true"],
          },
        },
      };
    }
    if (selected.props.g4b_Archived.dbValues[0] == "1") {
      inputData = {
        objects: [selected],
        attributes: {
          g4b_Archived: {
            stringVec: ["false"],
          },
        },
      };
    }
    await new Promise ( (resolve)=>{
      soaSvc
      .post("Core-2007-01-DataManagement", "setProperties", inputData)
      .then(function () {
        isArchieved = inputData.attributes.g4b_Archived.stringVec[0];
        resolve(isArchieved)
      });
    });
  }

  return isArchieved;
};
export let setPropertiesForPT = function (text) {
  let selected = appCtxSvc.getCtx("selected");

  let inputData = {};
  if (selected.type == "G4B_ProtTypeSpecRevision") {
    if (selected.props.g4b_Archived.dbValues[0] == "0") {
      inputData = {
        objects: [selected],
        attributes: {
          g4b_Archived: {
            stringVec: ["true"],
          },
        },
      };
    }
    if (selected.props.g4b_Archived.dbValues[0] == "1") {
      inputData = {
        objects: [selected],
        attributes: {
          g4b_Archived: {
            stringVec: ["false"],
          },
        },
      };
    }
    soaSvc
      .post("Core-2007-01-DataManagement", "setProperties", inputData)
      .then(function () {});
  } else if (selected.type == "Awb0DesignElement") {
    if (selected.props.g4b_Archived.dbValues[0] == "0") {
      inputData = {
        objects: [
          {
            uid: selected.props.awb0UnderlyingObject.dbValues[0],
            type: selected.props.awb0UnderlyingObjectType.dbValues[0],
          },
        ],
        attributes: {
          g4b_Archived: {
            stringVec: ["true"],
          },
        },
      };
    }
    if (selected.props.g4b_Archived.dbValues[0] == "1") {
      inputData = {
        objects: [
          {
            uid: selected.props.awb0UnderlyingObject.dbValues[0],
            type: selected.props.awb0UnderlyingObjectType.dbValues[0],
          },
        ],
        attributes: {
          g4b_Archived: {
            stringVec: ["false"],
          },
        },
      };
    }
    soaSvc
      .post("Core-2007-01-DataManagement", "setProperties", inputData)
      .then(function (response) {
        console.log("response", response);
        eventBus.publish( 'cdm.relatedModified', {
          relatedModified: [appCtxSvc.ctx.xrtSummaryContextObject],
          refreshLocationFlag: true,
        } );
      });
  }
};

export let getSelectedKatalogPVsForDelete = function (data) {
  let selectedKatalogPVs =
    data.dataProviders.TaKoKatalogPVDataProvider.selectedObjects;
  let selectedKatalogPVsToBeDeleted = [];
  let listOfKatalogPVsToBeDeleted = "";

  for (let inx = 0; inx < selectedKatalogPVs.length; inx++) {
    let deleteObjectsInput = {
      uid: selectedKatalogPVs[inx].uid,
      type: selectedKatalogPVs[inx].type,
    };
    selectedKatalogPVsToBeDeleted.push(deleteObjectsInput);
    listOfKatalogPVsToBeDeleted =
      listOfKatalogPVsToBeDeleted +
      "\n" +
      selectedKatalogPVs[inx].props.g4b_Name.uiValues[0] +
      ",";
  }

  let deleteKatalogPVObjs = {
    objects: selectedKatalogPVsToBeDeleted,
  };

  data.deleteKatalogPVObjs = deleteKatalogPVObjs;

  let message = "";
  let resource =
    app.getBaseUrlPath() + "/i18n/TaKonfigToolsInfoCommandsMessages";
  let localTextBundle = localeSvc.getLoadedText(resource);
  message = localTextBundle.deleteCatalogPVSuccessMsg;
  message = message.replace("{0}", listOfKatalogPVsToBeDeleted);

  let cancelbuttonString = localTextBundle.g4bTakoCancel;
  let deletebuttonString = localTextBundle.g4bRemovePPGButton;
  let buttons = [
    {
      addClass: "btn btn-notify",
      text: cancelbuttonString,
      onClick: function ($noty) {
        $noty.close();
      },
    },
    {
      addClass: "btn btn-notify",
      text: deletebuttonString,
      onClick: function ($noty) {
        $noty.close();
        exports.deleteSelectedKatalogPVs(data);
      },
    },
  ];

  messagingService.showWarning(message, buttons);
};

export let deleteSelectedKatalogPVs = function (data) {
  let deleteKatalogPVObjs = data.deleteKatalogPVObjs;

  soaSvc
    .post("Core-2006-03-DataManagement", "deleteObjects", deleteKatalogPVObjs)
    .then(
      function (response) {
        let deleted = response;
      },
      function (errObj) {
        let msg = errObj;
        errObj && errObj.message && (msg = errObj.message);
        messagingService.showError(msg);
        return;
      }
    );
};

exports = {
  setProperties,
  setPropertiesForPT,
  getSelectedKatalogPVsForDelete,
  deleteSelectedKatalogPVs,
};

export default exports;

// @<COPYRIGHT>@
// ==================================================
// Copyright 2021.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

import $ from "jquery";
import soaSvc from "soa/kernel/soaService";
import appCtxSvc from "js/appCtxService";
import cdm from "soa/kernel/clientDataModel";
import eventBus from "js/eventBus";
import g4bCreatePrototypeSvc from "js/G4B_CreatePrototypeService";
import AwPromiseService from "js/awPromiseService";

let exports = {};

export let checkSubsetBeforeReplay = function () {
  let selectedSubsetName = appCtxSvc.getCtx(
    "selected.props.object_string.dbValues[0]"
  );
  // If Tako 'Rohbau' subset is selected then check if Gesamter Variantenraum Subset is empty of not
  if (selectedSubsetName.indexOf("Rohbau") > -1) {
    exports.checkGesamterVariantenraumSubset();
  } else {
    eventBus.publish("Tako_replayRecipeCommandActionOOTB.event");
  }
};

export let checkGesamterVariantenraumSubset = function () {
  let gesamterVariantenraumSubsetUid = getSubsetUidViaSubsetName(
    "Gesamter Variantenraum"
  );

  let getSubsetContentPromise = getSubsetContent(
    gesamterVariantenraumSubsetUid
  );

  $.when(getSubsetContentPromise).done().then(
    function (ppgsFoundInSubset) {
    if (ppgsFoundInSubset.length <= 0) {
      eventBus.publish("G4B_showReplayMessage");
    } else {
      let ppgStarting4100array = [];
      for (let inx = 0; inx < ppgsFoundInSubset.length; inx++) {
        if (ppgsFoundInSubset[inx].startsWith("4100")) {
          ppgStarting4100array.push(ppgsFoundInSubset[inx]);
        }
      }
      let setPPGpromise = setPPGOnSubset(ppgStarting4100array);
      $.when(setPPGpromise)
        .done()
        .then(function () {
          let currentSubset = appCtxSvc.getCtx(
            "selected.props.awb0UnderlyingObject.dbValues[0]"
          );

          let getPartitionSchemeUidPromise =
            g4bCreatePrototypeSvc.getPartitionSchemeUid(
              "MMG-FB",
              currentSubset
            );
          $.when(getPartitionSchemeUidPromise).done().then(function (partitionScheme) {
            //Replay and set partition scheme 'MMG-FB' "Rohbau" subset
            let replayPromise = g4bCreatePrototypeSvc.replaySubsetRecipe(
              currentSubset,
              partitionScheme,
              true
            );
            $.when(replayPromise).done().then(function () {
              eventBus.publish("cdm.relatedModified", {
                relatedModified: [appCtxSvc.getCtx("selected")],
                refreshLocationFlag: true,
              });
              let eventData1 = {
                retainTreeExpansionStates: true,
              };
              eventBus.publish("acePwa.reset", eventData1);
            });
          });
        });
    }
  });

};

let getSubsetContent = function (subsetUid) {
  let deferred = AwPromiseService.instance.defer();
  let inputData = {
    productObject: {
      uid: subsetUid,
      type: "Cpd0DesignSubsetElement",
    },
    recipe: "",
    revRule: "",
    partition: "",
  };
  soaSvc
    .post("AWS2-2018-05-GepardCore", "getProductContent", inputData)
    .then(function (response) {
      if (response) {
        let ppgsFoundInSubset = [];
        for (let inx = 0; inx < response.vecPartUsageInfo.length; inx++) {
          if (
            ppgsFoundInSubset.indexOf(response.vecPartUsageInfo[inx].ppg) == -1
          ) {
            ppgsFoundInSubset.push(response.vecPartUsageInfo[inx].ppg);
          }
        }
        deferred.resolve(ppgsFoundInSubset);
      }
    });

  return deferred.promise;
};

/**
 * Returns the uid of the Cpd0DesignSubsetElement for the given subset name substring
 * @param {*} subsetNameSubstring
 */
let getSubsetUidViaSubsetName = function (subsetNameSubstring) {
  let subsetUid = "";
  let pciMap = appCtxSvc.getCtx("occmgmtContext").elementToPCIMap;
  for (let key in pciMap) {
    if (key.indexOf("Fgd0DesignSubsetElement") > -1) {
      let objectString = cdm.getObject(key).props.object_string.dbValues[0];
      if (objectString.indexOf(subsetNameSubstring) > -1) {
        //
        subsetUid = cdm.getObject(key).props.awb0UnderlyingObject.dbValues[0];
        break;
      }
    }
  }
  return subsetUid;
};

/**
 * Function to set the input PPG array as 'g4b_TaKoPPGs' property on selected subset
 * @param {integer array} ppgArray
 * @returns
 */

let setPPGOnSubset = function (ppgArray) {
  let deferred = AwPromiseService.instance.defer();
  let currentSubset = appCtxSvc.getCtx(
    "selected.props.awb0UnderlyingObject.dbValues[0]"
  );
  let subsetObj = cdm.getObject(currentSubset);
  let inputData = {
    objects: [subsetObj],
    attributes: {
      g4b_TaKoPPGs: {
        stringVec: ppgArray,
      },
    },
  };
  soaSvc
    .post("Core-2007-01-DataManagement", "setProperties", inputData)
    .then(function (response) {
      deferred.resolve(response);
    });
    return deferred.promise;
};
export let checkPPGOnSebSet = function () {
  let currentSubset = appCtxSvc.getCtx(
    "selected.props.awb0UnderlyingObject.dbValues[0]"
  );
  let subsetObj = cdm.getObject(currentSubset);
  let inputData = {};
  inputData.objects = [subsetObj];
  inputData.attributes = ["g4b_TaKoPPGs"];
  return soaSvc
    .post("Core-2006-03-DataManagement", "getProperties", inputData)
    .then(function (response) {
      let PPGListArr = "";
      PPGListArr =
        response.modelObjects[currentSubset].props.g4b_TaKoPPGs.dbValues;
      appCtxSvc.registerCtx("isPPGAvailabe", false);
      let subsetName =
        response.modelObjects[currentSubset].props.object_name.dbValues[0];
      if (
        subsetName.substring(0, 2).includes("00") ||
        subsetName.substring(0, 2).includes("01") ||
        subsetName.substring(0, 2).includes("02") ||
        subsetName.substring(0, 2).includes("04")
      ) {
        appCtxSvc.updateCtx("isPPGAvailabe", true);
      } else if (PPGListArr.length > 0) {
        //register a ctx for impact analysis of PPG
        appCtxSvc.updateCtx("isPPGAvailabe", true);
      }
      return;
    });
};
exports = {
  checkSubsetBeforeReplay,
  checkGesamterVariantenraumSubset,
  checkPPGOnSebSet,
};

export default exports;

// @<COPYRIGHT>@
// ==================================================
// Copyright 2017.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@



/**
 * 
 * 
 * @module js/ReserveService
 * 
 */
import AwStateService from 'js/awStateService';
import app from 'app';
import _ from 'lodash';
import _cdm from 'soa/kernel/clientDataModel';
import _cmm from 'soa/kernel/clientMetaModel';
import _eventBus from 'js/eventBus';
import browserUtils from 'js/browserUtils';
import _appCtxService from 'js/appCtxService';
import _msgSvc from 'js/messagingService';
import _clipboardSrv from 'js/tcClipboardService';
import _localeService from 'js/localeService';
import AwPromiseService from 'js/awPromiseService';
import _messagingSvc from 'js/messagingService';
import _soaSvc from 'soa/kernel/soaService';
import fmsUtils from 'js/fmsUtils';
import adapterSvc from 'js/adapterService';

var exports = {};


export let getFilteredPUForReservation = function () {
    var filteredPU = [];
    _.forEach(_appCtxService.ctx.mselected, function (modelObject) {
        if (modelObject.props.g4b_KovAResponsible && modelObject.props.g4b_KovAResponsible.dbValues[0] === null) {
            filteredPU.push(modelObject);
        }

    })
    if (filteredPU.length == 0) {
        filteredPU.push("");
    }
    return filteredPU;

};
export let getFilteredPUForRevokation = function () {
    var filteredPU = [];
    _.forEach(_appCtxService.ctx.mselected, function (modelObject) {

        if (_appCtxService.ctx.userSession.props.role_name.dbValue === 'Coordinator') {
            filteredPU.push(modelObject);
        } else if (modelObject.props.g4b_KovAResponsible && modelObject.props.g4b_KovAResponsible.dbValues[0] !== null && modelObject.props.g4b_KovAResponsible.dbValues[0] === _appCtxService.ctx.userSession.props.user.dbValue) {
            filteredPU.push(modelObject);
        }

    })
    if (filteredPU.length == 0) {
        filteredPU.push("");
    }
    return filteredPU;

};
export let getModelObjectFromViewModel = function (viewModelObject) {
    var modelObject = _cdm.getObject(viewModelObject.uid);
    return modelObject;
};
export let refreshPrimaryWrkArea = function () {

    //_eventBus.publish( 'primaryWorkarea.reset', {} );
    _eventBus.publish('occTreeTable.plTable.reload');


};
export let validateEvaluationResult = function (){
    var deferred = AwPromiseService.instance.defer();
    _.forEach(_appCtxService.ctx.mselected, function (modelObject) {

        if (modelObject.props.g4b_KovAEvalResult && modelObject.props.g4b_KovAEvalResult.dbValues[0] !== null) {
            deferred.resolve({
                evaluationResultData: true
            });
            return deferred.promise;
        }

    });
    deferred.resolve({
        evaluationResultData: false
    });
    return deferred.promise;
};
/**
 * Check already reserved part usage
 * 
 * @return {Object} a boolean flag if the current user is not kova responsible for any of the part usages in selection.
 */
export let validateReservation = function () {

    var deferred = AwPromiseService.instance.defer();
    _.forEach(_appCtxService.ctx.mselected, function (modelObject) {

        if (modelObject.props.g4b_KovAResponsible && modelObject.props.g4b_KovAResponsible.dbValues[0] !== null && modelObject.props.g4b_KovAResponsible.dbValues[0] !== _appCtxService.ctx.userSession.props.user.dbValue) {
            deferred.resolve({
                hasAlreadyReservedData: true
            });
            return deferred.promise;
        }

    });
    deferred.resolve({
        hasAlreadyReservedData: false
    });
    return deferred.promise;

    //     var allSelectedPartUsageUid = [];
    //     var deferred = _$q.defer();
    //     _.forEach( _appCtxService.ctx.mselected, function( modelObject ) {

    //         allSelectedPartUsageUid.push( modelObject.props.awb0UnderlyingObject.dbValues[0] );
    //     });

    //     var policyId = _policySvc.register( {
    //         "types": [ {
    //             name: "G4B_KovAPUInfo",
    //             "properties": [ {

    //                 "name": "g4b_KovAResponsible"
    //             } ]
    //         } ]
    //     } );

    //     _dmsSvc.getProperties(allSelectedPartUsageUid, ["G4B_PU2KovAPUInfoRel"]).then(function ( ) {

    //         _policySvc.unregister( policyId );
    //         _.forEach(allSelectedPartUsageUid, function( modelObjectUid ) 
    //         {
    //             var pu  = _cdm.getObject(modelObjectUid);
    //             PUForKovaResponsibles.push( pu );
    //             var puInfoUid = pu.props.G4B_PU2KovAPUInfoRel.dbValues[0];
    //             var puInfoObject = _cdm.getObject( puInfoUid);
    //             var kovaResponsible = puInfoObject.props.g4b_KovAResponsible.dbValues[0];

    //             if( kovaResponsible !== null && kovaResponsible !== _appCtxService.ctx.userSession.props.user.dbValue)
    //             {
    //                return deferred.resolve( {hasAlreadyReservedData :true} );
    //                // return {hasAlreadyReservedData :false};
    //             }
    //         })

    //         //return {hasAlreadyReservedData :true};
    //        return deferred.resolve( {hasAlreadyReservedData :false} )
    //     });
    //     //return deferred.resolve( {hasAlreadyReservedData :true} )
    //    return deferred.promise;
    //     // return {hasAlreadyReservedData :true};
};

export let validateSubsetIsUpdated = function () {


    var deferred = AwPromiseService.instance.defer();

    var multiSelected = _appCtxService.getCtx("mselected");
    var selectedSubset = [];
    for (var i = 0; i < multiSelected.length; i++) {
        if (multiSelected[i].type === 'Fgd0DesignSubsetElement') {
            selectedSubset[0] = multiSelected[i];
            break;
        }
        if (multiSelected[i].type === 'Fgf0PartitionElement') {
            var selectedPartition = multiSelected[i];
            var parent;
            if (_appCtxService.ctx.occmgmtContext.rootElement.type == 'Fgd0DesignSubsetElement') {
                do {
                    parent = _cdm.getObject(selectedPartition.props.awb0Parent.dbValues[0]);
                    selectedPartition = parent;
                }
                while (parent && parent.type !== "Fgd0DesignSubsetElement")
            }
            if (_appCtxService.ctx.occmgmtContext.rootElement.type == 'Fgf0SubsetElement') {
                do {
                    parent = _cdm.getObject(selectedPartition.props.awb0Parent.dbValues[0]);
                    selectedPartition = parent;
                }
                while (parent && parent.type !== "Fgf0SubsetElement")
            }
            selectedSubset[0] = parent;
            break;
        }
    }
    if (_appCtxService.ctx.selected.props.awb0UnderlyingObjectType && _appCtxService.ctx.selected.props.awb0UnderlyingObjectType.dbValues[0] === 'G4B_AnlCtxWrkSetRevision') {
        var elementToPCIMap = Object.keys(_appCtxService.ctx.occmgmtContext.elementToPCIMap);
        if (elementToPCIMap.length > 1) {

            for (var index = 1; index < elementToPCIMap.length; index++) {

                selectedSubset.push(_cdm.getObject(elementToPCIMap[index]));
            }

        }
    }

    //var staleNotFound = true;
    for (var index = 0; index < selectedSubset.length; index++) {

        if (selectedSubset[index].props.g4b_SubsetContentFlag && selectedSubset[index].props.g4b_SubsetContentFlag.dbValues[0] == '0') {
            //staleNotFound = false;
            deferred.resolve({
                isStructureUpdated: false
            });
        }
        // else if ( selectedSubset[index].props.g4b_SubsetContentFlag.dbValues && selectedSubset[index].props.g4b_SubsetContentFlag.dbValues[0] == '1')


    }
    deferred.resolve({
        isStructureUpdated: true
    });

    return deferred.promise;
};
export let getPartition = function () {
    if (_appCtxService.ctx.occmgmtContext.openedElement.type === 'Fgf0SubsetElement' || _appCtxService.ctx.occmgmtContext.openedElement.type === 'Awb0DesignElement') {
        if (_appCtxService.ctx.selected.type === 'Awb0DesignElement' || _appCtxService.ctx.selected.type === 'Fgf0SubsetElement') {
            return "";
        }
        return _appCtxService.ctx.selected;
    }
};

export let getSubsetFromPU = function () {

    if (_appCtxService.ctx.selected.type === 'Fgb0PartUsage') {
        var selectedPartUsage = _appCtxService.ctx.selected;
        var parent;
        do {
            parent = _cdm.getObject(selectedPartUsage.props.awb0Parent.dbValues[0]);
            selectedPartUsage = parent;
        }
        while (parent && parent.type !== "Fgd0DesignSubsetElement")

        return parent;
    }

};

export let getParentElementForPartition = function()
{
    if (_appCtxService.ctx.selected.props.awb0UnderlyingObjectType && _appCtxService.ctx.selected.props.awb0UnderlyingObjectType.dbValues[0] === 'G4B_AnlCtxWrkSetRevision') {
        var elementToPCIMap = Object.keys(_appCtxService.ctx.occmgmtContext.elementToPCIMap);
        if (elementToPCIMap.length > 1) {
            return _cdm.getObject(elementToPCIMap[1]);
        }
        return _appCtxService.ctx.selected;
    }

    if (_appCtxService.ctx.occmgmtContext.openedElement.type === 'Fgf0SubsetElement') {
        return _appCtxService.ctx.occmgmtContext.openedElement;

    } else if (_appCtxService.ctx.occmgmtContext.topElement.type === 'Fgf0SubsetElement') {

        return _appCtxService.ctx.occmgmtContext.topElement;

    } else {

        var selectedPartition = _appCtxService.ctx.selected;
        var parent;
        do {
            parent = _cdm.getObject(selectedPartition.props.awb0Parent.dbValues[0]);
            selectedPartition = parent;
        }
        while (parent && parent.type !== "Fgd0DesignSubsetElement")

        return parent;
    }
};

export let getParentAndPartitionMap = function () {

    // var partition1 = [{
    //     "type" : "Fgf0PartitionElement",
    //     "uid"  :  "SR::N::Fgf0PartitionElement..RcqJErrbLBR6dD,PR:SR::N::Cpd0WorksetLine..5.DdvErPcvt3dBkD.aJclVAT$t3djoA..1/94pJoZncLBR6dD/,,AWB4GB"
    // }];
    // var subset1 = [{
    //     "type" : "Fgd0DesignSubsetElement",
    //     "uid"  : "SR::N::Fgd0DesignSubsetElement..94pJoZncLBR6dD,PR:SR::N::Cpd0WorksetLine..5.DdvErPcvt3dBkD.aJclVAT$t3djoA..1/,,AWB4GB"
    // }];
    // var partition2 = [{
    //     "type" : "Fgf0PartitionElement",
    //     "uid"  :  "SR::N::Fgf0PartitionElement..RcqJErrbLBR6dD,PR:SR::N::Cpd0WorksetLine..5.DdvErPcvt3dBkD.aJclVAT$t3djoA..1/9kuJoZncLBR6dD/,,AWB4GB"
    // }];
    // var subset2 = [{
    //     "type" : "Fgd0DesignSubsetElement",
    //     "uid"  : "SR::N::Fgd0DesignSubsetElement..9kuJoZncLBR6dD,PR:SR::N::Cpd0WorksetLine..5.DdvErPcvt3dBkD.aJclVAT$t3djoA..1/,,AWB4GB"
    // }];
     var allSubsetsAndPartition = _getParentElementForPartition();
    // var allSubsets = allSubsetsAndPartition[0];
    // var allPartition = allSubsetsAndPartition[1];
    // var parentPartitionMap = [];
    // var mapIndex = 0;
    // for ( var count=0; count < allSubsets.length; count++) 
    // {

    //     parentPartitionMap[mapIndex] = [{"type": allSubsets[count].type,"uid":allSubsets[count].uid}];
    //     if( count == 0 && allPartition.length > 0)
    //     {
    //         parentPartitionMap[mapIndex+1] = [{"type": allPartition[count].type, "uid":allPartition[count].uid}];
    //     }else{
    //         parentPartitionMap[mapIndex+1] = [""];
    //     }
       
    //     mapIndex = mapIndex + 2;

    // }
    //var parentPartitionMapOld = [subset1,partition1,subset2,partition2];
    //parentPartitionMap.push(subset);
    //parentPartitionMap.push(partition);
    //parentPartitionMap[subset] = partition;
    // var parentPartitionMap = {
        
    //     subset:partition
    // }
    
   // return parentPartitionMap;
   return allSubsetsAndPartition;
};
var _getParentElementForPartition = function () {

    var subsets = [];
    var partition = [];
    if (_appCtxService.ctx.selected.props.awb0UnderlyingObjectType && _appCtxService.ctx.selected.props.awb0UnderlyingObjectType.dbValues[0] === 'G4B_AnlCtxWrkSetRevision') {
        var elementToPCIMap = Object.keys(_appCtxService.ctx.occmgmtContext.elementToPCIMap);
        // if (elementToPCIMap.length > 1) {
        //     return _cdm.getObject(elementToPCIMap[1]);

        // }
        for( var counter = 1; counter < elementToPCIMap.length ; counter++ )
        {
            subsets.push(_cdm.getObject(elementToPCIMap[counter]));
        }
       // return _appCtxService.ctx.selected;
    }

    if (_appCtxService.ctx.occmgmtContext.openedElement.type === 'Fgf0SubsetElement') {
        
        //return _appCtxService.ctx.occmgmtContext.openedElement;
        subsets.push(_appCtxService.ctx.occmgmtContext.openedElement);

    } else if (_appCtxService.ctx.occmgmtContext.topElement.type === 'Fgf0SubsetElement') {

        subsets.push(_appCtxService.ctx.occmgmtContext.topElement);
       // return _appCtxService.ctx.occmgmtContext.topElement;


    } else if ( _appCtxService.ctx.selected.type == 'Fgf0PartitionElement' ) {

        var selectedPartition = _appCtxService.ctx.selected;
        partition.push(selectedPartition);
        var parent;
        do {
            parent = _cdm.getObject( selectedPartition.props.awb0Parent.dbValues[0] );
            selectedPartition = parent;
        }
        while ( parent && parent.type !== "Fgd0DesignSubsetElement" )
        subsets.push(parent);
        
        //return parent;
    }

    return [subsets,partition];

};

export let openInNewTab = function (vmo) {

    // if( (_appCtxService.ctx.selected.type === 'G4B_KovAResult' && _appCtxService.ctx.pselected && _appCtxService.ctx.pselected.type === "Fgb0PartUsage") || _appCtxService.ctx.selected.type === 'Fgb0PartUsage' )
    // {
    //     var partUsagesToRefresh = [];

    //     if(localStorage.get('kovaResultUpdated'))
    //     {
    //         partUsagesToRefresh.push(JSON.stringify(JSON.parse(localStorage.get('kovaResultUpdated'))));
    //     }

    //     if( _appCtxService.ctx.selected.type === 'G4B_KovAResult' && _appCtxService.ctx.pselected && _appCtxService.ctx.pselected.type === "Fgb0PartUsage")
    //     {
    //         partUsagesToRefresh.push(JSON.stringify(_appCtxService.ctx.pselected));

    //     }else if(_appCtxService.ctx.selected.type === 'Fgb0PartUsage')
    //     {
    //         partUsagesToRefresh.push(JSON.stringify(_appCtxService.ctx.selected));
    //     }

    //     localStorage.publish( 'kovaResultUpdated',partUsagesToRefresh );


    // }

    if (!browserUtils.isIE) {

        var stateSvc = AwStateService.instance;

        var params = null;
        if (vmo && vmo.type == "Awp0XRTObjectSetRow" && vmo.props.awp0Target && vmo.props.awp0Target.dbValue) {
            params = {
                "uid": vmo.props.awp0Target.dbValue,
                "s_uid": ""
            }
        } else {
            params = {
                "uid": vmo.uid,
                "s_uid": ""
            }
        }

        // if( openInEdit ) {

        //     params.edit =  true; 
        // }
        // s_uid is not required for creating unique url for Model Object
        if (stateSvc) {


            var url = browserUtils.getBaseURL() +
                stateSvc.href("com_siemens_splm_clientfx_tcui_xrt_showObject", params, {
                    inherit: false
                });
            var openLink = window.open('', '_blank');
            openLink.location = url;
        }

        //  }


    } else {

        var $state = AwStateService.instance;
        var showObject = 'com_siemens_splm_clientfx_tcui_xrt_showObject';
        var toParams = {};
        var options = {};

        if (vmo && vmo.type == "Awp0XRTObjectSetRow" && vmo.props.awp0Target && vmo.props.awp0Target.dbValue) {
            toParams.uid = vmo.props.awp0Target.dbValue;
        } else {
            toParams.uid = vmo.uid;
        }

        // toParams.cmdId = 'Awp0StartEdit';
        options.inherit = false;

        $state.go(showObject, toParams, options);
    }

};

var _openNewObjectInEditMode = function( data )
{
    

    
    if ( data.defaultSelecteds )
{
    _appCtxService.ctx.occmgmtContext.pwaSelectionModel.selectNone();
    if( data.defaultSelecteds.length > 1 )
    {
        _appCtxService.ctx.occmgmtContext.pwaSelectionModel.setMultiSelectionEnabled( true );
    }else{
        _appCtxService.ctx.occmgmtContext.pwaSelectionModel.setMultiSelectionEnabled( false );
    }
    
    _appCtxService.ctx.occmgmtContext.pwaSelectionModel.setSelection(data.defaultSelecteds);

    }
    
    var resource = app.getBaseUrlPath() + '/i18n/kovaCmdMessages';
    var localeTextBundle = _localeService.getLoadedText(resource);

    if (_appCtxService.ctx.selected.props.g4b_KovAEvalResult && _appCtxService.ctx.selected.props.g4b_KovAEvalResult.dbValues[0] === data.createdObj[0]) {
        _msgSvc.showInfo(localeTextBundle.EvalResultAlreadyExist);
    } else {

        _msgSvc.showInfo(localeTextBundle.CreateEvaluationResult);
    }

    var vmo = _cdm.getObject(data.createdObj);
    var isSelectedObjectSupportInContext = false;
    var isCreatedObjectSupportInContext = false;
    var openInEdit = true;


    var stateSvc = AwStateService.instance;
    if (stateSvc && stateSvc.params) {
        var params = stateSvc.params;
        if (params.uid) {
            var openedObjectUid = params.uid;
            var openObjecyVmo = _cdm.getObject(openedObjectUid);

            if (openObjecyVmo && _cmm.isInstanceOf('Cpd0CollaborativeDesign', openObjecyVmo.modelType)) {
                isSelectedObjectSupportInContext = true;
            }
        }

    }
    // }

    if (isSelectedObjectSupportInContext) {
        if (_cmm.isInstanceOf('ChangeNoticeRevision', vmo.modelType) ||
            _cmm.isInstanceOf('Fnd0AbstractMarkupSpace', vmo.modelType)) {
            isCreatedObjectSupportInContext = true;
        }
        if (isCreatedObjectSupportInContext) {
            openInEdit = false;
        }
    }

    // if( openInEdit ) {
    //     _showObjectCommandHandler.execute( vmo, null, true );
    // }
    var param = {
        "uid": data.createdObj,
        "s_uid": ""
    }
    if (openInEdit) {

        param.edit = true;
    }
    // s_uid is not required for creating unique url for Model Object
    var url = browserUtils.getBaseURL() +
        stateSvc.href("com_siemens_splm_clientfx_tcui_xrt_showObject", param, {
            inherit: false
        });
    var openLink = null;
    if (!browserUtils.isIE) {
        openLink = window.open('', '_blank');
    } else {
        openLink = window.open('', '_self');
    }

openLink.location = url;
}

/**
 * Open Object in Edit Mode.
 *
 * @param {String} newObjectUid - object to open
 */
export let openNewObjectInEditMode = function (data) {

    _appCtxService.updatePartialCtx( 'aceActiveContext.context.configContext.expandedNodes', );
    var currentPartitionScheme = _appCtxService.ctx.occmgmtContext.productContextInfo.props.fgf0PartitionScheme.uiValues[0];
     if((currentPartitionScheme == "KovA Responsible" ||  currentPartitionScheme == "KovA Verantwortliche" || currentPartitionScheme ==  "KovA BI" || currentPartitionScheme ==  "KovA Meilenstein" || currentPartitionScheme == "KovA Milestone") &&_appCtxService.ctx.preferences.G4B_partitionUpdateMode && _appCtxService.ctx.preferences.G4B_partitionUpdateMode[0] == 'Auto')
     {
        refreshTree();
        var subscription = _eventBus.subscribe('appCtx.update', function( eventData ){
            
            if( eventData.name == "occmgmtContext" && eventData.target == "treeLoadingInProgress" && eventData.value.occmgmtContext.treeLoadingInProgress == false)
            {
                _eventBus.unsubscribe(subscription);
                _openNewObjectInEditMode(data);
    }
        });
     }
     else{
         _openNewObjectInEditMode(data);
     }
    

    

};

export let downloadFile = function( response )
{
    if(_appCtxService.ctx.selected.type == 'Fgd0DesignSubsetElement'){
        return response.ServiceData;
    }
    else{
    var responseUID = response.kovaresponseobjects.FileTicket[0].split('~');
    if( responseUID != null && responseUID.length > 0 )
    {
        _soaSvc.post('Core-2007-09-DataManagement', 'loadObjects', {
            uids: responseUID
        }).then(function () {
    
            var imanFileModelObject = _cdm.getObject( responseUID[1] );
                            var files = [ imanFileModelObject ];
                            //fmsSvc.getFileReadTickets( files ).then();
    
                            var inputDataToDownloadFile = {
                                "files": files
                            };
        _soaSvc.post("Core-2006-03-FileManagement", "getFileReadTickets", inputDataToDownloadFile).then(function (response) {
            
            fmsUtils.openFile( response.tickets[1][0] ,imanFileModelObject.props.original_file_name.dbValues[0]);
    
            var inputDataToDeleteDataset = {
                "objects": [{
                    "uid": responseUID[0],
                    "type": "Zip"
                }]
            };
             _soaSvc.post("Core-2006-03-DataManagement", "deleteObjects", inputDataToDeleteDataset).then(function (response) {
                    var deleted = response;
                },
                function (errObj) {
                    var msg = errObj;
                    errObj && errObj.message && (msg = errObj.message);
                    //_messagingService.showError(msg);
                    return;
                });
    
            //return response;
                                }); 
        })
    }
    

                           
    //var fileTicket = response.deleted[0];
    //fmsUtils.openFile("27b767f6efe14cf7051a557eb9daa0cc31632c44910e1176be7a80306e9cecffF1004F00000000000021c461f3709db4019eddT2022%2f01%2f28+14%3a00%3a00-1274962211+++++++++++++++++++++shalini+++++++++++++++++++++++++c6647dcbe93a1d8e869bfd69c51b9b5chttp%3a%2f%2fdecgnvslx001%3a13710%3b%2fOnepagerreports.zip","Onepagerreports.zip");

    }
};

export let refreshTree =  function ()
{
    //_eventBus.publish( 'primaryWorkarea.reset' );
    _appCtxService.updatePartialCtx( 'aceActiveContext.context.configContext.expandedNodes', );
    var currentPartitionScheme = _appCtxService.ctx.occmgmtContext.productContextInfo.props.fgf0PartitionScheme.uiValues[0];
     if(currentPartitionScheme == "KovA Responsible" ||  currentPartitionScheme == "KovA Verantwortliche" || currentPartitionScheme ==  "KovA BI" || currentPartitionScheme ==  "KovA Meilenstein" || currentPartitionScheme == "KovA Milestone")
     {
        if( _appCtxService.ctx.occmgmtContext && _appCtxService.ctx.occmgmtContext.pwaSelectionModel )
        {
            _appCtxService.ctx.occmgmtContext.pwaSelectionModel.selectNone();
        }
        _appCtxService.updatePartialCtx( 'aceActiveContext.context.configContext.startFreshNavigation', true);
    _eventBus.publish('acePwa.reset', {

        retainTreeExpansionStates: true

    });
     }
    

    
    //_eventBus.publish('occTreeTable.plTable.clientRefresh');
    //_eventBus.publish( 'occmgmt4gf.recipeUpdated' );
}

export let refreshTreeViaJS = function( )
{
   

if( _appCtxService.ctx.occmgmtContext && _appCtxService.ctx.occmgmtContext.pwaSelectionModel )
{
    _appCtxService.ctx.occmgmtContext.pwaSelectionModel.selectNone();
}

_appCtxService.updatePartialCtx( 'aceActiveContext.context.configContext.startFreshNavigation', true);
_eventBus.publish( 'primaryWorkarea.reset' );


/**TCVis Appconnect issue

                    Publish recipe updated event to trigger PWA event

                */

               _eventBus.publish( 'occmgmt4gf.recipeUpdated' );
               if(_appCtxService.ctx.selected.type === 'Fgb0PartUsage' || _appCtxService.ctx.selected.type === 'Fgf0PartitionElement'){
                _appCtxService.ctx.occmgmtContext.pwaSelectionModel.setSelection(_appCtxService.ctx.selected);
               }

};

export let refreshCurrentObjectViaJS = function()
{
    _eventBus.publish( 'cdm.relatedModified', {
        relatedModified : 
        [
            _appCtxService.ctx.selected
        ],
        refreshLocationFlag: true,
        relations: "",
    
     } );
};

export let refreshSublocationDataJS = function ( data, objForSelectionRevert ) {

    
    _appCtxService.updatePartialCtx( 'aceActiveContext.context.configContext.expandedNodes', );
        var currentPartitionScheme = _appCtxService.ctx.occmgmtContext.productContextInfo.props.fgf0PartitionScheme.uiValues[0];
    if( (currentPartitionScheme == "KovA Responsible" ||  currentPartitionScheme == "KovA Verantwortliche" || currentPartitionScheme ==  "KovA BI" || currentPartitionScheme ==  "KovA Meilenstein" || currentPartitionScheme == "KovA Milestone") &&_appCtxService.ctx.preferences.G4B_partitionUpdateMode && _appCtxService.ctx.preferences.G4B_partitionUpdateMode[0] == 'Auto' )
    {
        
         if(currentPartitionScheme == "KovA Responsible" ||  currentPartitionScheme == "KovA Verantwortliche" || currentPartitionScheme ==  "KovA BI" || currentPartitionScheme ==  "KovA Meilenstein" || currentPartitionScheme == "KovA Milestone")
         {
            if( _appCtxService.ctx.occmgmtContext && _appCtxService.ctx.occmgmtContext.pwaSelectionModel )
            {
                _appCtxService.ctx.occmgmtContext.pwaSelectionModel.selectNone();
            }
            _appCtxService.updatePartialCtx( 'aceActiveContext.context.configContext.startFreshNavigation', true);
        _eventBus.publish('acePwa.reset', {
    
            retainTreeExpansionStates: true
    
        });
         } 

         _appCtxService.ctx.occmgmtContext.pwaSelectionModel.setMultiSelectionEnabled( false );

         var subscription = _eventBus.subscribe('appCtx.update', function( eventData ){
            
            if( eventData.name == "occmgmtContext" && eventData.target == "treeLoadingInProgress" && eventData.value.occmgmtContext.treeLoadingInProgress == false)
            {
                _eventBus.unsubscribe(subscription);
                _appCtxService.ctx.occmgmtContext.pwaSelectionModel.setSelection(objForSelectionRevert);
            }
        });
    }
    else {

        if ( objForSelectionRevert )
    {
        _appCtxService.ctx.occmgmtContext.pwaSelectionModel.selectNone();
        if( objForSelectionRevert.length > 1 )
        {
            _appCtxService.ctx.occmgmtContext.pwaSelectionModel.setMultiSelectionEnabled( true );
        }else{
            _appCtxService.ctx.occmgmtContext.pwaSelectionModel.setMultiSelectionEnabled( false );
        }
        
        _appCtxService.ctx.occmgmtContext.pwaSelectionModel.setSelection(objForSelectionRevert);

    }
    
    if (_appCtxService.ctx.occmgmtContext && _appCtxService.ctx.occmgmtContext.openedElement.type === 'Fgf0SubsetElement') {
        //_eventBus.publish( 'primaryWorkarea.reset', {} );
        //_eventBus.publish( 'occTreeTable.plTable.reload' ); 
        if (_appCtxService.ctx.selected.type === 'Fgb0PartUsage' || _appCtxService.ctx.selected.type === 'Fgf0SubsetElement') {
            //_eventBus.publish( 'cdm.relatedModified', {
            //    relatedModified : 
            //    [
            //        _appCtxService.ctx.selected
            //    ],
            //    refreshLocationFlag: true,
            //    relations: "",

            //} )
            var selectedUids = [];
            if (_appCtxService.ctx.mselected.length > 0) {
                for (let inx = 0; inx < _appCtxService.ctx.mselected.length; inx++) {
                    selectedUids.push(_appCtxService.ctx.mselected[inx].uid);
                    
                }
            } else {
                selectedUids = _appCtxService.ctx.selected.uid;
            }
            _soaSvc.post('Core-2007-09-DataManagement', 'loadObjects', {
                uids: selectedUids
            }).then(function () {

                // _eventBus.publish('cdm.relatedModified', {
                //     relatedModified:
                //         [
                //             _cdm.getObject( _appCtxService.ctx.selected.uid )

                //         ],
                //     refreshLocationFlag: true,
                //     relations: "",

                // });
                var selectedObjects = [];
                for (let inx = 0; inx < selectedUids.length; inx++) {
                    selectedObjects.push(_cdm.getObject(selectedUids[inx]))
                }
                _eventBus.publish('plTable.relatedModified', {
                    relatedModified: //[
                        //_cdm.getObject(_appCtxService.ctx.selected.uid)
                        selectedObjects
                    //]
                    ,
                    refreshLocationFlag: true,
                    relations: "",

                });
                _eventBus.publish('cdm.relatedModified', {
                    relatedModified: //[
                       // _cdm.getObject(_appCtxService.ctx.selected.uid)
                       selectedObjects
                    //]
                    ,
                    refreshLocationFlag: true,
                    relations: "",

                });

                _eventBus.publish('occTreeTable.plTable.clientRefresh');
            });
        } else if (_appCtxService.ctx.selected.type === 'G4B_KovAResult' && _appCtxService.ctx.pselected.type === 'Fgb0PartUsage') {

            //_eventBus.publish( 'cdm.relatedModified', {
            //    relatedModified : 
            //    [
            //        _appCtxService.ctx.pselected
            //    ],
            //    refreshLocationFlag: true,
            //    relations: "",

            //} )
            var selectedUids = [];
            if (_appCtxService.ctx.mselected.length > 0) {
                for (let inx = 0; inx < _appCtxService.ctx.mselected.length; inx++) {
                    selectedUids.push(_appCtxService.ctx.mselected[inx].uid)
                }
            } else {
                selectedUids = _appCtxService.ctx.pselected.uid;
            }
            selectedUids.push( _appCtxService.ctx.pselected.uid );
            _soaSvc.post('Core-2007-09-DataManagement', 'loadObjects', {
                uids: selectedUids
            }).then(function () {

                _eventBus.publish('cdm.relatedModified', {
                    relatedModified:
                        [
                            _cdm.getObject(_appCtxService.ctx.pselected.uid),_cdm.getObject(_appCtxService.ctx.selected.uid)

                        ],
                    refreshLocationFlag: true,
                    relations: "",

                });

                _eventBus.publish('plTable.relatedModified', {
                    relatedModified: [
                        _cdm.getObject(_appCtxService.ctx.pselected.uid),_cdm.getObject(_appCtxService.ctx.selected.uid)

                    ],
                    refreshLocationFlag: true,
                    relations: "",

                });

                _eventBus.publish('occTreeTable.plTable.clientRefresh');
            });
        }


    } else {
        //_eventBus.publish( 'primaryWorkarea.reset', {} );
        //_eventBus.publish( 'occTreeTable.plTable.reload' );
        if (_appCtxService.ctx.selected.type === 'G4B_KovAResult' && _appCtxService.ctx.pselected.type === 'Fgb0PartUsage') {

            //  _eventBus.publish( 'cdm.relatedModified', {
            //      relatedModified : 
            //      [
            //          _appCtxService.ctx.pselected
            //      ],
            //      refreshLocationFlag: true,
            //      relations: "",

            //  } )
            var selectedUids = [];
            selectedUids.push(_appCtxService.ctx.selected.uid);
            selectedUids.push(_appCtxService.ctx.pselected.uid);
            _soaSvc.post('Core-2007-09-DataManagement', 'loadObjects', {
                uids: selectedUids
            }).then(function () {
                _eventBus.publish('plTable.relatedModified', {
                    relatedModified: [
                        _cdm.getObject(_appCtxService.ctx.pselected.uid),_cdm.getObject(_appCtxService.ctx.pselected.uid)
    
                    ],
                    refreshLocationFlag: true,
                    relations: "",
    
                });
                 _eventBus.publish( 'cdm.relatedModified', {
                     relatedModified : 
                     [
                        _cdm.getObject(_appCtxService.ctx.pselected.uid),_cdm.getObject(_appCtxService.ctx.pselected.uid)
                     ],
                     refreshLocationFlag: true,
                     relations: "",
    
                 } )
    
                _eventBus.publish('occTreeTable.plTable.clientRefresh');
            })
            

        } else if (_appCtxService.ctx.selected.type === 'Fgb0PartUsage') {
            //_eventBus.publish( 'cdm.relatedModified', {
            //    relatedModified : 
            //    [
            //        _appCtxService.ctx.selected
            //    ],
            //    refreshLocationFlag: true,
            //    relations: "",

            //} )
            var selectedUids = [];
            if (_appCtxService.ctx.mselected.length > 0) {
                for (let inx = 0; inx < _appCtxService.ctx.mselected.length; inx++) {
                    selectedUids.push(_appCtxService.ctx.mselected[inx].uid)
                }
            } else {
                selectedUids = _appCtxService.ctx.selected.uid;
            }
            _soaSvc.post('Core-2007-09-DataManagement', 'loadObjects', {
                uids: selectedUids
            }).then(function () {
                _eventBus.publish('plTable.relatedModified', {
                    relatedModified: [
                        _cdm.getObject(_appCtxService.ctx.selected.uid)

                    ],
                    refreshLocationFlag: true,
                    relations: "",

                });
                _eventBus.publish( 'cdm.relatedModified', {
               relatedModified : 
               [
                   _appCtxService.ctx.selected
               ],
               refreshLocationFlag: true,
               relations: "",

            } )
            })


            _eventBus.publish('occTreeTable.plTable.clientRefresh');
        }
    }
    }

    // }
};


export let createInput = function ( data ) {

    if( _appCtxService.ctx.selected.type === 'Fgb0PartUsage' || (_appCtxService.ctx.pselected && _appCtxService.ctx.pselected.type === 'Fgb0PartUsage'))
    {
        //var defaultSelectedPU = _appCtxService.ctx.selected.props.awb0UnderlyingObject.dbValues[0];
        var defaultSelectedPUs = [];
    if( _appCtxService.ctx.pselected && _appCtxService.ctx.pselected.type === 'Fgb0PartUsage' )
    {
        defaultSelectedPUs.push( _appCtxService.ctx.pselected );
        data.defaultSelecteds = [_appCtxService.ctx.pselected];
    }else{
        defaultSelectedPUs = _appCtxService.ctx.mselected;
        data.defaultSelecteds = _appCtxService.ctx.mselected;
    }
        
        var puTobeForcedSelected = [];
        //data.defaultSelected = _appCtxService.ctx.selected.uid;
        
        var loadedVMObjs = _appCtxService.ctx.occmgmtContext.vmc.loadedVMObjects;

        for (var index = 0; index < loadedVMObjs.length; index++) {
            
            if( loadedVMObjs[index].modelType && loadedVMObjs[index].modelType.name && loadedVMObjs[index].modelType.name == 'Fgb0PartUsage')
            {
                for( var selectedPUCount = 0; selectedPUCount < defaultSelectedPUs.length; selectedPUCount++)
                {
                    if( loadedVMObjs[index].props.awb0UnderlyingObject.dbValue == defaultSelectedPUs[selectedPUCount].props.awb0UnderlyingObject.dbValues[0] )
                    {
                        puTobeForcedSelected.push( loadedVMObjs[index].uid);
                    }
                }
                // if( loadedVMObjs[index].props.awb0UnderlyingObject.dbValue == defaultSelectedPU )
                // {
                //     puTobeForcedSelected.push( loadedVMObjs[index].uid);
                // }
            }
        }


        _appCtxService.ctx.occmgmtContext.pwaSelectionModel.setMultiSelectionEnabled(true);
        _appCtxService.ctx.occmgmtContext.pwaSelectionModel.setSelection( puTobeForcedSelected );
    }
    
    if (_appCtxService.ctx.occmgmtContext.openedElement.type === 'Fgf0SubsetElement') {
        return _appCtxService.ctx.occmgmtContext.openedElement;

    } else if (_appCtxService.ctx.occmgmtContext.topElement.type === 'Fgf0SubsetElement') {

        return _appCtxService.ctx.occmgmtContext.topElement;
    } else if (_appCtxService.ctx.selected.type === 'Fgb0PartUsage') {
        var selectedPartUsage = _appCtxService.ctx.selected;
        var parent;
        do {
            parent = _cdm.getObject(selectedPartUsage.props.awb0Parent.dbValues[0]);
            selectedPartUsage = parent;
        }
        while (parent && parent.type !== "Fgd0DesignSubsetElement")

        return parent;
    } else if (_appCtxService.ctx.selected.type === 'G4B_KovAResult') {
        var selectedPartUsageLocal = _appCtxService.ctx.pselected;
        var parentLocal;
        do {

            parentLocal = _cdm.getObject(selectedPartUsageLocal.props.awb0Parent.dbValues[0]);
            selectedPartUsageLocal = parentLocal;
        }
        while (parentLocal && parentLocal.type !== "Fgd0DesignSubsetElement")
        return parentLocal;
    }
};

export let clearClipboardJS = function () {
    _clipboardSrv.setContents(null);
};

export let handleFailure = function (response) {
    if (response.partialErrors && response.partialErrors.length > 0) {
        for (var inx = 0; inx < response.partialErrors.length; inx++) {
            _messagingSvc.showError(response.partialErrors[inx].errorValues[0].message);
        }
    }
};

export let handlingFailure = function (response) {
    if (response.ServiceData.partialErrors && response.ServiceData.partialErrors.length > 0) {
        for (var inx = 0; inx < response.ServiceData.partialErrors.length; inx++) {
            for (var err = 0; err < response.ServiceData.partialErrors[inx].errorValues.length; err++) {
                if (response.ServiceData.partialErrors[inx].errorValues[err].code !== 515111) {
                    _messagingSvc.showError(response.ServiceData.partialErrors[inx].errorValues[err].message);
                }
            }
        }
    }
};

export let lockUnlockSubsetViaJS = function ( currentState )
{
    var toBeStatus = "";
        if( currentState ){
           
        if( currentState == 'KovA Lock Subset' || currentState == 'KovA Subset gesperrt')
        {
            toBeStatus = "Set_KovA_Subset_Unlock"
        }
        else if (currentState == 'KovA Unlock Subset' || currentState == 'KovA Subset aktualisierbar' )
        {
            toBeStatus = "Set_KovA_Subset_Lock"
        }
        }else {
            toBeStatus = "Set_KovA_Subset_Lock"
        }
        
        var selected = _appCtxService.getCtx("selected");
        var inputData = {
            startImmediately: true,
            observerKey:"", 
            name:selected.props.object_string.dbValues[0],
            subject:"",
            description:"",
            contextData:{
                processTemplate:toBeStatus,
                attachmentCount:1,
                attachments:[selected.props.awb0UnderlyingObject.dbValues[0]],
                attachmentTypes:[1]
            }
            
        }

       

        _soaSvc.post("Workflow-2008-06-Workflow", "createInstance", inputData).then(
            function () { 
              //console.log("in soa create instance");
            }
        );
    
}

export let reservePartitions = function (){
    var PEsSelected = _appCtxService.getCtx("mselected");
    var totalPE = [];
    for (var i = 0; i < PEsSelected.length; i++){
        if(_appCtxService.ctx.occmgmtContext.openedElement.type == 'Awb0DesignElement' && PEsSelected[i].type == 'Fgf0PartitionElement' && PEsSelected[i].props.awb0Parent.dbValues[0] !== _appCtxService.ctx.occmgmtContext.rootElement.uid && PEsSelected[i].props.awb0UnderlyingObjectType.dbValues == 'G4B_PartitionMMGFB'){
            totalPE.push(PEsSelected[i]);
        }
    }
    if(totalPE.length > 0){
        var finalPartitionElements = [];
        for(var index = 0; index < totalPE.length; index++){
            finalPartitionElements.push({
                type: totalPE[index].type,
                uid: totalPE[index].uid
            });
        }
    }
    return finalPartitionElements;
}


export let vecPartitions = function () {
    var finalPartElements = [];
    var selectedPE = _appCtxService.getCtx("mselected");
    for (var i = 0; i < selectedPE.length; i++) {
        if ((selectedPE[i].props.object_string.dbValues[0].indexOf("ToDo List") > -1 || selectedPE[i].props.object_string.dbValues[0].indexOf("ToDo Liste") > -1) && selectedPE[i].type == 'Fgf0PartitionElement' && selectedPE[i].props.awb0UnderlyingObjectType.dbValues == 'G4B_PartnKovAPI' && _appCtxService.ctx.userSession.props.role_name.dbValue !== 'Integrator') {
            finalPartElements.push(selectedPE[i]);
        }
        if ((selectedPE[i].props.object_string.dbValues[0].indexOf("ToDo List") > -1 || selectedPE[i].props.object_string.dbValues[0].indexOf("ToDo Liste") > -1) && selectedPE[i].type == 'Fgf0PartitionElement' && selectedPE[i].props.awb0UnderlyingObjectType.dbValues == 'G4B_PartnKovAPI' && _appCtxService.ctx.userSession.props.role_name.dbValue == 'Integrator' && selectedPE[i].props.object_string.dbValues[0].indexOf(_appCtxService.ctx.user.props.user_name.dbValue) > -1) {
            finalPartElements.push(selectedPE[i]);
        }
        if (selectedPE[i].type == 'Fgf0PartitionElement' && selectedPE[i].props.awb0Parent.dbValues[0] !== _appCtxService.ctx.occmgmtContext.rootElement.uid && selectedPE[i].props.awb0UnderlyingObjectType.dbValues == 'G4B_PartitionMMGFB') {
            finalPartElements.push(selectedPE[i]);
        }
    }
    if(finalPartElements.length > 0){
        var partitionElements = [];
        for(var index = 0; index < finalPartElements.length; index++){
            partitionElements.push({
                type: finalPartElements[index].type,
                uid: finalPartElements[index].uid
            });
        }
    }
    return partitionElements;
};

export let performMultipleOperations = function (){
    var finalParent;
    if (_appCtxService.ctx.selected.type === 'Fgb0PartUsage' || _appCtxService.ctx.selected.type === 'Fgf0PartitionElement') {
        if (_appCtxService.ctx.occmgmtContext.rootElement.type == 'Fgd0DesignSubsetElement') {
            var selectedPartUsage = _appCtxService.ctx.selected;
            var parent;
            do {
                parent = _cdm.getObject(selectedPartUsage.props.awb0Parent.dbValues[0]);
                selectedPartUsage = parent;
            }
            while (parent && parent.type !== "Fgd0DesignSubsetElement")
            finalParent = { type: parent.type, uid: parent.uid }
        }
        else if (_appCtxService.ctx.occmgmtContext.rootElement.type == 'Fgf0SubsetElement') {
            var selectedPartUsage = _appCtxService.ctx.selected;
            var parent;
            do {
                parent = _cdm.getObject(selectedPartUsage.props.awb0Parent.dbValues[0]);
                selectedPartUsage = parent;
            }
            while (parent && parent.type !== "Fgf0SubsetElement")
            finalParent = { type: parent.type, uid: parent.uid }
        }
    }
    else if (_appCtxService.ctx.selected.type === 'Fgd0DesignSubsetElement') {
        finalParent = { type: _appCtxService.ctx.selected.type, uid: _appCtxService.ctx.selected.uid }
    }
    return finalParent;
};

export let validatingReserve = function (data){
    var combinedPUs = [];
    for(var i = 0; i < data.Combined_PUList.length; i++){
        var object = _cdm.getObject(data.Combined_PUList[i]);
        combinedPUs.push(object);
    }
    return combinedPUs;
};

export let notReservedValidation = function (data){
    var notReservedPUs = [];
    for(var i = 0; i < data.NotReserved_PUList.length; i++){
        var object = _cdm.getObject(data.NotReserved_PUList[i]);
        notReservedPUs.push(object);
    }
    return notReservedPUs;
};

export let notEvaluatedValidation = function (data){
    var notEvaluatedPUs = [];
    for(var i = 0; i < data.NotEvaluated_PUList.length; i++){
        var object = _cdm.getObject(data.NotEvaluated_PUList[i]);
        notEvaluatedPUs.push(object);
    }
    return notEvaluatedPUs;
};

export let deleteRelationOfAttachment = function () {
    var attamentsTodelete = [];
    for (var i = 0; i < _appCtxService.ctx.mselected.length; i++) {
        var input = {};
        input.clientId = _appCtxService.ctx.mselected[i].cellHeader1;
        input.relationType = _appCtxService.ctx.relationContext.relationInfo[i].relationType;
        input.primaryObject = {
            uid: _appCtxService.ctx.pselected.uid,
            type: _appCtxService.ctx.pselected.type
        };
        input.secondaryObject = {
            uid: _appCtxService.ctx.mselected[i].uid,
            type: _appCtxService.ctx.mselected[i].type
        }

        attamentsTodelete.push(input);
    }
    return attamentsTodelete;
};

export let adaptForRefresh = function () {
    return adapterSvc.getAdaptedObjectsSync( [ _appCtxService.ctx.pselected ] );
};

export let sortInvalidPEForInfo = function (data) {
    var selectedPE = _appCtxService.getCtx("mselected");
    var valid = [];
    var invalid = [];
    for (var i = 0; i < selectedPE.length; i++) {
        if (((selectedPE[i].props.object_string.dbValues[0].indexOf("ToDo List") > -1 || selectedPE[i].props.object_string.dbValues[0].indexOf("ToDo Liste") > -1) && selectedPE[i].type == 'Fgf0PartitionElement' && selectedPE[i].props.awb0UnderlyingObjectType.dbValues == 'G4B_PartnKovAPI' && _appCtxService.ctx.userSession.props.role_name.dbValue !== 'Integrator') ||
            ((selectedPE[i].props.object_string.dbValues[0].indexOf("ToDo List") > -1 || selectedPE[i].props.object_string.dbValues[0].indexOf("ToDo Liste") > -1) && selectedPE[i].type == 'Fgf0PartitionElement' && selectedPE[i].props.awb0UnderlyingObjectType.dbValues == 'G4B_PartnKovAPI' && _appCtxService.ctx.userSession.props.role_name.dbValue == 'Integrator' && selectedPE[i].props.object_string.dbValues[0].indexOf(_appCtxService.ctx.user.props.user_name.dbValue) > -1) ||
            (selectedPE[i].type == 'Fgf0PartitionElement' && selectedPE[i].props.awb0Parent.dbValues[0] !== _appCtxService.ctx.occmgmtContext.rootElement.uid && selectedPE[i].props.awb0UnderlyingObjectType.dbValues == 'G4B_PartitionMMGFB')) 
        {
            valid.push(selectedPE[i]);
        }
        else {
            if (selectedPE[i].type == 'Fgf0PartitionElement') {
                invalid.push("\n" + selectedPE[i]);
            }
        }
    }
    data.invalidPEs = invalid;
};

export let validateInputBI = function (data, StatusCodeRange) {    
    data.statusCodeRange = StatusCodeRange;
    if(_appCtxService.ctx.selected.type == 'Fgd0DesignSubsetElement'){
        data.subsetReport = "true"
    }
    else{
        data.subsetReport = "false"
    }
};

export let processPartitions = function () { 
    var selectedPE = _appCtxService.getCtx("mselected");
    var totalPE = [];
    for(var i = 0; i < selectedPE.length; i++){
        if(selectedPE[i].type == 'Fgf0PartitionElement'){
            if((selectedPE[i].props.awb0Parent.dbValues[0] !== _appCtxService.ctx.occmgmtContext.rootElement.uid && selectedPE[i].props.awb0UnderlyingObjectType.dbValues == 'G4B_PartitionMMGFB') || 
            (selectedPE[i].props.awb0UnderlyingObjectType.dbValues == 'G4B_PartnKovASC')){
                totalPE.push(selectedPE[i]);
            }
        }
    }
    if(totalPE.length > 0){
        var totalPartitionElements = [];
        for(var index = 0; index < totalPE.length; index++){
            totalPartitionElements.push({
                type: totalPE[index].type,
                uid: totalPE[index].uid
            });
        }
    }
    return totalPartitionElements;
};

export let processPartusages = function () { 
    var selectedPUs = _appCtxService.getCtx("mselected");
    var totalPUs = [];
    for(var i = 0; i < selectedPUs.length; i++){
        if(selectedPUs[i].type == 'Fgb0PartUsage'){
            totalPUs.push(selectedPUs[i]);
        }
    }
    if(totalPUs.length > 0){
        var totalPartUsage = [];
        for(var index = 0; index < totalPUs.length; index++){
            totalPartUsage.push({
                type: totalPUs[index].type,
                uid: totalPUs[index].uid
            });
        }
    }
    return totalPartUsage;
};

exports = {
    clearClipboardJS,
    createInput,
    refreshSublocationDataJS,
    openNewObjectInEditMode,
    openInNewTab,
    getPartition,
    validateReservation,
    validateEvaluationResult,
    refreshPrimaryWrkArea,
    getModelObjectFromViewModel,
    getFilteredPUForRevokation,
    getFilteredPUForReservation,
    getSubsetFromPU,
    handleFailure,
    handlingFailure,
    refreshTreeViaJS,
    refreshCurrentObjectViaJS,
    downloadFile,
    lockUnlockSubsetViaJS,
    validateSubsetIsUpdated,
    getParentAndPartitionMap,
    getParentElementForPartition,
    refreshTree,
    vecPartitions,
    performMultipleOperations,
    reservePartitions,
    validatingReserve,
    notReservedValidation,
    notEvaluatedValidation,
    deleteRelationOfAttachment,
    adaptForRefresh,
    sortInvalidPEForInfo,
    validateInputBI,
    processPartitions,
    processPartusages
};
export default exports;
/**
 * Utility service for KovA One Step Command related services.
 *
 * @member ReserveService
 * @memberof NgServices
 */
app.factory('ReserveService', () => exports);
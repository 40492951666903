// @<COPYRIGHT>@
// ==================================================
// Copyright 2018.
// Siemens Product Lifecycle Management Software Inc.
// All Rights Reserved.
// ==================================================
// @<COPYRIGHT>@

/* global */

/**
 * Simple Alert service for sample command Handlers
 *
 * @module js/g4b_toggleVaaSAnnotationsService
 */
import g4bPDMVisService from "js/g4b_PDMVisService";
import appCtxService from "js/appCtxService";
import soaSvc from "soa/kernel/soaService";
import cdm from "soa/kernel/clientDataModel";
import localeSvc from "js/localeService";
import $ from 'jquery';

let exports = {};
let obj_activeAnnotationsInVaas = {};

let toggleButtonSelectedState = function (selectionState) {
  let button = $( 'li[button-id=\'g4b_toggleVaaSAnnotations\']' )[0];


  if (
    selectionState == true &&
    !button.classList.contains("aw-state-selected")
  ) {
    button.classList.add("aw-state-selected");
  } else if (
    selectionState == false &&
    button.classList.contains("aw-state-selected")
  ) {
    button.classList.remove("aw-state-selected");
  }
};

export let clearAnnotationCache = function () {
  obj_activeAnnotationsInVaas = {};
};

export let toggleAnnotation = function (annotationsEnabled) {
  //console.log("Value of [annotationsEnabled]: ".annotationsEnabled);
  let arr_selectionUids = [];
  //let inputData = {};
  let designComponent = null;
  if (appCtxService.getCtx("mselected").length > 0) {
    for (let i = 0; i < appCtxService.getCtx("mselected").length; i++) {
      if (
        appCtxService.getCtx("mselected")[i].props.fgb0DesignComponent !=
          null &&
          appCtxService.getCtx("mselected")[i].props.fgb0DesignComponent !=
          undefined
      ) {
        designComponent = cdm.getObject(
          appCtxService.getCtx("mselected")[i].props.fgb0DesignComponent
            .dbValues[0]
        );
        if (designComponent != null) {
          designComponent.props.partUsageUid =
          appCtxService.getCtx("mselected")[
              i
            ].props.awb0UnderlyingObject.dbValues[0];
          arr_selectionUids.push(
            appCtxService.getCtx("mselected")[i].props.fgb0DesignComponent
              .dbValues[0]
          );
        }
      }
    }
  }

  if (annotationsEnabled === undefined) {
    appCtxService.registerCtx("vaasAnnotationsEnabled", true);
    toggleButtonSelectedState(true);
    if (arr_selectionUids.length > 0) {
      g4bPDMVisService.sendSelectionToVaaS(arr_selectionUids, true);
    }
  } else if (annotationsEnabled == false) {
    appCtxService.updateCtx("vaasAnnotationsEnabled", true);
    toggleButtonSelectedState(true);
    if (arr_selectionUids.length > 0) {
      g4bPDMVisService.sendSelectionToVaaS(arr_selectionUids, true);
    }
  } else {
    appCtxService.updateCtx("vaasAnnotationsEnabled", false);
    toggleButtonSelectedState(false);
  }
};

export let createAnnotation = function (nodeID, modelObject) {
  let _str_combined = "";
  localeSvc
    .getLocalizedText(
      "GepardOneStepCommandsMessages",
      "g4b_vaaSAnnotations_combinedStr"
    )
    .then(function (result) {
      _str_combined = result;
    });

  //check if vaas annotation for node already exists
  if (obj_activeAnnotationsInVaas[nodeID] != undefined) {
    if (
      window.vaasPopupWindow.webvis.getAnnotationData(
        obj_activeAnnotationsInVaas[nodeID]
      ) != undefined
    ) {
      return;
    }
  }

  let oedeAttrs = {};

  //PV Variant formula was put here by client code. Setting it in OEDE attr object here, so that the SOA service doesn't overwrite the props.
  if (
    modelObject.props.g4b_PV_VARIANT_FORMULA.dbValues == null ||
    modelObject.props.g4b_PV_VARIANT_FORMULA.dbValues == undefined ||
    modelObject.props.g4b_PV_VARIANT_FORMULA.dbValues.length == 0 ||
    modelObject.props.g4b_PV_VARIANT_FORMULA.dbValues[0] == null ||
    modelObject.props.g4b_PV_VARIANT_FORMULA.dbValues[0] == undefined
  ) {
    oedeAttrs.g4b_PV_VARIANT_FORMULA = "";
  } else {
    oedeAttrs.g4b_PV_VARIANT_FORMULA =
      modelObject.props.g4b_PV_VARIANT_FORMULA.dbValues[0];
  }

  let inputData = {};
  //console.log("Inside fetchPropertiesForAnnotation");

  inputData.objects = [modelObject];
  inputData.attributes = [
    "g4b_DOKNR",
    "g4b_INTEXT",
    "g4b_DOKVR",
    "g4b_PEP_DOKTEIL",
    "g4b_DOKTL",
    "g4b_PEP_DOKUFMT",
    "g4b_PEP_DOKUTYP",
    "g4b_DocStatus",
    "g4b_VARIANT_FORMULA",
    "g4b_IN_VARIANT_FORMULA",
  ];
  soaSvc
    .post("Core-2006-03-DataManagement", "getProperties", inputData)
    .then(function () {
      if (
        modelObject.props.g4b_DOKNR.dbValues == null ||
        modelObject.props.g4b_DOKNR.dbValues == undefined ||
        modelObject.props.g4b_DOKNR.dbValues.length == 0 ||
        modelObject.props.g4b_DOKNR.dbValues[0] == null ||
        modelObject.props.g4b_DOKNR.dbValues[0] == undefined
      ) {
        oedeAttrs.g4b_DOKNR = "";
      } else {
        oedeAttrs.g4b_DOKNR = modelObject.props.g4b_DOKNR.dbValues[0] + ", ";
      }

      if (
        modelObject.props.g4b_INTEXT.dbValues == null ||
        modelObject.props.g4b_INTEXT.dbValues == undefined ||
        modelObject.props.g4b_INTEXT.dbValues.length == 0 ||
        modelObject.props.g4b_INTEXT.dbValues[0] == null ||
        modelObject.props.g4b_INTEXT.dbValues[0] == undefined
      ) {
        oedeAttrs.g4b_INTEXT = "";
      } else {
        oedeAttrs.g4b_INTEXT = modelObject.props.g4b_INTEXT.dbValues[0] + ", ";
      }

      if (
        modelObject.props.g4b_DOKVR.dbValues == null ||
        modelObject.props.g4b_DOKVR.dbValues == undefined ||
        modelObject.props.g4b_DOKVR.dbValues.length == 0 ||
        modelObject.props.g4b_DOKVR.dbValues[0] == null ||
        modelObject.props.g4b_DOKVR.dbValues[0] == undefined
      ) {
        oedeAttrs.g4b_DOKVR = "";
      } else {
        oedeAttrs.g4b_DOKVR = modelObject.props.g4b_DOKVR.dbValues[0] + ", ";
      }

      if (
        modelObject.props.g4b_PEP_DOKTEIL.dbValues == null ||
        modelObject.props.g4b_PEP_DOKTEIL.dbValues == undefined ||
        modelObject.props.g4b_PEP_DOKTEIL.dbValues.length == 0 ||
        modelObject.props.g4b_PEP_DOKTEIL.dbValues[0] == null ||
        modelObject.props.g4b_PEP_DOKTEIL.dbValues[0] == undefined
      ) {
        oedeAttrs.g4b_PEP_DOKTEIL = "";
      } else {
        oedeAttrs.g4b_PEP_DOKTEIL =
          modelObject.props.g4b_PEP_DOKTEIL.dbValues[0] + ", ";
      }

      if (
        modelObject.props.g4b_DOKTL.dbValues == null ||
        modelObject.props.g4b_DOKTL.dbValues == undefined ||
        modelObject.props.g4b_DOKTL.dbValues.length == 0 ||
        modelObject.props.g4b_DOKTL.dbValues[0] == null ||
        modelObject.props.g4b_DOKTL.dbValues[0] == undefined
      ) {
        oedeAttrs.g4b_DOKTL = "";
      } else {
        oedeAttrs.g4b_DOKTL = modelObject.props.g4b_DOKTL.dbValues[0] + ", ";
      }

      if (
        modelObject.props.g4b_PEP_DOKUFMT.dbValues == null ||
        modelObject.props.g4b_PEP_DOKUFMT.dbValues == undefined ||
        modelObject.props.g4b_PEP_DOKUFMT.dbValues.length == 0 ||
        modelObject.props.g4b_PEP_DOKUFMT.dbValues[0] == null ||
        modelObject.props.g4b_PEP_DOKUFMT.dbValues[0] == undefined
      ) {
        oedeAttrs.g4b_PEP_DOKUFMT = "";
      } else {
        oedeAttrs.g4b_PEP_DOKUFMT =
          modelObject.props.g4b_PEP_DOKUFMT.dbValues[0] + ", ";
      }

      if (
        modelObject.props.g4b_PEP_DOKUTYP.dbValues == null ||
        modelObject.props.g4b_PEP_DOKUTYP.dbValues == undefined ||
        modelObject.props.g4b_PEP_DOKUTYP.dbValues.length == 0 ||
        modelObject.props.g4b_PEP_DOKUTYP.dbValues[0] == null ||
        modelObject.props.g4b_PEP_DOKUTYP.dbValues[0] == undefined
      ) {
        oedeAttrs.g4b_PEP_DOKUTYP = "";
      } else {
        oedeAttrs.g4b_PEP_DOKUTYP =
          modelObject.props.g4b_PEP_DOKUTYP.dbValues[0] + ", ";
      }

      if (
        modelObject.props.g4b_DocStatus.dbValues == null ||
        modelObject.props.g4b_DocStatus.dbValues == undefined ||
        modelObject.props.g4b_DocStatus.dbValues.length == 0 ||
        modelObject.props.g4b_DocStatus.dbValues[0] == null ||
        modelObject.props.g4b_DocStatus.dbValues[0] == undefined
      ) {
        oedeAttrs.g4b_DocStatus = "";
      } else {
        let docStatusObject = cdm.getObject(
          modelObject.props.g4b_DocStatus.dbValues[0]
        );
        oedeAttrs.g4b_DocStatus =
          docStatusObject.props.object_string.uiValues[0] + ", ";
      }

      if (
        modelObject.props.g4b_VARIANT_FORMULA.dbValues == null ||
        modelObject.props.g4b_VARIANT_FORMULA.dbValues == undefined ||
        modelObject.props.g4b_VARIANT_FORMULA.dbValues.length == 0 ||
        modelObject.props.g4b_VARIANT_FORMULA.dbValues[0] == null ||
        modelObject.props.g4b_VARIANT_FORMULA.dbValues[0] == undefined
      ) {
        oedeAttrs.g4b_VARIANT_FORMULA = "";
      } else {
        if (oedeAttrs.g4b_PV_VARIANT_FORMULA == "") {
          oedeAttrs.g4b_VARIANT_FORMULA =
            modelObject.props.g4b_VARIANT_FORMULA.dbValues[0];
        } else {
          oedeAttrs.g4b_VARIANT_FORMULA =
            "+ " + modelObject.props.g4b_VARIANT_FORMULA.dbValues[0];
        }
      }

      if (appCtxService.getCtx("vaasAnnotationsEnabled") == true) {
        obj_activeAnnotationsInVaas[nodeID] =
          window.vaasPopupWindow.webvis.createAnnotation(
            nodeID,
            '<html><div class="g4b-webvis-annotation" style="white-space: normal;overflow-wrap: break-word;max-width: 450px;">' +
              oedeAttrs.g4b_DOKNR +
              oedeAttrs.g4b_INTEXT +
              oedeAttrs.g4b_DOKVR +
              oedeAttrs.g4b_PEP_DOKTEIL +
              oedeAttrs.g4b_DOKTL +
              oedeAttrs.g4b_PEP_DOKUFMT +
              oedeAttrs.g4b_PEP_DOKUTYP +
              oedeAttrs.g4b_DocStatus +
              '</div><hr style="border-width: 0.5px;border-style: solid;border-color: #b8b8b8;"><div class="g4b-webvis-annotation" style="white-space: normal;overflow-wrap: break-word;max-width: 450px;">' +
              oedeAttrs.g4b_PV_VARIANT_FORMULA +
              " " +
              oedeAttrs.g4b_VARIANT_FORMULA +
              " (" +
              _str_combined +
              ")</div></html>"
          );
      }
    });
};
/**
 * TO CHECK:- implementation in Factory
 */
let factoryInitializer_setParameters = function () {
  //set parameters for endIndex and cursorData
  if (appCtxService.getCtx("isIframeVisible") == undefined) {
    appCtxService.registerCtx("isIframeVisible", false);
  } else {
    appCtxService.updateCtx("isIframeVisible", false);
  }

  if (appCtxService.getCtx("vaasAnnotationsEnabled") == true) {
    toggleButtonSelectedState(true);
  }

  window.removeAnnotationFromCache = function (annotationID) {
    for (let nodeId in obj_activeAnnotationsInVaas) {
      if (obj_activeAnnotationsInVaas[nodeId] == annotationID) {
        delete obj_activeAnnotationsInVaas[nodeId];
      }
    }
  };
};
factoryInitializer_setParameters();

exports = {
  clearAnnotationCache,
  toggleAnnotation,
  createAnnotation,
};
export default exports;
